import React, { useMemo } from 'react';
import { ColumnContainer, descriptions } from '../../helpers';
import { TransformSQLType } from '../../../constants/hammerstoneConstants';
import { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { objToOptions } from 'src/commons/';
import Content, { Rules } from 'src/components/helpers/content/';
import { AttributeEditorInput } from 'src/interfaces/inputInterfaces';
import { DisplayMode } from 'src/components/helpers/content/contentInterfaces';

/**
 * An editable container for information about an TRANSFORM-type activity's SQL statements
 */
export default function TransformSQLContainer(props: ActivityContainerProps) {
  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;
  const SQLTypeOptions = useMemo(() => objToOptions(TransformSQLType), []);
  return (
    <ColumnContainer header={props.header}>
      {props.mode === DisplayMode.Edit && <descriptions.SQLQuery />}
      <Content.AttributeEditor
        label="Transformation SQLs"
        path="config.TRANSFORM_SQLS"
        editDescription={'This field is optional'}
        definitions={[
          {
            label: 'Type',
            key: 'type',
            options: SQLTypeOptions,
            input: AttributeEditorInput.Select,
            defaults: true,
            rules: { required: Rules.required('Transform SQL type') },
          },
          {
            label: 'Query',
            key: 'value',
            input: AttributeEditorInput.Code,
            language: 'sql',
            rules: { required: Rules.required('Transform SQL query') },
          },
        ]}
        {...contentProps}
      />
    </ColumnContainer>
  );
}
