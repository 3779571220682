import { WEBSITE_NAME } from '../constants/config';
import { urlSearchString } from './navHelper';
import PAGES from './Pages';

export const home = (groupName: string) => [
  {
    text: WEBSITE_NAME,
    href: PAGES.HOME.path + urlSearchString({ groupName }),
  },
];

export const managePipelines = (groupName: string) => [
  ...home(groupName),
  {
    text: 'Pipelines',
    href: PAGES.MANAGE_PIPELINES.path + urlSearchString({ groupName }),
  },
];

export const pipeline = (groupName: string, pipelineName: string) => [
  ...managePipelines(groupName),
  {
    text: pipelineName || 'loading...',
    href: pipelineName
      ? PAGES.VIEW_PIPELINE.path.replace(':pipelineName', pipelineName) + urlSearchString({ groupName })
      : '', //Will not navigate before pipeline loads
  },
];

export const activity = (groupName: string, pipelineName: string, activityId: string, activityName: string) => [
  ...pipeline(groupName, pipelineName),
  {
    text: activityName || 'loading...',
    href: PAGES.VIEW_ACTIVITY.path.replace(':activityId', activityId) + urlSearchString({ groupName }),
  },
];

export const instance = (
  groupName: string,
  pipelineName: string,
  activityId: string,
  activityName: string,
  instanceId: string,
) => [
  ...activity(groupName, pipelineName, activityId, activityName),
  {
    text: 'Monitor',
    href: PAGES.MONITOR_ACTIVITY.path.replace(':activityId', activityId) + urlSearchString({ groupName }),
  },
  {
    text: instanceId,
    href:
      PAGES.VIEW_INSTANCE.path.replace(':activityId', activityId).replace(':instanceId', instanceId) +
      urlSearchString({ groupName }),
  },
];
