import { Box, SpaceBetween, SpaceBetweenProps } from '@amzn/awsui-components-react';
import React from 'react';

export function Column({
  children,
  size = 'm',
  direction = 'vertical',
}: {
  children: React.ReactNode;
  size?: React.ComponentProps<typeof SpaceBetween>['size'];
  direction?: SpaceBetweenProps['direction'];
}) {
  return (
    <Box>
      <SpaceBetween size={size} direction={direction}>
        {children}
      </SpaceBetween>
    </Box>
  );
}
