import { Container, SpaceBetween, Box, TextContent, Button } from '@amzn/awsui-components-react';
import { ONBOARDING_WIKI } from 'src/constants/staticUrls';
import { urlSearchString } from '../../nav/navHelper';
import { PAGES } from 'src/nav/Pages';

import { useNavigate } from 'react-router-dom';
import { useGroupName } from 'src/data/redux';
import React from 'react';
import { LinkButton } from '../helpers';

// CTA: Call to action. In design, a web component to suggest the next action. 
export const PrimaryCTA = () => {
  const navigate = useNavigate();
  const groupName = useGroupName();

  return (
    <div className="custom-home__header-cta">
      <Container>
        <SpaceBetween size="xl">
          <Box variant="h2" padding="n">
            Discover data workflows
          </Box>
          <TextContent>Start browsing the data pipelines available in Hammerstone.</TextContent>
          <Button variant="primary" onClick={() => navigate({pathname:PAGES.MANAGE_PIPELINES.path, search: urlSearchString({groupName})})}>
            Discover pipelines
          </Button>
        </SpaceBetween>
      </Container>
    </div>
  );
};

// Redirects to Wiki to onboard customers. TODO: add external icon, open new tab while preserving primary style.
export const NewCustomerCTA = () => (
  <div className="custom-home__header-cta">
    <Container>
      <SpaceBetween size="xl">
        <Box variant="h2" padding="n">
          Discover data workflows
        </Box>
        <TextContent>Join over 2,200 Amazonians using Hammerstone.</TextContent>
        <LinkButton href={ONBOARDING_WIKI} variant="primary" external data-testid="getAccessLinkButton">
          Get access
        </LinkButton>
      </SpaceBetween>
    </Container>
  </div>
);
