import { ColumnLayout, Link, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { usePipeline } from 'src/data/redux';
import { urlSearchString, useInternalOnFollow } from 'src/nav/navHelper';
import { dateToUTCString, useConditionalEffect, useTimeoutListRef } from '../../commons';
import { fetchPipelineActivitiesAndInstances, fetchPipelineInfo } from '../../data/api/fetchFromAPI';
import PAGES from '../../nav/Pages';
import { CustomStatusIndicator, LabeledContent } from '../helpers';
import PipelineActivitiesTable from './PipelineActivitiesTable';

export default function ManagePipelinesRow(props: { pipelineName: string; groupName: string; selected?: boolean }) {
  const { pipelineName, groupName, selected } = props;
  const onFollow = useInternalOnFollow();
  const delayRefs = useTimeoutListRef();
  useConditionalEffect(() => {
    if (selected) {
      fetchPipelineActivitiesAndInstances(pipelineName, groupName, { delays: delayRefs }, false);
    } else {
      fetchPipelineInfo({
        pipelineName,
        groupName,
        // Do not load the last execution status and date automatically when loading Manage Pipelines, since it would spike our DJS TPS limits (600, 40)
        // Summary of this design decision: https://quip-amazon.com/FYXZAuzTvuNz/LLD-Hammerstone-React-DJS-Throttling-on-Last-Execution-Status-and-Dates
        // Our Sev-2 to DJS team to raise our limits: https://t.corp.amazon.com/V850508775/communication
        // DJS team's responding CR: https://code.amazon.com/reviews/CR-86924440/revisions/1#/details
        includeLastExecutionDetails: false,
      });
    }
  }, [pipelineName, groupName]);

  const { data: pipeline } = usePipeline({ groupName, pipelineName });

  const loading = !pipeline;
  const pipelineNameContainer = (
    <Link
      onFollow={onFollow}
      href={PAGES.VIEW_PIPELINE.path.replace(':pipelineName', pipelineName) + urlSearchString({ groupName })}
    >
      {pipelineName}
    </Link>
  );

  if (loading) {
    return (
      <ColumnLayout columns={2} data-testid="pipelineActivitySummary">
        <span>
          {pipelineNameContainer} <Spinner />
        </span>
        <span>
          <Skeleton height={'1.1em'} width={75} />
        </span>
      </ColumnLayout>
    );
  } else if (selected) {
    return (
      <SpaceBetween size="s">
        <ColumnLayout columns={2} data-testid="pipelineActivitySummary">
          <b>
            {pipelineNameContainer} ({pipeline?.activityInfoList?.length})
          </b>
          <CustomStatusIndicator>{pipeline?.status}</CustomStatusIndicator>
        </ColumnLayout>

        <ColumnLayout columns={3} variant="text-grid">
          <LabeledContent data-testid="pipelineActivityDescription" label="Description">
            {pipeline?.description}
          </LabeledContent>
          <LabeledContent data-testid="pipelineActivityUpdateDate" label="Update date">
            {dateToUTCString(pipeline?.updateDate)}
          </LabeledContent>
          <LabeledContent data-testid="pipelineActivityUpdateBy" label="Updated by">
            {pipeline?.updatedBy}
          </LabeledContent>
        </ColumnLayout>
        <PipelineActivitiesTable pipelineName={pipelineName} />
      </SpaceBetween>
    );
  } else {
    return (
      <ColumnLayout columns={2} data-testid="pipelineActivitySummary">
        <span>
          {pipelineNameContainer} ({pipeline?.activityInfoList?.length})
        </span>
        <CustomStatusIndicator>{pipeline?.status}</CustomStatusIndicator>
      </ColumnLayout>
    );
  }
}
