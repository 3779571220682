import React, { useEffect } from 'react';
import { FormField, Input } from '@amzn/awsui-components-react';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash-es';
import { useEditOnChange, useIsEditContentDisabled, useViewContent } from './contentHelpers';
import { DisplayMode, ResourcePath, ResourceType, iContent } from './contentInterfaces';
import { LabeledContentSkeleton } from '../skeletons/LabeledContentSkeleton';
import { HelpPanelInfoLink, LabeledContent } from '..';
import { DataAttributes, filterDataAttributes, getContentDataAttributes } from 'src/commons/dataAttributes';
import { ContentErrorWrapper } from 'src/components/error/ContentErrorBoundary';
import { Rules } from '.';
import { getNodeText } from 'src/commons';

/** Renders the field identified by the `path` as a Polaris `<Input>` component. Manipulates the value as a `number` type
 * For an overview of `<Content>` components, see the [README.md](./README.md). For usage examples, see the [How-to guide](./HowToGuide.md) */
export const NumberContent = ContentErrorWrapper(_NumberContent);

function _NumberContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iContent<RType, DisabledOnPaths>,
) {
  // Processed at top level component to avoid unneccessary executions of a relatively heavy functions between renders
  const dataAttributes = { ...filterDataAttributes(props), ...getContentDataAttributes('Number', props) };

  switch (props.mode) {
    case DisplayMode.View:
      return <ViewNumberContent {...props} dataAttributes={dataAttributes} />;
    case DisplayMode.Edit:
      return <EditNumberContent {...props} dataAttributes={dataAttributes} />;
    case DisplayMode.Loading:
      return <LabeledContentSkeleton label={props.label} />;
    default:
      return null;
  }
}

/** The default transform for numbers */
function defaultNumberViewTransform(value: string) {
  const float = parseFloat(value);
  if (Number.isNaN(float)) {
    // Blank/empty values will be parsed as NaN, but we want to display them as empty
    return '';
  } else {
    return float.toString();
  }
}

function ViewNumberContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iContent<RType, DisabledOnPaths> & { dataAttributes: DataAttributes },
) {
  const { value, disabled } = useViewContent(props);
  return (
    <LabeledContent
      label={props.label}
      info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
      missingText={props.missingText}
    >
      <span className={disabled ? 'disabled-content' : ''} {...props.dataAttributes}>
        {props.viewTransform ? props.viewTransform(value) : defaultNumberViewTransform(value)}
      </span>
    </LabeledContent>
  );
}

function EditNumberContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iContent<RType, DisabledOnPaths> & { dataAttributes: DataAttributes },
) {
  const { control, clearErrors, setValue } = useFormContext<ResourceType[RType]>();
  const disabled = useIsEditContentDisabled(props);

  return (
    <Controller
      control={control}
      name={props.path}
      rules={Rules.useRules(props.rules, props.label, disabled)}
      render={({ field, formState, fieldState }) => {
        const onChange = useEditOnChange(props, field.onChange, setValue, 'value');

        useEffect(() => {
          // This effect will ignore any potential validation errors if the particular field is disabled
          if (disabled) {
            clearErrors(props.path);
          }
        }, [disabled, formState.isSubmitting, formState.isValidating]);

        return (
          <FormField
            label={props.label}
            description={props.editDescription}
            info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
            errorText={get(formState, `errors.${props.path}.message`)}
          >
            <span {...props.dataAttributes}>
              <Input
                data-testid={getNodeText(props.label)}
                value={field.value as string}
                ref={field.ref}
                onChange={onChange}
                onBlur={field.onBlur}
                invalid={fieldState.invalid}
                disabled={disabled}
                placeholder={props.placeholder}
                // Number-specific props
                type="number"
                inputMode="numeric"
                // The default step will be set to 1, but users can input their own values directly
                step={1}
              />
            </span>
          </FormField>
        );
      }}
    />
  );
}
