import * as constants from './hammerstoneConstants';

/**
 * Justification: Instead of using enums for constants, we decided to use objects,
 * which are more flexible and allow for numeric inputs, which enums do not.
 * In order to programatically retrieve a particular key from these constants (which corresponds to the raw
 * outputs from the API), we defined this object to programatically map each constant's keys to itself.
 *
 * www.youtube.com/watch?v=3tmd-ClpJxA
 * www.youtube.com/watch?v=eGCD4xb-Tr8
 */

/**
 * This type takes in a particular object's type as a generic input
 *
 * It returns an object which maps each key in the object back to itself.
 */
export type KeyObj<Obj extends {}> = { [key in keyof Obj]: key};

/**
 * This type is specific to the exported `keys` object.
 *
 * It maps each of the objects exported from `hammerstoneConstants.tsx` to their self-key objects.
 */
type NameKeyObj = { [name in keyof typeof constants]: KeyObj<typeof constants[name]> };

/**
 * Example:
 *
 *      const x = { a : 1, b : 2 , c : 3} as const;
 *      const xKeys = objToKeys(xKeys);
 *
 *      x.a; // 1
 *      xKeys.a; // 'a'
 *      x.x; // undefined
 *      xKeys.x; // TypeError!
 *
 * @param {object} obj Any object type (although a readonly object, e.g. `{...} as const`)
 * @returns A new object which strictly maps the input obj's keys back to itself.
 */
export function objToKeys<Obj extends {}>(obj: Obj): KeyObj<Obj> {
  return Object.fromEntries(Object.keys(obj).map((key) => [key, key as keyof typeof obj])) as KeyObj<Obj>;
}

/**
 * 🎵 Let's have a keykey
 * 🎵 I want to have a keykey
 * 🎵 Lock the door's type
 *
 * This object maps the name of each constant exported from `hammerstoneConstants.tsx` to its self-key object.
 * This allows from strong type constraints on accessing these keys.
 */
const keys: NameKeyObj = Object.fromEntries(
  Object.entries(constants).map(([key, value]) => [key, objToKeys(value) as KeyObj<typeof value>]),
) as NameKeyObj;

export default keys;
