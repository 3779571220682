import React, { useEffect, useMemo } from 'react';
import { FormField, Multiselect } from '@amzn/awsui-components-react';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash-es';
import { useEditOnChange, useIsEditContentDisabled, useViewContent } from './contentHelpers';
import { DisplayMode, ResourcePath, ResourceType, iSelectContent } from './contentInterfaces';
import { LabeledContentSkeleton } from '../skeletons/LabeledContentSkeleton';
import { LabeledContent } from '..';
import { OptionsObj, optionsToObj } from 'src/commons';
import { HelpPanelInfoLink } from '../info/HelpPanelInfoLink';
import { DataAttributes, filterDataAttributes, getContentDataAttributes } from 'src/commons/dataAttributes';
import { ContentErrorWrapper } from 'src/components/error/ContentErrorBoundary';
import { Rules } from '.';

/** Renders the field identified by the `path` as a Polaris `<Multiselect>`. Manipulates the value selected options' values as a `string[]` type.
 * For an overview of `<Content>` components, see the [README.md](./README.md). For usage examples, see the [How-to guide](./HowToGuide.md) */
export const MultiselectContent = ContentErrorWrapper(_MultiselectContent);

function _MultiselectContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iSelectContent<RType, DisabledOnPaths>,
) {
  // Processed at top level component to avoid unneccessary executions of a relatively heavy functions between renders
  const dataAttributes = { ...filterDataAttributes(props), ...getContentDataAttributes('Multiselect', props) };
  // Convert options list to a value-indexed object in order to efficiently retrieve the label corresponding to a value
  const optionsObj = useMemo(() => optionsToObj(props.options), [props.options]);

  switch (props.mode) {
    case DisplayMode.View:
      return <ViewMultiselectContent {...props} optionsObj={optionsObj} dataAttributes={dataAttributes} />;
    case DisplayMode.Edit:
      return <EditMultiselectContent {...props} optionsObj={optionsObj} dataAttributes={dataAttributes} />;
    case DisplayMode.Loading:
      return <LabeledContentSkeleton label={props.label} />;
    default:
      return null;
  }
}

function ViewMultiselectContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iSelectContent<RType, DisabledOnPaths> & {
    optionsObj: OptionsObj;
    dataAttributes: DataAttributes;
  },
) {
  const { disabled, value: rawValues } = useViewContent(props);
  // Convert each value to its label, if available, otherwise just displays the raw value
  const values = ((rawValues ?? []) as string[]).map((value) => props.optionsObj[value]?.label ?? value);

  return (
    <LabeledContent
      label={props.label}
      info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
      missingText={props.missingText}
    >
      <span className={disabled ? 'disabled-content' : ''} {...props.dataAttributes}>
        {/* By default, displays the values as a comma-separated list */}
        {props.viewTransform ? props.viewTransform(values) : values.join(', ')}
      </span>
    </LabeledContent>
  );
}

function EditMultiselectContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iSelectContent<RType, DisabledOnPaths> & { optionsObj: OptionsObj; dataAttributes: DataAttributes },
) {
  const { control, clearErrors, setValue } = useFormContext<ResourceType[RType]>();
  const disabled = useIsEditContentDisabled(props);

  return (
    <Controller
      control={control}
      name={props.path}
      rules={Rules.useRules(props.rules, props.label, disabled)}
      render={({ field, formState, fieldState }) => {
        const onChange = useEditOnChange(props, field.onChange, setValue, 'options');

        useEffect(() => {
          // This effect will ignore any potential validation errors if the particular field is disabled
          if (disabled) {
            clearErrors(props.path);
          }
        }, [disabled, formState.isSubmitting, formState.isValidating]);

        return (
          <FormField
            label={props.label}
            description={props.editDescription}
            errorText={get(formState, `errors.${props.path}.message`)}
            info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
          >
            <span {...props.dataAttributes}>
              <Multiselect
                // General props
                ref={field.ref}
                onChange={onChange}
                onBlur={field.onBlur}
                invalid={fieldState.invalid}
                disabled={disabled}
                // Multiselect-specific props:
                placeholder={
                  props.placeholder ?? (props.options?.length > 0 ? 'Select options ...' : 'No options found.')
                }
                options={props.options}
                selectedOptions={((field.value as string[]) ?? []).map(
                  (value: string) => props.optionsObj[value] ?? { value, label: value },
                )}
                selectedAriaLabel="Selected"
                // These props facilitate asynchronous options loading, which is especially useful for large dropdowns (e.g. CTI)
                filteringType={props.filteringType}
                onLoadItems={props.onLoadItems}
                statusType={props.statusType}
                loadingText={props.loadingText}
                errorText={props.errorText}
                finishedText={props.finishedText}
              />
            </span>
          </FormField>
        );
      }}
    />
  );
}
