import React from 'react';
import ErrorLayout from './ErrorLayout';
import { Box, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { useGroupName } from '../../data/redux';
import { ODIN_TO_IAM_MIGRATION_GUIDE, RAGNAROK } from 'src/constants/staticUrls';
import { getOldHammerstoneEndpoint } from 'src/constants/config';
import PAGES from 'src/nav/Pages';

export default function ErrorCannotCreateOdinActivity(props: { pipelineName: string }) {
  const groupName = useGroupName();

  return (
    <ErrorLayout
      header="Activity creation is disabled in Odin pipelines"
      subheader="Activities may only be created in pipelines which use IAM"
      backtrackButtons
    >
      <SpaceBetween size="l">
        <Box>
          Since Odin is being deprecated through{' '}
          <Link href={RAGNAROK} external target="_blank">
            Project Ragnarok
          </Link>
          , we no longer allow activity creation in pipelines which use Odin for S3 credentials. For the time being,
          however, you may still view and edit legacy Odin pipelines and their activities.
        </Box>
        <Box>
          If you are interested in migrating pipeline "{props.pipelineName}" and its activities to IAM, you may review
          the{' '}
          <Link external href={ODIN_TO_IAM_MIGRATION_GUIDE} target="_blank">
            Odin-to-IAM Migration Guide
          </Link>
          .
        </Box>
        <Box>
          Once you are ready to migrate to IAM: navigate to the{' '}
          <Link
            external
            href={getOldHammerstoneEndpoint() + PAGES.MANAGE_PIPELINES.pageLayoutProps({ groupName }).oldHref}
            target="_blank"
          >
            old Manage Pipelines page
          </Link>{' '}
          and click the "Migrate Pipelines to IAM" button to launch our self-serve migration tool.
        </Box>
      </SpaceBetween>
    </ErrorLayout>
  );
}
