import { Box } from '@amzn/awsui-components-react';
import React, { ReactNode } from 'react';
import BacktrackButtons from './BacktrackButtons';

/**
 * A component which renders a useful error message for the user.
 * @param {ReactNode} header - Optional The large header announcing the error
 * @param {ReactNode} subheader - Optional A subheader explaining the error in more user-friendly language
 * @param {ReactNode} children - Optional Additional information about the error as well as actions the user may take
 * @param {boolean} backtrackButtons - Optional If true, will render a "back" and "home" button at the bottom of the page
 */
export default function ErrorLayout(props: {
  header?: ReactNode;
  subheader?: ReactNode;
  children?: ReactNode;
  backtrackButtons?: boolean;
}) {
  return (
    <>
      {props.header && (
        <Box textAlign="center" variant="h1">
          {props.header}
        </Box>
      )}
      {props.subheader && (
        <Box textAlign="center" variant="h2">
          {props.subheader}
        </Box>
      )}
      {props.children && (
        <Box textAlign="center" padding={{ top: 'l' }}>
          {props.children}
        </Box>
      )}
      {props.backtrackButtons && <BacktrackButtons />}
    </>
  );
}
