import {
  KeyObjectDefinitionList,
  KeyValuesList,
} from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import { kodlObjType } from '../interfaces/reactInterfaces';
// --- === OBJECT MANIPULATORS === ---

/**
 * Function borrowed from https://stackoverflow.com/a/60564620
 *
 * Example:
 *    Input: <div><span style={{color:"red"}}>Hello </span><span>World!</span></div>
 *  Output: "Hello World!"
 *
 * @param node Any kind of node (including ReactNodes)
 * @returns The concatenated inner texts of all nodes.
 */
export const getNodeText = (node: any): string => {
  if (['string', 'number', 'boolean'].includes(typeof node)) {
    return node.toString();
  }
  if (Array.isArray(node)) {
    return node.map(getNodeText).join('');
  }
  if (typeof node === 'object' && node?.props) {
    // The order of this list matters: the first non-empty value will be returned from the following component props
    // This order is chosen based on an assumed hierarchy of how text will be passed into a component. Most typically, it's as a child (<>text<>) or value (in the case of editors/input components, <Input value={text}>).
    // Some components, however, have irregular props for the displayed text such as "text", "items" (Table, AttributeEditor), and "empty" if there are no "items"
    for (const key of ['children', 'value', 'text', 'items', 'empty', 'content']) {
      if (node.props[key]) {
        const text = getNodeText(node.props[key]);
        if (text?.length) {
          // Only returns if the returned text is non-empty, otherwise continues iterating
          return text;
        }
      }
    }
  }
  return '';
};

/**
 * Converts a list of key-value objects into a kev-value pair object
 *
 * Example:
 *
 *      const x = [{key:'a', value:'apple'}, {key: 'b', value: 'banana', ...etc}];
 *      keyValuesListToObj(x); // Returns `{ a: 'apple', b: 'banana', etc.}`
 */
export const keyValuesListToObj = (keyValuesList: KeyValuesList) => {
  return Object.fromEntries(keyValuesList.map(({ key, value }) => [key, value])) as any;
};

/**
 * keyObjectDefinitionList:
 */
/**
 * Converts an annoyingly complex keyObjectDefinitionList to a more compact and indexable object.
 * 
 * 
 * 
 * Example: 
 * 
 *      INPUT: [
          {
            key: 'KEY_A',
            keyObjectsList: [
              {
                key: 'key1',
                keyObjectsList: [],
                values: [
                  {
                    key: 'key1.1',
                    value: 'value1.1',
                  },
                ],
              },
              {
                key: 'key2',
                keyObjectsList: [],
                values: [
                  {
                    key: 'key2.1',
                    value: 'value2.1',
                  },
                ],
              },
            ],
            values: [
              {
                key: 'key3',
                value: 'value3',
              },
            ],
          },
      ];

 * 
 * OUTPUT: 
 *    
 *      {
 *        KEY_A: { 
 *          keyObjectsList: { 
 *            key1: { 
 *              keyObjectsList: {},
 *              values: {
 *                key1.1: "value1.1" 
 *              } 
 *            key2: { 
 *              keyObjectsList: {}, 
 *              values: { 
 *                key2.1: "value2.1" 
 *              } 
 *            } 
 *          }, 
 *          values: { 
 *            key3: "value3" 
 *          }
 *        } 
 *      };
 *   */
export const keyObjectDefinitionListToObj = (keyObjectDefinitionList: KeyObjectDefinitionList): kodlObjType => {
  return Object.fromEntries(
    keyObjectDefinitionList.map(({ key, keyObjectsList, values }) => {
      const keyObjectsListObj = keyObjectDefinitionListToObj(keyObjectsList);
      const valuesObj = keyValuesListToObj(values);
      return [key, { keyObjectsList: keyObjectsListObj, values: valuesObj }];
    }),
  );
};

/** Takes a string and escapes special characters which may break DB/API calls (allowing for consistent edit -> PUT --> GET --> view flows) */
export function escapeQuerySpecialChars(s: string): string {
  // WARNING! Be VERY careful when changing which inputs are escaped and how they are escaped
  // Bad escaping/replacements may systematically damage/disrupt users' SQL queries and running jobs
  return s
    .replaceAll('\\', '\\\\') // Escape literal slashes
    .replaceAll('\n', '\\n') // Escape line breaks
    .replaceAll('"', '\\"'); // Escape double quotes
}

/**
 * Takes a list of strings and returns an object mapping unique values in the list to their frequency
 */
export function count(list: string[]) {
  return list.reduce(function (acc, curr) {
    if (!acc[curr]) {
      acc[curr] = 0;
    }
    acc[curr]++;
    return acc;
  }, {} as { [uniqueVals: string]: number });
}
