import React from 'react';
import { Link } from '@amzn/awsui-components-react';
import { useDispatch } from 'react-redux';
import { actions } from 'src/data/redux';
import { HelpPanels } from '..';

/**
 * Creates a Polaris "Info" Link which will open the `tools` drawer (if it is not hidden) and renderthe named helpPanel
 *
 * Follows Polaris usage standards: https://polaris.a2z.com/components/link/?tabId=usage
 *  - The link text should simply be 'Info'
 *  - No quotes, icons (e.g. external), or other special indicators
 *  - Only put Info Links on the main page, next to headings (e.g. in `<Containers actions>`, `<Header info>`, `<FormField info>`, or adjacent to the label of a key-value pair  )
 */
export function HelpPanelInfoLink(props: { helpPanel?: keyof typeof HelpPanels }) {
  const dispatch = useDispatch();
  return (
    <Link
      variant="info"
      onFollow={(e) => {
        e.preventDefault();
        dispatch(actions.page.displayHelpPanel(props.helpPanel));
      }}
    >
      Info
    </Link>
  );
}
