import { transformParamItem, transformSQLItem } from './activityInterfaces';
import keys from 'src/constants/hammerstoneConstantKeys';

export const _DefaultConfigMetadataKeys = {
  string: [
    'SNS_TOPIC_ARN',
    'SNS_CUSTOM_MESSAGE_TEXT',
    'ENCRYPT_KEY_ODIN', // Optional (ENCRYPTION & SYMMETRICKEY)
    'FIELD_DELIMITER', // Optional, can be blank/empty and will then default to NUL delimiter in backend
    'REGION', //OPTIONAL
    'TARGET_ACCESS_ODIN', //Optional & Deprecated: Should NOT be used (does not seem to do anything)
    'S3_ACCESS_ODIN', // Optional & Deprecated : only for legacy pipelines (RS API: isIam == false, HS API: s3CredentialTpe == "ODIN")
    // We don't want to include ETL_LOAD_TYPE here since it should ONLY be added to CreateActivity calls
    'CUSTOM_PARAMETERS', // Optional
  ],
  number: ['PRECON_RETRY', 'PRECON_WAIT'],
  boolean: [
    'INCLUDE_GLOBAL_PARAM',
    'ENCRYPTION',
    'SYMMETRICKEY',
    'GZIP',
    'ESCAPE_OPTION',
    'DJS_ALARM_ALL_EXECUTIONS_FAILED',
    'DJS_ALARM_EXECUTION_FAILURE',
    'DJS_ALARM_DISABLED_JOB',
  ],
  splitString: ['SNS_NOTIFY_ON', 'SNS_MESSAGE_OPTIONS'],
} as const;

const _ExtractTransformStringKeys = [
  'S3_FILE_PATH',
  'KMS_KEY_ID', // Optional (ENCRYPTION)
  'EMAIL_LIST', // Optional (SEND_EMAIL)
  'EMAIL_SUBJECT', // Optional (SEND_EMAIL)
  'EMAIL_SUMMARY', // Optional (SEND_EMAIL)
  'REPORT_HEADER', // Optional (HAS_REPORT_HEADER)
] as const;
const _ExtractTransformBooleanKeys = [
  'ALLOW_OVERWRITE',
  'SEND_EMAIL', // Optional (PUBLISH)
  'HAS_REPORT_HEADER', // Optional (SEND_EMAIL)
  'MANIFEST',
  'PUBLISH',
  'DELETE_FILES',
  'ADD_ACL', // Default true
] as const;

export const ExtractConfigMetadataKeys = {
  string: [..._DefaultConfigMetadataKeys.string, ..._ExtractTransformStringKeys, 'SELECT_QUERY'],
  number: _DefaultConfigMetadataKeys.number,
  boolean: [..._DefaultConfigMetadataKeys.boolean, ..._ExtractTransformBooleanKeys, 'ADDQUOTES'],
  splitString: _DefaultConfigMetadataKeys.splitString,
} as const;

export const TransformConfigMetadataKeys = {
  string: [..._DefaultConfigMetadataKeys.string, ..._ExtractTransformStringKeys],
  number: _DefaultConfigMetadataKeys.number,
  boolean: [..._DefaultConfigMetadataKeys.boolean, ..._ExtractTransformBooleanKeys],
  splitString: _DefaultConfigMetadataKeys.splitString,
} as const;

export const LoadConfigMetadataKeys = {
  string: [
    ..._DefaultConfigMetadataKeys.string,
    'S3_FILE_NAME', //TODO: Replace S3 File Path with File Name
    'S3_FILE_PATH_TYPE',
    'SOURCE_TYPE',
    'DIST_KEY_NAME',
    'DATA_REPLACE_OPTION',
    'TARGET_DATABASE_TYPE',
    'TARGET_DATABASE_NAME',
    'TARGET_SCHEMA_NAME',
    'TARGET_TABLE_NAME',
    'TARGET_DB_HOST',
    'FILE_FORMAT',
    'JSONPATHFILEINPUT', //Optional. The JSONPATHFILE config should be true before giving this config
    'POST_TRANSFORM_SQL', //Optional
    'PRE_TRANSFORM_SQL', //Optional
    'TIME_FORMAT',
    'DATE_FORMAT',
  ],
  number: [..._DefaultConfigMetadataKeys.number, 'SKIP_LINES', 'MAX_ERROR'],
  boolean: [
    ..._DefaultConfigMetadataKeys.boolean,
    'TRUNCATE_TO_FIT',
    'TRIM',
    'DRYRUN',
    'REMOVE_MANIFEST',
    'REMOVEQUOTES',
    'JSONPATHFILE', //Optional. FILE_FORMAT should be JSON before enabling this config
    'SYMMETRICKEY', //Optional, will enable symmetric key encription. The Encryption config should be true before enabling this
    'NO_FILES_TO_COPY_GENERATES_ERROR',
    'FASTLOAD', // Optional, will enable "Direct Load" config
  ],
  splitString: _DefaultConfigMetadataKeys.splitString,
} as const;

/**
 * An object mapping different Activity types to their corresponding key objects.
 * Each key object maps a data type (string, number, boolean, and splitString) to a list of keys which should have that type.
 */
export const ConfigMetadataKeys = {
  EXTRACT: ExtractConfigMetadataKeys,
  TRANSFORM: TransformConfigMetadataKeys,
  COPY: LoadConfigMetadataKeys,
};

export type ConfigMetadataKeysType = {
  [typeName in keyof (typeof ConfigMetadataKeys)[keyof typeof ConfigMetadataKeys]]: (typeof ConfigMetadataKeys)[keyof typeof ConfigMetadataKeys][typeName];
};

/** IAM_ROLE_ARN and IAM_CHAIN_ROLES are combined to a list of objects containing the role as a value (to make them compatible with react-hook-form useFieldArray: https://react-hook-form.com/docs/usefieldarray)  */
interface CUSTOM_IAM_ROLES {
  IAM_ROLES: { value: string }[];
}

export type ExtractConfigMetadata = {
  [key in (typeof ExtractConfigMetadataKeys.string)[number]]: string;
} & {
  [key in (typeof ExtractConfigMetadataKeys.splitString)[number]]: string[];
} & {
  [key in (typeof ExtractConfigMetadataKeys.number)[number]]: number;
} & {
  [key in (typeof ExtractConfigMetadataKeys.boolean)[number]]: boolean;
} & CUSTOM_IAM_ROLES;

export type LoadConfigMetadata = {
  [key in (typeof LoadConfigMetadataKeys.string)[number]]: string;
} & {
  [key in (typeof LoadConfigMetadataKeys.splitString)[number]]: string[];
} & {
  [key in (typeof LoadConfigMetadataKeys.number)[number]]: number;
} & {
  [key in (typeof LoadConfigMetadataKeys.boolean)[number]]: boolean;
} & {
  //TODO: keyObjectDefinitionList
  //keyValuesList
  //TODO: Eventually elimitate 'COPY' nomenclature from APIs, Worker, and other services to avoid confusion
  //TODO: assuming error in documentation declaring  S3_FILE_PATH_TYPE= '';
  DATA_REPLACE_OPTION: keyof typeof keys.LoadReplaceOption;
  SOURCE_TYPE: keyof typeof keys.LoadSourceType;
  TARGET_DATABASE_TYPE: keyof typeof keys.TargetDBType;
  FILE_FORMAT: keyof typeof keys.LoadFileFormat;
  SOURCE_DEFINITION: string;
  // Merge keys are only expected when DATA_REPLACE_OPTION = UPSERT
  MERGE_KEY_FIELD?: string[];
} & CUSTOM_IAM_ROLES;

export type TransformConfigMetadata = {
  [key in (typeof TransformConfigMetadataKeys.string)[number]]: string;
} & {
  [key in (typeof TransformConfigMetadataKeys.splitString)[number]]: string[];
} & {
  [key in (typeof TransformConfigMetadataKeys.number)[number]]: number;
} & {
  [key in (typeof TransformConfigMetadataKeys.boolean)[number]]: boolean;
} & {
  TRANSFORM_SQLS: transformSQLItem[];
  TRANSFORM_PARAMETERS: transformParamItem[];

  IDENTITY_SEED_SCHEMA: string;
  IDENTITY_SEED_TABLE: string;
  IDENTITY_COLUMN: string;
  IDENTITY: boolean;
  PARAMETERS: string;

  //OPERATION_ORDER: ?; //TODO: Include once defined
  USE_AUDITOR: boolean;
  AUDIT_FAILURE_CONDITION: keyof typeof keys.CompareOperator;
  AUDIT_FAILURE_VALUE_HI: number;
  AUDIT_FAILURE_VALUE_LO: number;
  VALIDATION_QUERY: string;

  STORE_AUDIT: boolean;
  STORE_AUDIT_QUERY: string;
  STORE_AUDIT_SCHEMA: string;
  STORE_AUDIT_TABLE: string;
  STORE_AUDIT_LOCATION: string;
  STORE_AUDIT_UNLOAD: boolean;
} & CUSTOM_IAM_ROLES;

export interface AnyConfigMetadata extends ExtractConfigMetadata, LoadConfigMetadata, TransformConfigMetadata {
  ETL_LOAD_TYPE: keyof typeof keys.ActivityType;
}

/** This type returns the union of all ConfigMetadata keys, or takes in an optional ActivityType and returns that type's specific ConfigMetadata */
export type ConfigMetadata<ActivityType extends keyof typeof keys.ActivityType = any> =
  ActivityType extends typeof keys.ActivityType.EXTRACT
    ? ExtractConfigMetadata
    : ActivityType extends typeof keys.ActivityType.COPY
    ? LoadConfigMetadata
    : ActivityType extends typeof keys.ActivityType.TRANSFORM
    ? TransformConfigMetadata
    : AnyConfigMetadata;
