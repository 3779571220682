import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HelpPanels } from 'src/components/helpers';
import { OneOrList, ToList } from 'src/interfaces/reactInterfaces';
import { ReduxFlashbarItem, PageSliceType } from 'src/interfaces/reduxInterfaces';

const initialState: PageSliceType = {
  groupSelectorDisabled: false,
  oldHammerstoneHref: null,
  helpPanel: undefined,
  toolsOpen: false,
  flashbarItems: {},
  formFieldErrors: null,
  pipelineCooldown: {},
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    resetPage() {
      return initialState;
    },
    resetTools(state) {
      state.helpPanel = initialState.helpPanel;
      state.toolsOpen = initialState.toolsOpen;
    },
    setToolsOpen(state, { payload }: PayloadAction<boolean>) {
      state.toolsOpen = payload;
    },
    displayHelpPanel(state, { payload }: PayloadAction<keyof typeof HelpPanels>) {
      state.helpPanel = payload;
      state.toolsOpen = true;
    },
    setGroupSelectorDisabled(state, { payload }: PayloadAction<boolean>) {
      state.groupSelectorDisabled = payload;
    },
    setOldHammerstoneHref(state, { payload }: PayloadAction<string>) {
      state.oldHammerstoneHref = payload;
    },
    addToFlashbar(state, { payload }: PayloadAction<ReduxFlashbarItem>) {
      state.flashbarItems[payload.id] = payload;
    },
    setFormFieldErrors(state, { payload }: PayloadAction<{ [path: string]: string }>) {
      state.formFieldErrors = payload;
    },
    removeFromFlashbar(state, { payload }: PayloadAction<OneOrList<string>>) {
      ToList(payload).forEach((id) => {
        delete state.flashbarItems[id];
      });
    },
    setPipelineCooldown(state, { payload }: PayloadAction<{ pipelineName: string; cooldown: boolean }>) {
      state.pipelineCooldown[payload.pipelineName] = payload.cooldown;
    },
    clearNonPersistingFlashbarItems(state) {
      //Remove all flashbar items that are not persisting across the application
      state.flashbarItems = Object.fromEntries(
        Object.entries(state.flashbarItems).filter(([, item]) => item.persistAcrossApp),
      );
    },
  },
});
