import React, { useEffect, useMemo } from 'react';
import { FormField, Select } from '@amzn/awsui-components-react';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash-es';
import { useEditOnChange, useIsEditContentDisabled, useViewContent } from './contentHelpers';
import { DisplayMode, ResourcePath, ResourceType, iSelectContent } from './contentInterfaces';
import { LabeledContentSkeleton } from '../skeletons/LabeledContentSkeleton';
import { LabeledContent } from '..';
import { OptionsObj, optionsToObj, getNodeText } from 'src/commons';
import { HelpPanelInfoLink } from '../info/HelpPanelInfoLink';
import { DataAttributes, filterDataAttributes, getContentDataAttributes } from 'src/commons/dataAttributes';
import { ContentErrorWrapper } from 'src/components/error/ContentErrorBoundary';
import { Rules } from '.';

/** Renders the field identified by the `path` as a Polaris `<Select>`. Manipulates the value selected option's value as a `string` type.
 * For an overview of `<Content>` components, see the [README.md](./README.md). For usage examples, see the [How-to guide](./HowToGuide.md) */
export const SelectContent = ContentErrorWrapper(_SelectContent);

function _SelectContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iSelectContent<RType, DisabledOnPaths>,
) {
  // Memoized at top level component to avoid unneccessary executions of a relatively heavy functions between renders
  const dataAttributes = { ...filterDataAttributes(props), ...getContentDataAttributes('Select', props) };
  // Convert options list to an indexed object in order to efficiently retrieve the label corresponding to a value
  const optionsObj = useMemo(() => optionsToObj(props.options), [props.options]);

  switch (props.mode) {
    case DisplayMode.View:
      return <ViewSelectContent {...props} optionsObj={optionsObj} dataAttributes={dataAttributes} />;
    case DisplayMode.Edit:
      return <EditSelectContent {...props} optionsObj={optionsObj} dataAttributes={dataAttributes} />;
    case DisplayMode.Loading:
      return <LabeledContentSkeleton label={props.label} />;
    default:
      return null;
  }
}

function ViewSelectContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iSelectContent<RType, DisabledOnPaths> & { optionsObj: OptionsObj; dataAttributes: DataAttributes },
) {
  const { disabled, value: rawValue } = useViewContent(props);
  // Convert the value to its label, if available, otherwise displays raw value
  const value = props.optionsObj[rawValue]?.label ?? rawValue;

  return (
    <LabeledContent
      label={props.label}
      info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
      missingText={props.missingText}
    >
      <span className={disabled ? 'disabled-content' : ''} {...props.dataAttributes}>
        {props.viewTransform ? props.viewTransform(value) : value}
      </span>
    </LabeledContent>
  );
}

function EditSelectContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iSelectContent<RType, DisabledOnPaths> & { optionsObj: OptionsObj; dataAttributes: DataAttributes },
) {
  const { control, clearErrors, setValue } = useFormContext<ResourceType[RType]>();
  const disabled = useIsEditContentDisabled(props);

  return (
    <Controller
      control={control}
      name={props.path}
      rules={Rules.useRules(props.rules, props.label, disabled)}
      render={({ field, formState, fieldState }) => {
        const onChange = useEditOnChange(props, field.onChange, setValue, 'option');

        useEffect(() => {
          // This effect will ignore any potential validation errors if the particular field is disabled
          if (disabled) {
            clearErrors(props.path);
          }
        }, [disabled, formState.isSubmitting, formState.isValidating]);

        const value = (field.value as string) ?? '';

        return (
          <FormField
            label={props.label}
            description={props.editDescription}
            errorText={get(formState, `errors.${props.path}.message`)}
            info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
          >
            <span {...props.dataAttributes}>
              <Select
                // General props
                data-testid={getNodeText(props.label)}
                ref={field.ref}
                onChange={onChange}
                onBlur={field.onBlur}
                invalid={fieldState.invalid}
                disabled={disabled}
                // Select-specific props:
                placeholder={
                  props.placeholder ?? (props.options?.length > 0 ? 'Select option ...' : 'No options found.')
                }
                options={props.options}
                selectedOption={props.optionsObj[value] ?? { value: value, label: value }}
                selectedAriaLabel="Selected"
                // These props facilitate asynchronous options loading, which is especially useful for large dropdowns (e.g. CTI)
                filteringType={props.filteringType}
                onLoadItems={props.onLoadItems}
                statusType={props.statusType}
                loadingText={props.loadingText}
                errorText={props.errorText}
                finishedText={props.finishedText}
              />
            </span>
          </FormField>
        );
      }}
    />
  );
}
