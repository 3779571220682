import React, { useState, useEffect } from 'react';
import { ContainerSkeleton } from '../helpers';

import { Alert, SpaceBetween, Button, Icon, TextContent } from '@amzn/awsui-components-react';

import { DWHammerstoneRepositoryService as RepoService } from '@amzn/awsdwhammerstonerepository';
import { GetActivityInfo } from 'src/lib/repoServiceApi';

interface ActivityProps {
  action: 'edit' | 'view';
}

export default function GetActivity(props: ActivityProps) {
  // TODO: refactor, this is just a proof of concept with a Hammerstone Gamma activity
  const [activityInfo, setActivityInfo]: [RepoService.GetActivityInfoList, any] = useState();

  const ACTIVITY_ID = 46781;
  const ACTIVITY_GROUP = 'AWS-DW-PRIMARY';

  useEffect(() => {
    loadActivity(ACTIVITY_ID);
  }, [ACTIVITY_ID, ACTIVITY_GROUP]);

  const loadActivity = async (activityId: number) => {
    const res: RepoService.GetActivityInfoList = await GetActivityInfo({
      activityId,
      groupName: ACTIVITY_GROUP
    });
    setActivityInfo(res);
  };

  if (props.action === 'edit') {
    return (
      // TODO: Replace w/ actual Edit Activity page
      <SpaceBetween size="xl">
        <div>this component will edit the activity</div>
        <ContainerSkeleton columnsPerRow={[4, 4, 3, 3]} />
        <ContainerSkeleton columnsPerRow={[3, 3, 3]} final />
      </SpaceBetween>
    );
  } else if (props.action === 'view') {
    return (
      // TODO: Replace w/ actual View Activity page
      <SpaceBetween size="xl">
        <Button
          variant="link"
          target="_blank"
          href={`https://hammerstonegamma.amazon.com/manageactivity/${ACTIVITY_ID}`}
        >
          View this test activity in Old Hammerstone Gamma <Icon name="external" />
        </Button>
        <TextContent>
          Proof of concept Activity Details (returned from repo directly):
          <br />
          <br />
          {JSON.stringify(activityInfo)}
        </TextContent>
        <ContainerSkeleton columnsPerRow={[4, 4, 3, 3]} />
        <ContainerSkeleton columnsPerRow={[3, 3, 3]} final />
      </SpaceBetween>
    );
  } else {
    //TODO: Design site-wide error state/component and handling
    return (
      <Alert dismissAriaLabel="Close alert" type="error" header="Permission Denied">
        You are not authorized to access this page.
      </Alert>
    );
  }
}
