import { GetClusterUsingGroupOutput } from '@amzn/awsdwhammerstonerepository/clients/dwhammerstonerepositoryservice';
import React, { useMemo } from 'react';
import { fetchClustersByGroup } from 'src/data/api/fetchFromAPI';
import { useClusters, useGroupName } from 'src/data/redux';
import { useConditionalEffect } from 'src/commons';
import { iSelectContent, ResourcePath, ResourceType } from '../helpers/content/contentInterfaces';
import Content from '../helpers/content/';
import { SelectProps } from '@amzn/awsui-components-react';

/**
 * Written based on the logic of get_cluster_permissions in manageactivity_controller.rb
 * https://code.amazon.com/packages/AWSDWHammerstoneWebsite/blobs/6a73ed521ebcb835da97ffb1b4cd6a7ccea3b662/--/rails-root/app/controllers/manageactivity_controller.rb#L2610
 */
export function clusterHasAccess(cluster: GetClusterUsingGroupOutput, access: 'READ' | 'WRITE'): boolean {
  switch (access) {
    case 'WRITE':
      return ['WRITE-ONLY', 'WRITE', 'ADMIN'].includes(cluster.accessType);
    case 'READ':
      return ['READ', 'WRITE', 'ADMIN'].includes(cluster.accessType);
  }
}

function clustersToOptions(clusters: GetClusterUsingGroupOutput[], access: 'READ' | 'WRITE'): SelectProps.Options {
  // Only return clusters with the correct level of access
  const filteredClusters = (clusters ?? []).filter((cluster) => clusterHasAccess(cluster, access));

  // Sort clusters by their name
  const sortedClusters = filteredClusters.sort((clusterA, clusterB) =>
    clusterA.clusterName > clusterB.clusterName ? 1 : -1,
  );

  // Map each cluster name to a dropdown option
  return sortedClusters.map((cluster) => ({
    label: cluster.clusterName,
    value: cluster.clusterName,
    // Make more human "Status" instead of screaming "STATUS"
    labelTag: cluster.clusterStatusCode.charAt(0).toUpperCase() + cluster.clusterStatusCode.slice(1).toLowerCase(),
    description: `${cluster.leaderNodeHostname}:${cluster.clusterDbPort}/${cluster.databaseName}`,
    disabled: cluster.clusterStatusCode === 'DELETED',
  }));
}

export default function ClusterSelectContent<
  RType extends keyof ResourceType,
  DisableOnPaths extends ResourcePath<RType>[] = [],
>(props: Omit<iSelectContent<RType, DisableOnPaths>, 'options'> & { access: 'READ' | 'WRITE' }) {
  const groupName = useGroupName();
  useConditionalEffect(() => {
    fetchClustersByGroup({ groupName }, true);
  }, [groupName]);

  const { data: clusters } = useClusters(groupName);

  const clusterOptions = useMemo(() => clustersToOptions(clusters, props.access), [clusters, props.access]);

  // TODO: Eventually add refresh button adjacent to select to fetch the options again, and all cooldown to prevent spamming <Button iconName="refresh" variant="icon-inline" disabled={cooldown} loading={fetching} onClick={fetch...} />
  return <Content.Select infoHelpPanel="ClusterManagement" {...props} options={clusterOptions} filteringType="auto" />;
}
