import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { durationToString } from 'src/commons';
import { Column, ColumnContainer, transformTo } from '../helpers';
import Content from '../helpers/content/';
import { DisplayMode } from '../helpers/content/contentInterfaces';

export default function InstanceContainerLayout(props: { instanceId: string; mode: DisplayMode }) {
  const contentProps = {
    resourceId: props.instanceId,
    mode: props.mode,
    resourceType: 'instance',
  } as const;

  return (
    <SpaceBetween size="xl">
      <ColumnContainer header="Overview" columns={2}>
        <Column>
          <Content.Text label="Instance ID" path="instanceId" {...contentProps} />
          <Content.Text label="Command Run" path="commandRun" {...contentProps} />
          <Content.Text label="Redshift cluster" path="redshiftCluster" {...contentProps} />
        </Column>

        <Column>
          <Content.Text
            label="Status"
            path="instanceStatusCode"
            viewTransform={transformTo.statusIndicator}
            {...contentProps}
          />
          <Content.Number label="Retries" path="activityInstanceRetryCount" {...contentProps} />
        </Column>
        <Column>
          <Content.Datetime label="Schedule time" path="scheduleDateTime" {...contentProps} />
          <Content.Datetime label="Start time" path="startDateTime" {...contentProps} />
        </Column>
        <Column>
          <Content.Number
            label="Duration"
            viewTransform={(duration) => durationToString(duration, { granularity: 2 })}
            path="duration"
            {...contentProps}
          />
          <Content.Datetime label="End time" path="endDateTime" {...contentProps} />
        </Column>
      </ColumnContainer>
      <ColumnContainer header="Host schedules" columns={2}>
        <Column>
          <Content.Text label="RMS host" path="rmsHost" {...contentProps} />
          <Content.Text label="Worker Service host" path="workerServiceHost" {...contentProps} />
        </Column>
        <Column>
          <Content.Datetime label="RMS end time" path="rmsEndDateTime" {...contentProps} />
          <Content.Datetime label="Worker Service end time" path="wsEndDateTime" {...contentProps} />
        </Column>
      </ColumnContainer>
    </SpaceBetween>
  );
}
