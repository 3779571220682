import keys from 'src/constants/hammerstoneConstantKeys';
import Activity from 'src/interfaces/activityInterfaces';

/**
 * This function takes in an object of all the redux FormFields which are used to populate the inputs/fields, and from those fields returns a template of custom parameters for a LOAD/COPY activity
 * This logic is copied from the old hammerstone site: https://code.amazon.com/packages/AWSDWHammerstoneWebsite/blobs/b123c662f86e0dce22ca8beca642664e5fbd664e/--/rails-root/app/assets/javascripts/manageactivity/loadtype.js#L80-L148
 */
export function createLoadParametersTemplate(activity: Activity<'COPY'>) {
  // Variable values derived from fields
  const config = (key: keyof typeof activity.config) => (activity?.config[key] || '').toString().trim();

  let template = '';
  let format = '';
  let parameters = '';
  let load = "ACCEPTINVCHARS AS '?' COMPUPDATE OFF";

  switch (config('FILE_FORMAT') as keyof typeof keys.LoadFileFormat) {
    case keys.LoadFileFormat.PARQUET:
      format = 'FORMAT AS PARQUET';
      break;
    case keys.LoadFileFormat.CSV:
      format = 'FORMAT AS CSV ';
      parameters = 'ACCEPTANYDATE FILLRECORD EMPTYASNULL IGNOREBLANKLINES BLANKSASNULL ';
      break;
    case keys.LoadFileFormat.JSON:
      format = 'FORMAT AS JSON ';
      if (config('JSONPATHFILE') && config('JSONPATHFILEINPUT')) {
        format += "'" + config('JSONPATHFILEINPUT') + "' ";
      } else {
        format += "'auto' ";
      }
      parameters = 'ACCEPTANYDATE EMPTYASNULL BLANKSASNULL ';
      break;
    default:
      parameters = 'ACCEPTANYDATE FILLRECORD EMPTYASNULL IGNOREBLANKLINES BLANKSASNULL ';
      if (config('ESCAPE_OPTION')) {
        load = 'ESCAPE ' + load;
      }
      break;
  }

  if (config('FILE_FORMAT') === keys.LoadFileFormat.PARQUET) {
    template = format;
  } else {
    template += "TIMEFORMAT '" + config('TIME_FORMAT') + "' ";
    template += "DATEFORMAT '" + config('DATE_FORMAT') + "' ";
    template += "REGION '" + config('REGION') + "' ";
    if (config('FIELD_DELIMITER')) {
      template += "DELIMITER '" + config('FIELD_DELIMITER') + "' ";
    }
    if (config('ENCRYPTION')) {
      template += 'ENCRYPTED ';
    }
    if (config('GZIP')) {
      template += 'GZIP ';
    }
    template += 'MAXERROR AS ' + config('MAX_ERROR') + ' ';
    if (config('DRYRUN')) {
      template += 'NOLOAD ';
    }
    template += 'IGNOREHEADER AS ' + config('SKIP_LINES') + ' ';
    if (config('TRUNCATE_TO_FIT')) {
      template += 'TRUNCATECOLUMNS ';
    }
    if (config('TRIM')) {
      template += 'TRIMBLANKS ';
    }
    if (config('REMOVEQUOTES')) {
      template += 'REMOVEQUOTES ';
    }
    template += format + parameters + load;
  }

  return template;
}

/**
 * This function takes in an object of all the redux FormFields which are used to populate the inputs/fields, and from those fields returns a template of custom parameters for an EXTRACT or TRANSFORM activity
 * This logic is copied from the old hammerstone site: https://code.amazon.com/packages/AWSDWHammerstoneWebsite/blobs/a22ecd342b1d46a5bfdfcd44b7ece5de9bc376f7/--/rails-root/app/assets/javascripts/manageactivity/extracttype.js#L13-L42
 */
export function createExtractTransformParametersTemplate(activity: Activity) {
  // Variable values derived from fields
  const config = (key: keyof (Activity<'TRANSFORM'>['config'] & Activity<'EXTRACT'>['config'])) =>
    (activity?.config[key as keyof typeof activity.config] || '').toString().trim();
  let template = '';

  if (config('GZIP')) {
    template += 'GZIP ';
  }
  template += "DELIMITER '" + (config('FIELD_DELIMITER') ?? '|') + "' ";
  if (config('ALLOW_OVERWRITE')) {
    template += 'ALLOWOVERWRITE ';
  }
  if (config('MANIFEST')) {
    template += 'MANIFEST ';
  }
  // Transform activities do not define the ADDQUOTES field
  if (activity.activityType === 'TRANSFORM' && config('ADDQUOTES')) {
    template += 'ADDQUOTES ';
  }
  if (config('ESCAPE_OPTION')) {
    template += 'ESCAPE ';
  }
  if (config('REGION')) {
    template += "REGION '" + config('REGION') + "' ";
  }

  return template;
}
