import React from 'react';
import { Box } from '@amzn/awsui-components-react';

export function EmptyState({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
    </Box>
  );
}
