import React from 'react';
import { HammerstoneAppLayout } from '../helpers';
import ErrorLayout from './ErrorLayout';

export default function Error404() {
  return (
    <HammerstoneAppLayout title={`Page Not Found`}>
      <ErrorLayout header={'ERROR 404:'} subheader={'Page not found ...'} backtrackButtons>
        We're sorry that you couldn't find what you are looking for. You may want to return to either of the following:
      </ErrorLayout>
    </HammerstoneAppLayout>
  );
}
