import {
  KeyObjectDefinitionList,
  Metadata,
  KeyValuesList,
  KeyObjectsList,
} from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import keys from '../../constants/hammerstoneConstantKeys';
import { AnyConfigMetadata, ConfigMetadata, ConfigMetadataKeys } from '../../interfaces/configMetadataInterfaces';
import { escapeQuerySpecialChars } from '../../commons/objectManipulators';
import { KeyObjectDefinition } from '../../interfaces/reactInterfaces';
import Activity from 'src/interfaces/activityInterfaces';

const QueryKeys = new Set<keyof AnyConfigMetadata>([
  'SELECT_QUERY',
  'PRE_TRANSFORM_SQL',
  'POST_TRANSFORM_SQL',
  'STORE_AUDIT_QUERY',
  'VALIDATION_QUERY',
]);

/**
 * Reconstructs a configurationMetadata object for sending to API from user-input form fields in Activity view page
 * @param {object} activityForm The user-input fields (key=name, value=input)
 * @returns {Metadata} A configurationMetadata object with the correct types for the Hammerstone API
 */
export default function configMetadataPostprocess<ActivityType extends keyof typeof keys.ActivityType>(
  activityForm: Activity<ActivityType>,
): Metadata {
  // unfold from Redux store
  const { activityType, clusterName } = activityForm;

  // An empty Metadata object
  const resultConfig: Metadata = {
    keyValuesList: [],
    keyObjectDefinitionList: [],
  };

  // Sets the keyValueList from the formFields
  resultConfig.keyValuesList = buildMetadataKeyValuesList(
    activityForm.config as AnyConfigMetadata,
    activityType,
    clusterName,
  );

  // Depending on activity type, sets the keyObjectDefinitionList from the formFields
  if (activityType === keys.ActivityType.COPY) {
    resultConfig.keyObjectDefinitionList = buildLoadKeyObjectDefinitionList(
      activityForm.config as ConfigMetadata<'COPY'>,
    );
  } else if (activityType === keys.ActivityType.TRANSFORM) {
    resultConfig.keyObjectDefinitionList = buildTransformKeyObjectDefinitionList(
      activityForm.config as ConfigMetadata<'TRANSFORM'>,
    );
  }

  return resultConfig;
}

/**
 * Assembles the KeyValuesList from the formField's rebuilt `config` object
 *
 * @param {object} formConfig A pre-processed config object, rebuilt from the flattened formField
 * @param {string} activityType The type of the given activity (COPY/LOAD, EXTRACT, TRANSFORM)
 * @returns A new Metadata KeyValuesList
 */
function buildMetadataKeyValuesList<ActivityType extends keyof typeof keys.ActivityType>(
  formConfig: AnyConfigMetadata,
  activityType: ActivityType,
  clusterName?: string,
): KeyValuesList {
  const keyValuesList: KeyValuesList = [];

  // Fetch the metadata types and corresopnding keys based on the activity
  const metadataKeys = ConfigMetadataKeys[activityType];

  // Adds and cleans up all the string metadata, including assembling multi-string fields
  let targetDatabaseName = '';
  let targetSchemaName = '';
  let targetTableName = '';

  metadataKeys.string.forEach((key) => {
    let value: string;
    if (activityType === keys.ActivityType.COPY && key === 'FILE_FORMAT') {
      // Overwrites FILE_FORMAT for LOAD jobs to `CUSTOM` if the custom parameters are defined and non-empty:
      // This preserves a more consistent UX for adding custom parameters after setting relevant parameters (inc FILE_FORMAT)
      value = formConfig.CUSTOM_PARAMETERS?.length > 0 ? 'CUSTOM' : formConfig[key];
    } else if (QueryKeys.has(key)) {
      // TODO: Figure out correct escaping rules for various characters
      value = escapeQuerySpecialChars((formConfig[key] ?? '').toString());
    } else {
      value = formConfig[key];
    }

    if (typeof value !== 'undefined') {
      // Makes sure not to push undefined values to the API, since this will create a 500 NullPointerException. Alternatively, we could default undefined values to empty strings but that may cause unintended side-effects.
      keyValuesList.push({
        key,
        value,
      });
    }

    if (key === 'TARGET_DATABASE_NAME') {
      targetDatabaseName = formConfig[key].toString();
    }
    if (key === 'TARGET_SCHEMA_NAME') {
      targetSchemaName = formConfig[key].toString();
    }
    if (key === 'TARGET_TABLE_NAME') {
      targetTableName = formConfig[key].toString();
    }
  });

  /**
   * Assemble ALLLOADINGTARGETS if all Load cluster fields are filled out. It's a legacy field only used to display COPY activities on Rails site.
   * HS API then had to include it for backward compatibility, so we build it up here only to keep backend state consistent.
   * This is not used in Worker service when choosing what cluster to execute on, but Target table is:
   * So we are choosing to use the "Target" and "clusterName" variables moving forward, since it is more extendable.
   * ALLLOADINGTARGETS will be updated here for backward compatibility with Rails site.
   *
   * https://code.amazon.com/search?term=ALLLOADINGTARGETS+rp%3AAWSDWHammerstoneWebsite
   * https://w.amazon.com/bin/view/HammerstoneAPIUserGuideTemp/Documentations/APIs#HLoadJob
   * https://code.amazon.com/packages/AWSDWWorker/blobs/12150b774637a14004ef945674349dfe35d8a710/--/lib/GenerateRedshiftSQL.rb#L34
   *
   * TODO: delete old HS Website and deprecate this field
   */
  if (
    activityType === keys.ActivityType.COPY &&
    clusterName &&
    targetDatabaseName &&
    targetSchemaName &&
    targetTableName
  ) {
    keyValuesList.push({
      key: 'ALLLOADINGTARGETS',
      value: `${clusterName},${targetDatabaseName},${targetSchemaName},${targetTableName}`,
    });
  }

  // Adds and stringifies all the number metadata
  metadataKeys.number.forEach((key) => {
    keyValuesList.push({ key, value: formConfig[key].toString() });
  });

  // Adds and stringifies all boolean metadata
  metadataKeys.boolean.forEach((key) => {
    keyValuesList.push({ key, value: formConfig[key] ? 'Y' : 'N' });
  });

  // Reassembles and stringifies all splitString metadata
  metadataKeys.splitString.forEach((key) => {
    let value = '';
    switch (key) {
      case 'SNS_MESSAGE_OPTIONS':
        // Ensures that the 'custom message' option is enabled if a user defined a message, otherwise disabled
        const valueSet = new Set(formConfig[key] ?? []);
        const custom = 'custom message';
        if ((formConfig['SNS_CUSTOM_MESSAGE_TEXT'] ?? '').trim() === '') {
          valueSet.delete(custom);
        } else {
          valueSet.add(custom);
        }
        value = Array.from(valueSet).join(',');
        break;
      default:
        // Default is to join together values as comma-separated string
        value = (formConfig[key] ?? []).join(',');
    }
    keyValuesList.push({ key, value });
  });

  // Custom IAM Roles --> Decouple the initial role from the chain roles to update the KVPs according to how the API stores the values
  const [iamArn, ...chainRoles] = formConfig['IAM_ROLES'];
  keyValuesList.push({ key: 'IAM_ROLE_ARN', value: iamArn.value });
  if (chainRoles?.length > 0) {
    keyValuesList.push({ key: 'IAM_CHAIN_ROLES', value: ',' + chainRoles.map(({ value }) => value).join(',') });
  } else {
    keyValuesList.push({ key: 'IAM_CHAIN_ROLES', value: '' });
  }

  return keyValuesList;
}

/**
 * @param {object} formConfig A pre-processed config object, rebuilt from the flattened formField
 * @returns A new keyObjectDefinitionList containing a SOURCE_DEFINITION with the LOAD Activity's JSON definition
 */
function buildLoadKeyObjectDefinitionList(formConfig: ConfigMetadata<'COPY'>): KeyObjectDefinitionList {
  const keyObjectDefinitionList: KeyObjectDefinitionList = [];

  const LOCAL_SOURCE_DEFINITION: KeyValuesList[] = JSON.parse(formConfig.SOURCE_DEFINITION).keyObjectsList;
  const API_SOURCE_DEFINITION: KeyObjectsList = LOCAL_SOURCE_DEFINITION.map((values, index) => ({
    // Ruby-on-Rails site uses 1-indexing (instead of 0) for unclear reasons. Implemented here to maintain parity & reverse-compatibilty
    // https://code.amazon.com/packages/AWSDWHammerstoneWebsite/blobs/6a73ed521ebcb835da97ffb1b4cd6a7ccea3b662/--/rails-root/app/controllers/manageactivity_controller.rb#L1902-L1903
    key: `FIELD${index + 1}`,
    keyObjectsList: [],
    values,
  }));
  keyObjectDefinitionList.push({
    key: 'SOURCE_DEFINITION',
    values: [],
    keyObjectsList: API_SOURCE_DEFINITION,
  });

  if (formConfig.DATA_REPLACE_OPTION === keys.LoadReplaceOption.UPSERT) {
    const API_MERGE_KEY_FIELD: KeyObjectsList = formConfig.MERGE_KEY_FIELD?.map((value, index) => ({
      // Ruby-on-Rails site uses 1-indexing (instead of 0) for unclear reasons. Implemented here to maintain parity & reverse-compatibilty
      // https://code.amazon.com/packages/AWSDWHammerstoneWebsite/blobs/6a73ed521ebcb835da97ffb1b4cd6a7ccea3b662/--/rails-root/app/controllers/manageactivity_controller.rb#L1872-L1873
      key: `FIELD${index + 1}`,
      keyObjectsList: [],
      values: [{ key: 'FIELD_NAME', value }],
    }));

    keyObjectDefinitionList.push({
      key: 'MERGE_KEY_FIELD',
      values: [],
      keyObjectsList: API_MERGE_KEY_FIELD,
    });
  }

  return keyObjectDefinitionList;
}

/**
 * @param {object} formConfig A pre-processed config object, rebuilt from the flattened formField
 * @returns A new keyObjectDefinitionList containing a TRANFORM_OPERATION and (optionally) an AUDIT_OPERATION object from a TRANSFORM activity
 */
function buildTransformKeyObjectDefinitionList(formConfig: ConfigMetadata<'TRANSFORM'>): KeyObjectDefinitionList {
  const kodl: KeyObjectDefinitionList = [];

  const TRANSFORM_OPERATION = buildTransformOperation(formConfig);
  kodl.push(TRANSFORM_OPERATION);

  // Only include the AUDIT_OPERATION if you want to enable the auditor (there is no separate parameter for Enabling/Disabling Auditor)
  if (formConfig['USE_AUDITOR']) {
    const AUDIT_OPERATION = buildAuditOperation(formConfig);
    kodl.push(AUDIT_OPERATION);
  }

  return kodl;
}

/**
 * A helper function to build the TRANSFORM_OPERATION KeyObjectDefinition Object
 */
function buildTransformOperation(formConfig: ConfigMetadata<'TRANSFORM'>): KeyObjectDefinition {
  const TRANSFORM_OPERATION: KeyObjectDefinition = {
    key: 'TRANSFORM_OPERATION',
    keyObjectsList: [],
    values: [],
  };

  // Reconstruct the KeyObjectDefinition of TRANSFORM_OPERATION
  // Add the transform parameters
  const PARAMETERS_LIST: string[] = [];
  (formConfig['TRANSFORM_PARAMETERS'] ?? []).forEach(({ name, type, value }) => {
    PARAMETERS_LIST.push(name);
    TRANSFORM_OPERATION.keyObjectsList.push({
      key: name,
      keyObjectsList: [],
      values: [
        {
          key: 'TYPE',
          value: type,
        },
        {
          key: 'VALUE',
          value: value,
        },
      ],
    });
  });
  // Add the transform SQLs
  let i = 0;
  const OPERATION_ORDER_LIST: string[] = [];
  (formConfig['TRANSFORM_SQLS'] ?? []).forEach(({ type, value }) => {
    const name = `sqlText${i++}`;
    OPERATION_ORDER_LIST.push(name);
    TRANSFORM_OPERATION.keyObjectsList.push({
      key: name,
      keyObjectsList: [],
      values: [
        {
          key: 'TYPE',
          value: type,
        },
        {
          key: 'SQL',
          value: escapeQuerySpecialChars(value ?? ''),
        },
      ],
    });
  });

  // Reconstruct the values (keyValuesList) of TRANSFORM_OPERATION
  TRANSFORM_OPERATION.values.push(
    ...[
      {
        key: 'IDENTITY_SEED_SCHEMA',
        value: formConfig['IDENTITY_SEED_SCHEMA'] ?? '',
      },
      {
        key: 'IDENTITY_SEED_TABLE',
        value: formConfig['IDENTITY_SEED_TABLE'] ?? '',
      },
      {
        key: 'IDENTITY_COLUMN',
        value: formConfig['IDENTITY_COLUMN'] ?? '',
      },
      {
        key: 'IDENTITY',
        value: formConfig['IDENTITY_COLUMN'] ? 'Y' : 'N',
      },
      {
        key: 'PARAMETERS',
        value: PARAMETERS_LIST.join(','),
      },
      {
        key: 'OPERATION_ORDER',
        value: OPERATION_ORDER_LIST.join(','),
      },
    ],
  );
  return TRANSFORM_OPERATION;
}

/**
 * A helper function to build the AUDIT_OPERATION KeyObjectDefinition Object
 */
function buildAuditOperation(formConfig: ConfigMetadata<'TRANSFORM'>) {
  const AUDIT_OPERATION: KeyObjectDefinition = {
    key: 'AUDIT_OPERATION',
    keyObjectsList: [],
    values: [],
  };

  // Define the failure condition of the Auditor
  const FAILURE_CONDITION: KeyObjectDefinition = {
    key: 'FAILURE_CONDITION',
    keyObjectsList: [],
    values: [
      {
        key: 'OPERATOR',
        value: formConfig['AUDIT_FAILURE_CONDITION'],
      },
      {
        key: 'VALUE',
        // Value is either a stringified number, or two comma-separated numbers depending on the operation
        value: formConfig['AUDIT_FAILURE_CONDITION'].includes('BETWEEN')
          ? `${formConfig['AUDIT_FAILURE_VALUE_LO']},${formConfig['AUDIT_FAILURE_VALUE_HI']}`
          : formConfig['AUDIT_FAILURE_VALUE_HI'].toString(),
      },
    ],
  };

  const RUN_VALIDATION: KeyObjectDefinition = {
    key: 'RUN_VALIDATION',
    keyObjectsList: [FAILURE_CONDITION],
    values: [
      {
        key: 'VALIDATION_QUERY',
        value: formConfig['VALIDATION_QUERY'],
      },
    ],
  };

  AUDIT_OPERATION.keyObjectsList.push(RUN_VALIDATION);

  // Only add STORE_AUDIT object if you want to save results (no separate ENABLE/DISABLE parameter)
  if (formConfig['STORE_AUDIT']) {
    const STORE_AUDIT_RESULT = buildStoreAuditResult(formConfig);
    AUDIT_OPERATION.keyObjectsList.push(STORE_AUDIT_RESULT);
  }

  return AUDIT_OPERATION;
}

/**
 * A helper function to build the STORE_AUDIT_RESULT KeyObjectDefinition Object
 */
function buildStoreAuditResult(formConfig: ConfigMetadata<'TRANSFORM'>): KeyObjectDefinition {
  const STORE_AUDIT_RESULT: KeyObjectDefinition = {
    key: 'STORE_AUDIT_RESULT',
    keyObjectsList: [],
    values: [
      {
        key: 'QUERY',
        value: formConfig['STORE_AUDIT_QUERY'] ?? '',
      },
      {
        key: 'SCHEMA',
        value: formConfig['STORE_AUDIT_SCHEMA'] ?? '',
      },
      {
        key: 'AUDIT_TABLE',
        value: formConfig['STORE_AUDIT_TABLE'] ?? '',
      },
      {
        key: 'UNLOAD_AUDIT_RESULT',
        value: formConfig['STORE_AUDIT_UNLOAD'] ? 'Y' : 'N',
      },
      {
        key: 'UNLOAD_AUDIT_LOCATION',
        value: formConfig['STORE_AUDIT_LOCATION'],
      },
    ],
  };

  return STORE_AUDIT_RESULT;
}
