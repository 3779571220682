import React, { ReactNode, useRef } from 'react';
import { useConditionalEffect } from 'src/commons';
import { highlightElement } from 'prismjs';
import { CodeEditorProps } from '@amzn/awsui-components-react';

/** This component will highlight the syntax of its content (`children`) according to the provided language
 * Implemented using prismjs https://prismjs.com/ following best practices outlined for "Read-only code" by Cloudscape: https://cloudscape.aws.dev/components/code-editor/?tabId=api)
 * Uses div-pre-code elements conventions for displaying formatted code*/
export function CodeTextBox(props: { children: ReactNode; language?: CodeEditorProps.Language }) {
  const codeRef = useRef<HTMLElement>(null);
  useConditionalEffect(() => {
    highlightElement(codeRef.current);
  }, [codeRef.current]);

  return (
    <div className="custom-code-box">
      <pre>
        <code ref={codeRef} className={`language-${props.language ?? 'plain'}`}>
          {props.children}
        </code>
      </pre>
    </div>
  );
}
