import React from 'react';
import { Badge, FlashbarProps } from '@amzn/awsui-components-react';
import { count } from 'src/commons';
import store, { actions } from 'src/data/redux';
import { ReduxFlashbarItem } from 'src/interfaces/reduxInterfaces';

/**
 * Groups flashbar items by their type and header strings
 *
 * @param {ReduxFlashbarItem[]} items A list of Flashbar items to be grouped by certain message properties
 * @param {(keyof FlashbarProps.MessageDefinition)[]} groupBy A list of message properties by which to group flashbar items. Default is [type, header]. For no grouping, set to null/empty.
 * @returns An object mapping underscore-joined groupBy values to a list of flashbar items under that group
 */
function groupFlashbarItems(
  items: ReduxFlashbarItem[],
  groupBy: (keyof FlashbarProps.MessageDefinition)[] = ['type', 'header'],
): { [groupByKey: string]: ReduxFlashbarItem[] } {
  if (groupBy?.length > 0) {
    // If groupBy is a defined non-empty list, then join together the corresponding values in each item to create a dedpulicating `groupByKey` string
    return items.reduce((accum, curr) => {
      const groupByKey = groupBy.map((key) => curr.message[key] || '').join('_');
      if (!(groupByKey in accum)) {
        accum[groupByKey] = [];
      }
      accum[groupByKey].push(curr);
      return accum;
    }, {} as { [groupBy: string]: ReduxFlashbarItem[] });
  } else {
    // If groupBy is null, undefined, or an empty list [], then return each item
    return Object.fromEntries(items.map((value, ix) => [ix, [value]]));
  }
}

/**
 * Groups the `content` within an item to de-duplicate repeated messages
 *
 * @param {ReduxFlashbarItem[]} items A list of Redux Flashbar items to collapse down to a single Message by combining their content
 * @returns A message to be rendered by the `<Flashbar>` component in `<HammerstoneAppLayout>`
 */
function groupItemContent(items: ReduxFlashbarItem[]): FlashbarProps.MessageDefinition {
  let message: FlashbarProps.MessageDefinition = { ...items[0].message };
  // Group by message content
  let messages = items.map(({ message }) => message.content ?? '');

  // Add count badge to header
  message.header = (
    <>
      {message.header} <Badge color="grey">{messages.length}</Badge>
    </>
  );

  // Groups the message's content by their string and count the occurrences of each
  const contentList = Object.entries(count(messages))
    // Sort the message content by frequency
    .sort(([, freq1], [, freq2]) => freq2 - freq1)
    // Convert content to a `<li>` list of unique `content` messages, parenthetically append their frequency
    .map(([content, freq]) => {
      if (!content && freq === messages.length) {
        // If all of the messages have no content, don't display any content
        return null;
      } else {
        return (
          <li>
            {content || <span className="missing-data">no content</span>}{' '}
            {freq > 1 && freq < messages.length && <span>(&#xd7;{freq})</span>}
          </li>
        );
      }
    });

  // Set `content` to an unordered list of unique `content` messages
  message.content = <ul>{React.Children.toArray(contentList)}</ul>;
  // Set default dismissible for the grouped messages to true (unless all are set to false)
  message.dismissible = !items.map(({ message }) => message.dismissible).every((x) => x === false);
  return message;
}

/**
 * Converts the flashbarItems from the Redux store to a grouped list of Messages which can be rendered by the `<FLashbar>` in `<HammerstoneAppLayout>`
 */
export function flashbarItemsToList(itemsObj: { [id: string]: ReduxFlashbarItem }): FlashbarProps.MessageDefinition[] {
  // TODO: Eventually implement (de)serialization to save components in the Redux store
  const groupedByTypeAndHeader = groupFlashbarItems(Object.values(itemsObj));

  // collapse by type and header
  const flashbarItems: FlashbarProps.MessageDefinition[] = Object.values(groupedByTypeAndHeader).map((items) => {
    let message: FlashbarProps.MessageDefinition;
    if (items.length > 1) {
      // If there are multiple items with the same type and header, group their content together
      message = groupItemContent(items);
    } else {
      message = { ...items[0].message };
    }

    // Set default values
    message.dismissLabel ??= `Dismiss message(s)`;
    message.onDismiss ??= () => {
      store.dispatch(actions.page.removeFromFlashbar(items.map(({ id }) => id)));
    };
    // Use the remaining parameters from the first item instance
    // TODO: Eventually write a reducer to collapse down the props (p1 ?? p2 ?? ... ?? pN)
    return message;
  });
  return flashbarItems;
}

/**
 * Flashes a temporary message to the user using the `<Flashbar>` located in `<HammerstoneAppLayout>`
 *
 * Defaults to type = success, use upon completing a PUT/POST operation
 *
 * @param {ReduxFlashbarItem} item - A redux flashbar item, including a unique `id` prop to deduplicate identical messages
 * @param {number} timeout - Optional, ms until flashbar disappears (default is 3 secs)
 * @param onTimeout A function to be executed after the timeout, before the flashbar `item` is removed by its ID
 * @returns the Timeout object reference returned by setTimeout
 */
export function tempFlashbarMessage(
  item: ReduxFlashbarItem,
  timeout: number = 3 * 1000,
  onTimeout: () => void = () => {},
) {
  // Sets default item values:
  item.persistAcrossApp ??= true;
  item.message.dismissible ??= false;
  item.message.type ??= 'success';

  // Add item to flashbar
  store.dispatch(actions.page.addToFlashbar(item));

  // After `timeout` ms, remove item from flashbar
  return setTimeout(() => {
    onTimeout();
    store.dispatch(actions.page.removeFromFlashbar(item.id));
  }, timeout);
}
