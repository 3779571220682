import React from 'react';
import { ColumnContainer, Column } from '../../helpers';
import { TransformParamType } from '../../..//constants/hammerstoneConstants';
import { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { objToOptions } from 'src/commons';
import Content, { Rules } from 'src/components/helpers/content/';
import { ColumnLayout } from '@amzn/awsui-components-react';
import { AttributeEditorInput } from 'src/interfaces/inputInterfaces';

/**
 * An editable container for information about an TRANSFORM-type activity's configuration parameters & SQL
 */
export default function TransformConfigContainer(props: ActivityContainerProps) {
  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;

  return (
    <ColumnContainer
      header={props.header}
      footer={
        <Content.Toggle {...contentProps} label="Identity" path="config.IDENTITY" expandable>
          <ColumnLayout columns={3} variant="text-grid">
            <Content.Text
              label="Schema"
              path="config.IDENTITY_SEED_SCHEMA"
              disableOn={{
                paths: ['config.IDENTITY'],
                condition: (activity: any) => !activity.config.IDENTITY,
              }}
              rules={(l) => ({
                required: Rules.required(l),
                validate: {
                  redshiftStandardId: Rules.redshiftStandardId(),
                },
              })}
              {...contentProps}
            />
            <Content.Text
              label="Table"
              path="config.IDENTITY_SEED_TABLE"
              disableOn={{
                paths: ['config.IDENTITY'],
                condition: (activity: any) => !activity.config.IDENTITY,
              }}
              rules={(l) => ({
                required: Rules.required(l),
                validate: {
                  redshiftStandardId: Rules.redshiftStandardId(),
                },
              })}
              {...contentProps}
            />
            <Content.Text
              label="Column"
              path="config.IDENTITY_COLUMN"
              disableOn={{
                paths: ['config.IDENTITY'],
                condition: (activity: any) => !activity.config.IDENTITY,
              }}
              rules={(l) => ({
                required: Rules.required(l),
                validate: {
                  redshiftStandardId: Rules.redshiftStandardId(),
                },
              })}
              {...contentProps}
            />
          </ColumnLayout>
        </Content.Toggle>
      }
    >
      <Column>
        <Content.AttributeEditor
          label="Parameters"
          path="config.TRANSFORM_PARAMETERS"
          definitions={[
            {
              label: 'Variable name',
              key: 'name',
              input: AttributeEditorInput.Text,
              defaults: (allItems) => `var${allItems.length}`,
              rules: { required: Rules.required('Transform parameters variable name') },
            },
            {
              label: 'Type',
              key: 'type',
              options: objToOptions(TransformParamType),
              input: AttributeEditorInput.Select,
              defaults: true,
              rules: { required: Rules.required('Transform parameters type') },
            },
            {
              label: 'Value',
              key: 'value',
              input: AttributeEditorInput.Text,
              rules: { required: Rules.required('Transform parameters value') },
            },
          ]}
          {...contentProps}
        />
      </Column>
    </ColumnContainer>
  );
}
