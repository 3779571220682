// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight-change-enter {
  background-color: none;
}
.highlight-change-exit-active {
  font-weight: 900;
  background-color: #ffe180; 
  color: black;
  transition: all 100ms ease-in;
}
.highlight-change-exit-done {
  font-weight: 400;
  background-color: none;
  color: #16191f;
  transition: all 1400ms ease-out;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/highlight-new.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,6BAA6B;AAC/B;AACA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,cAAc;EACd,+BAA+B;AACjC","sourcesContent":[".highlight-change-enter {\n  background-color: none;\n}\n.highlight-change-exit-active {\n  font-weight: 900;\n  background-color: #ffe180; \n  color: black;\n  transition: all 100ms ease-in;\n}\n.highlight-change-exit-done {\n  font-weight: 400;\n  background-color: none;\n  color: #16191f;\n  transition: all 1400ms ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
