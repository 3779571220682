import { CodeEditor, CodeEditorProps } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePreferences, actions } from 'src/data/redux';
import { codeEditor_i18n } from '../../constants/i18n';

const blockedEvent = (e: React.MouseEvent | React.KeyboardEvent) => {
  e.preventDefault();
  e.stopPropagation();
  return;
};
const blockedParentProps = {
  onClick: blockedEvent,
  onKeyDown: blockedEvent,
  onKeyUp: blockedEvent,
  style: { position: 'relative', pointerEvents: 'none' } as React.CSSProperties,
};
const blockedChildProps = {
  style: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.5,
    backgroundColor: '#ededed',
    width: '100%',
    height: '100%',
    zIndex: 10,
  } as React.CSSProperties,
};

interface iBetterCodeEditor
  extends Pick<CodeEditorProps, 'value' | 'language' | 'editorContentHeight'>,
    Partial<Pick<CodeEditorProps, 'onValidate'>> {
  onChange: CodeEditorProps['onDelayedChange'];
  disabled?: boolean;
  className?: string;
}

/**
 * An improved CodeEditor Component:
 *    Allows disabling (blocks user input, prevents updating value, and greys out the code editor)
 *    Asynchronously fetches and sets the Ace object used to power the CodeEditor
 *    Manages the user's CodeEditor preferences using the Redux store (user.preferences.codeEditor)
 */
export function BetterCodeEditor(props: iBetterCodeEditor & { 'data-testid'?: string }) {
  const preferences = usePreferences('codeEditor');
  const dispatch = useDispatch();

  const [loadingAce, setLoadingAce] = useState(false);
  const [_ace, setAce] = useState<any>(undefined);

  useEffect(() => {
    setLoadingAce(true);
    import('ace-builds')
      .then((ace) =>
        import('ace-builds/webpack-resolver')
          .then(() => {
            ace.config.set('useStrictCSP', true);
            ace.config.set('loadWorkerFromBlob', false);
            setAce(ace);
            setLoadingAce(false);
          })
          .catch((e) => {
            console.error('Failed to Load Ace Webpack Resolver', e);
            setLoadingAce(false);
          }),
      )
      .catch((e) => {
        console.error('Failed to Load Ace Builds', e);
        setLoadingAce(false);
      });
  }, []);

  return (
    <div {...(props.disabled ? blockedParentProps : {})} className={`custom-code-editor ${props.className}`}>
      <CodeEditor
        data-testid={props['data-testid']}
        ace={_ace}
        loading={loadingAce}
        i18nStrings={codeEditor_i18n}
        // Retrieve and update preference to redux store
        preferences={preferences}
        onPreferencesChange={(e) => dispatch(actions.user.updatePreferences({ key: 'codeEditor', value: e.detail }))}
        // CodeEditor's `onChange` has been deprecated and replaced with `onDelayedChange`, but this interface keeps the `onChange` prop for consistency
        onDelayedChange={props.disabled ? () => {} : props.onChange}
        onValidate={props.onValidate}
        language={props.language}
        value={props.value}
        editorContentHeight={props.editorContentHeight}
      />
      <div {...(props.disabled ? blockedChildProps : {})}></div>
    </div>
  );
}
