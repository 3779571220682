import { SideNavigation } from '@amzn/awsui-components-react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { urlSearchString, useInternalOnFollow } from './navHelper';
import PAGES from './Pages';
import { getOldHammerstoneEndpoint, getOldPermissionsToolEndpoint } from '../constants/config';
import { useGroupName, useOldHammerstoneHref } from 'src/data/redux';

export default function SidebarNavigation() {
  const { pathname } = useLocation();
  const onFollow = useInternalOnFollow();
  const groupName = useGroupName();
  const oldHSHref = useOldHammerstoneHref() ?? '';

  const groupNameSearchString = urlSearchString({ groupName });
  const oldHSEndpoint = getOldHammerstoneEndpoint();
  const oldPTEndpoint = getOldPermissionsToolEndpoint();

  return (
    // Follow these label writing guidelines
    // https://polaris.a2z.com/components/side-navigation/?tabId=usage#writing-guidelines
    <SideNavigation
      items={[
        { 
          type: 'link', 
          text: 'Manage pipelines', 
          href: PAGES.MANAGE_PIPELINES.path + groupNameSearchString 
        },
        // Old Hammerstone links, copied 1:1 with old headers, except moved under different sections
        // TODO: Change order/sections when we revamp the Permissions Tool UI!
        {
          type: 'link',
          text: 'Ad hoc query',
          href: `${oldHSEndpoint}/adhoc${groupNameSearchString}`,
          external: true,
        },
        {
          type: 'link',
          text: 'Dashboard',
          href: `${oldHSEndpoint}/etldashboard${groupNameSearchString}`,
          external: true,
        },
        {
          type: 'section',
          text: 'Search',
          items: [
            {
              type: 'link',
              text: 'Precondition status',
              href: `${oldHSEndpoint}/edpstatus`,
              external: true,
            },
            {
              type: 'link',
              text: 'Precondition tree',
              href: `${oldHSEndpoint}/dependencytree`,
              external: true,
            },
            {
              type: 'link',
              text: 'Subscriptions',
              href: `${oldHSEndpoint}/subscriptions`,
              external: true,
            },
          ],
        },
        {
          type: 'section',
          text: 'Group management',
          items: [
            {
              type: 'link',
              text: 'Create group',
              href: `${oldPTEndpoint}/create-hammerstone-group`,
              external: true,
            },
            {
              type: 'link',
              text: 'Edit group',
              href: `${oldPTEndpoint}/edit-hammerstone-group`,
              external: true,
            },
            {
              type: 'link',
              text: 'Cross-team share',
              href: `${oldPTEndpoint}/hammerstone-ldap-management`,
              external: true,
            },
          ],
        },
        {
          type: 'section',
          text: 'Redshift cluster access',
          items: [
            {
              type: 'link',
              text: 'Allowlist cluster',
              href: `${oldPTEndpoint}/cluster-management`,
              external: true,
            },
            {
              type: 'link',
              text: 'Secrets Manager credentials',
              href: `${oldPTEndpoint}/secrets-manager-redshift-management`,
              external: true,
            },
            {
              type: 'link',
              text: 'Odin credentials (legacy)',
              href: `${oldPTEndpoint}/odin-redshift-management`,
              external: true,
            },
            {
              type: 'link',
              text: 'Add & remove users',
              href: `${oldPTEndpoint}/ldap-redshift-management`,
              external: true,
            },
            {
              type: 'link',
              text: 'Reset user password',
              href: `${oldPTEndpoint}/account-management`,
              external: true,
            },
          ],
        },
        { type: 'divider' },
        {
          type: 'link',
          text: 'Help',
          href: PAGES.HELP.path,
        },
        {
          type: 'link',
          text: 'Provide feedback',
          href: 'https://amazonmr.au1.qualtrics.com/jfe/form/SV_ebRzrVHXWmBMz6C',
          external: true,
        },
        {
          type: 'link',
          text: oldHSHref ? 'View this in old website' : 'Return to old experience',
          href: oldHSEndpoint + oldHSHref,
          external: true,
        },
      ]}
      activeHref={pathname + groupNameSearchString}
      onFollow={onFollow}
    />
  );
}
