import React from 'react';
import { Button } from '@amzn/awsui-components-react';

interface RefreshButtonProps {
  refreshing: boolean;
  disabled?: boolean;
  onRefresh: (e: CustomEvent) => void;
  children?: React.ReactNode;
}

export function RefreshButton(props: RefreshButtonProps) {
  return (
    // TODO: Get rid of unnecessary component!
    <Button
      onClick={props.onRefresh}
      disabled={props.disabled}
      data-testid="refresh-button"
      ariaLabel="Refresh data"
      loading={props.refreshing}
      iconName="refresh"
    >
      {props.children}
    </Button>
  );
}
