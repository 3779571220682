import React from 'react';
import { Icon, StatusIndicator } from '@amzn/awsui-components-react';
import keys from '../../constants/hammerstoneConstantKeys';
import { DjsExecutionStatus, StatusCode } from '../../constants/hammerstoneConstants';
import { colorTextStatusInactive, colorTextStatusSuccess } from '@amzn/awsui-design-tokens';

// Customized Status Indicators for Jobs and Pipelines, following Polaris Iconography guidelines, components, and best practices:
// This component is designed to maintain scalable consistency of our visual communication across pages
// https://polaris.a2z.com/foundation/visual-foundation/iconography/
// https://cloudscape.aws.dev/components/status-indicator/ (see ?tabId=usage)
// https://polaris.a2z.com/foundation/visual-foundation/colors/
/**
 * An Icon & Text component for Hammerstone constants (currently supports StatusCode and DjsExecutionStatus)
 * @param {string} children The key to the  Hammerstone constant (i.e. the StatusCode)
 * @returns A <StatusIndicator>-style component formatted according to the input key
 */
export function CustomStatusIndicator({ children }: { children?: string }): JSX.Element {
  return <span className="status-indicator">{CustomStatusIndicatorBuilder(children)}</span>;
}

function CustomStatusIndicatorBuilder(children: string): JSX.Element {
  if (!children) {
    // Catches issues with missing status, and makes them visible to the user (rather than simply displaying nothing, or failing)
    return <span className="missing-data">status missing</span>;
  }

  switch (children) {
    case keys.StatusCode.NEW:
      return <StatusIndicator type="info">{StatusCode[children]}</StatusIndicator>;
    case keys.StatusCode.DEACTIVATED:
      return (
        <StatusIndicator type="error" colorOverride="grey">
          {StatusCode[children]}
        </StatusIndicator>
      );
    case keys.StatusCode.SCHEDULED:
      return (
        <span style={{ color: colorTextStatusSuccess }} className=" awsui_root awsui_status">
          <Icon name="calendar" size="normal" variant="success" /> {StatusCode[children]}
        </span>
      );
    case keys.StatusCode.PAUSED:
      return <StatusIndicator type="stopped">{StatusCode[children]}</StatusIndicator>;
    case keys.StatusCode.EDIT_PENDING:
      return (
        <StatusIndicator type="pending" colorOverride="blue">
          {StatusCode[children]}
        </StatusIndicator>
      );
    case keys.StatusCode.MIGRATION_ERROR:
      return <StatusIndicator type="error">{StatusCode[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.CREATED:
      return <StatusIndicator type="info">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.ENQUEUED:
      return (
        <span style={{ color: colorTextStatusInactive }}>
          <Icon name="calendar" size="normal" variant="success" /> {DjsExecutionStatus[children]}
        </span>
      );
    case keys.DjsExecutionStatus.RUNNING:
      return <StatusIndicator type="in-progress">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.ONHOLD:
      return <StatusIndicator type="stopped">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.SUCCESS:
      return <StatusIndicator type="success">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.WAITING_DEPENDENCIES:
      return <StatusIndicator type="pending">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.WaitingToBePolled:
      return <StatusIndicator type="pending">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.FAILURE:
      return <StatusIndicator type="error">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.ErrorCancellation:
      return <StatusIndicator type="error">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.DependencyTimeout:
      return <StatusIndicator type="error">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.S3ConfigurationException:
      return <StatusIndicator type="error">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.ConfigurationException:
      return <StatusIndicator type="error">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.AgentPolledExecution:
      return <StatusIndicator type="info">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.DELETED:
      return (
        <StatusIndicator type="stopped" colorOverride="red">
          {DjsExecutionStatus[children]}
        </StatusIndicator>
      );
    case keys.DjsExecutionStatus.DELETE_PENDING:
      return (
        <StatusIndicator type="pending" colorOverride="red">
          {DjsExecutionStatus[children]}
        </StatusIndicator>
      );
    case keys.DjsExecutionStatus.ACTIVATE_PENDING:
      return (
        <StatusIndicator type="pending" colorOverride="green">
          {DjsExecutionStatus[children]}
        </StatusIndicator>
      );
    case keys.DjsExecutionStatus.DISCOVERED:
      return (
        <StatusIndicator type="info" colorOverride="green">
          {DjsExecutionStatus[children]}
        </StatusIndicator>
      );
    case keys.DjsExecutionStatus.PROCESSED:
      return <StatusIndicator type="success">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.SUBMITTED:
      return (
        <StatusIndicator type="pending" colorOverride="green">
          {DjsExecutionStatus[children]}
        </StatusIndicator>
      );
    case keys.DjsExecutionStatus.ENABLED:
      return <StatusIndicator type="success">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.DISABLED:
      return <StatusIndicator type="stopped">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.READONLY:
      return (
        <StatusIndicator type="info" colorOverride="grey">
          {DjsExecutionStatus[children]}
        </StatusIndicator>
      );
    case keys.DjsExecutionStatus.COMPLETED:
      return <StatusIndicator type="success">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.LOCKED:
      return <StatusIndicator type="stopped">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.UNLOCKED:
      return (
        <StatusIndicator type="success" colorOverride="grey">
          {DjsExecutionStatus[children]}
        </StatusIndicator>
      );
    case keys.DjsExecutionStatus.REJECTED:
      return <StatusIndicator type="error">{DjsExecutionStatus[children]}</StatusIndicator>;
    case keys.DjsExecutionStatus.CANCELLED:
      return (
        <StatusIndicator type="error" colorOverride="grey">
          {DjsExecutionStatus[children]}
        </StatusIndicator>
      );
    default:
      if (children in DjsExecutionStatus) {
        return (
          <StatusIndicator type="info">
            {/* If some other status is passed in, display it as an "info" */}
            {DjsExecutionStatus[children as keyof typeof DjsExecutionStatus]}
          </StatusIndicator>
        );
        // If some other string is passed in, displays text:
      } else if (children.toUpperCase().includes('ERROR')) {
        // If the custom status is (probably) an ERROR
        return <StatusIndicator type="error">{children}</StatusIndicator>;
      } else if (children.toUpperCase().includes('WARN')) {
        // If the custom status is (probably) a WARNING
        return <StatusIndicator type="warning">{children}</StatusIndicator>;
      } else if (children.toUpperCase().includes('LOAD')) {
        return <StatusIndicator type="loading">{children}</StatusIndicator>;
      } else {
        // If the custom status is something unanticipated, display in a neutral way
        return (
          <StatusIndicator type="info" colorOverride="grey">
            {children}
          </StatusIndicator>
        );
      }
  }
}
