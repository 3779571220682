import React from 'react';
import { Alert } from '@amzn/awsui-components-react';
import { useParams } from 'react-router';
import ViewActivity from './ViewActivity';
import EditActivity from './EditActivity';
import CreateActivity from './CreateActivity';
import CopyActivity from './CopyActivity';
import ActivityHistory from './ActivityHistory';
import { DisplayMode } from '../helpers/content/contentInterfaces';

type ActivityProps = { action: DisplayMode | 'monitor' | 'create' | 'copy' | 'history' };

/**
 * A routing component which helps render the correct components (and fetch certain data) ONLY IF the user is authorized to do so.
 */
export default function Activity(props: ActivityProps) {
  const { activityId } = useParams();

  switch (props.action) {
    case 'edit':
      return <EditActivity activityId={activityId} />;
    case 'create':
      return <CreateActivity />;
    case 'copy':
      return <CopyActivity activityId={activityId} />;
    case 'view':
      return <ViewActivity action="view" activityId={activityId} />;
    case 'monitor':
      return <ViewActivity action="monitor" activityId={activityId} />;
    case 'history':
      return <ActivityHistory activityId={activityId} />;
    default:
      return (
        <Alert dismissAriaLabel="Close alert" type="error" header="Permission Denied">
          You are not authorized to access this page.
        </Alert>
      );
  }
}
