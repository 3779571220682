import React, { useEffect } from 'react';
import { HammerstoneAppLayout } from '../helpers';
import { Box, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import PAGES from 'src/nav/Pages';
import { openInNewTab } from 'src/nav/navHelper';
import { getOldHammerstoneEndpoint } from 'src/constants/config';
import { delay } from 'src/commons';

export default function ActivityHistory(props: { activityId: string }) {
  const activityHistoryPageProps = PAGES.ACTIVITY_HISTORY.pageLayoutProps({ activityId: props.activityId });
  const oldRevisionHistoryHref = getOldHammerstoneEndpoint() + activityHistoryPageProps.oldHref;

  useEffect(() => {
    if (props.activityId) {
      delay(3 * 1000).then(() => {
        // Open page after 3 seconds to avoid bombarding user with popups and redirects
        openInNewTab(oldRevisionHistoryHref);
      });
    }
  }, [props.activityId]);

  return (
    <HammerstoneAppLayout {...activityHistoryPageProps}>
      <SpaceBetween size="m">
        <Header variant="h1">Revision History</Header>
        <Box variant="p" textAlign="center">
          <b>This page is under construction in the new Hammerstone React experience</b>
        </Box>
        <Box variant="p" textAlign="center">
          In the meantime, you may access this activity's{' '}
          <Link external target="_blank" href={oldRevisionHistoryHref}>
            revision history
          </Link>{' '}
          on the old Hammerstone experience.
        </Box>
        <Box variant="p" textAlign="center">
          The old page should open automatically in a new tab...
        </Box>
      </SpaceBetween>
    </HammerstoneAppLayout>
  );
}
