import React, { useMemo } from 'react';
import { ColumnLayout, Container, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';

import {
  NotifyOnStatus,
  SNSMessageOptionGroups,
  SNSMessageOptions,
  Severity,
} from 'src/constants/hammerstoneConstants';
import { HelpPanelInfoLink, transformTo } from '../../helpers';
import { objToOptions } from 'src/commons';
import { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { SNS_NOTIFICATION_USER_GUIDE_WIKI } from 'src/constants/staticUrls';
import Content, { Rules } from 'src/components/helpers/content/';
import CtiContent from 'src/components/helpers/CtiContent';

/**
 * An editable container for information about an Activity's Notifications and Alarms
 */
export default function NotificationsContainer(props: ActivityContainerProps) {
  // Memoized at top level to avoid unnecessary computations
  const SeverityOptions = useMemo(() => objToOptions(Severity).sort(), []);
  const NotifyOnOptions = useMemo(() => objToOptions(NotifyOnStatus), []);
  const MessageOptions = useMemo(() => objToOptions(SNSMessageOptions, SNSMessageOptionGroups), []);

  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;

  return (
    <Container
      data-testid={`column-container:notifications`}
      header={
        <Header
          variant="h2"
          info={
            <Link variant="info" target="_blank" href={SNS_NOTIFICATION_USER_GUIDE_WIKI}>
              Info
            </Link>
          }
        >
          {props.header}
        </Header>
      }
      footer={
        <Content.Toggle {...contentProps} path="alarm.active" label="Remedy alarm" expandable>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="m">
              <Content.Text
                {...contentProps}
                label="Remedy login"
                path="alarm.login"
                rules={(l) => ({
                  required: Rules.required(l),
                  maxLength: Rules.maxLength(128, l),
                })}
                disableOn={{ paths: ['alarm.active'], condition: (activity) => !activity.alarm.active }}
              />
              <Content.Text
                {...contentProps}
                label="Dedupe string"
                path="alarm.dedupe"
                rules={(l) => ({
                  required: Rules.required(l),
                  maxLength: Rules.maxLength(128, l),
                })}
                disableOn={{ paths: ['alarm.active'], condition: (activity) => !activity.alarm.active }}
              />
              <Content.Select
                {...contentProps}
                label="Alarm severity"
                path="alarm.severity"
                disableOn={{ paths: ['alarm.active'], condition: (activity) => !activity.alarm.active }}
                options={SeverityOptions}
                rules={(l) => ({ required: Rules.required(l) })}
              />
            </SpaceBetween>

            <SpaceBetween size="m">
              <CtiContent
                {...contentProps}
                path={{ category: 'alarm.category', type: 'alarm.type', item: 'alarm.item' }}
                disableOn={{ paths: ['alarm.active'], condition: (activity) => !activity.alarm.active }}
              />
            </SpaceBetween>

            <SpaceBetween size="m">
              <Header variant="h3" info={<HelpPanelInfoLink helpPanel="RemedyAlarmForPreconditions" />}>
                Remedy alarm for preconditions
              </Header>
              <Content.Toggle
                {...contentProps}
                label="All executions fail"
                path="config.DJS_ALARM_ALL_EXECUTIONS_FAILED"
                disableOn={{ paths: ['alarm.active'], condition: (activity) => !activity.alarm.active }}
              />
              <Content.Toggle
                {...contentProps}
                label="Each execution fails"
                path="config.DJS_ALARM_EXECUTION_FAILURE"
                disableOn={{ paths: ['alarm.active'], condition: (activity) => !activity.alarm.active }}
              />
              <Content.Toggle
                {...contentProps}
                label="Scheduler disabled"
                path="config.DJS_ALARM_DISABLED_JOB"
                disableOn={{ paths: ['alarm.active'], condition: (activity) => !activity.alarm.active }}
              />
            </SpaceBetween>
          </ColumnLayout>
        </Content.Toggle>
      }
    >
      <ColumnLayout columns={2}>
        <SpaceBetween size="m">
          <Content.Multiselect
            {...contentProps}
            label="Notify on"
            options={NotifyOnOptions}
            path="config.SNS_NOTIFY_ON"
            missingText="Notifications are disabled"
            viewTransform={transformTo.badgeList}
          />
          <Content.Text
            {...contentProps}
            label="SNS Topic ARN"
            path="config.SNS_TOPIC_ARN"
            rules={(l) => ({
              maxLength: Rules.maxLength(512, l),
              pattern: Rules.pattern.snsTopicArn(l),
            })}
            disableOn={{
              paths: ['config.SNS_NOTIFY_ON'],
              condition: (activity) => !(activity.config.SNS_NOTIFY_ON?.length > 0),
            }}
          />
        </SpaceBetween>
        <SpaceBetween size="m">
          <Content.Multiselect
            {...contentProps}
            label="Message options"
            path="config.SNS_MESSAGE_OPTIONS"
            options={MessageOptions}
            viewTransform={transformTo.badgeList}
            missingText="No options specified"
            disableOn={{
              paths: ['config.SNS_NOTIFY_ON'],
              condition: (activity) => !(activity.config.SNS_NOTIFY_ON?.length > 0),
            }}
          />
          <Content.Textarea
            {...contentProps}
            label="Custom message"
            path="config.SNS_CUSTOM_MESSAGE_TEXT"
            disableOn={{
              paths: ['config.SNS_NOTIFY_ON'],
              condition: (activity) => !(activity.config.SNS_NOTIFY_ON?.length > 0),
            }}
            // Should we disable for missing option?
            rules={(l) => ({ maxLength: Rules.maxLength(2 ** 31 - 1, l) })}
          />
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
