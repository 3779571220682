import { Button, ButtonProps, LinkProps } from '@amzn/awsui-components-react';
import React from 'react';
import { DataAttributes, filterDataAttributes } from 'src/commons/dataAttributes';
import { useInternalOnFollow } from 'src/nav/navHelper';

interface iLinkButton extends ButtonProps, DataAttributes {
  href: string;
  external?: boolean;
}

/**
 * A Button with an href prop which can navigate both internally and externally
 *
 * Rationale: Polaris / Cloudscape Buttons already have href properties, but their onFollow functions do not actually take in a populated event with the href/external/target details.
 * This component has all the visual/interface properties of a Button (with the addition of an optional `external` prop) but has the navigational features of a Link
 */
export function LinkButton(props: iLinkButton) {
  const buttonProps = { ...(props as ButtonProps) };
  // If no target is set, uses _blank for external and _self for internal links
  buttonProps.target = props.target ?? (props.external ? '_blank' : '_self');
  // If no iconName is set and button is external, sets to 'external'
  buttonProps.iconName = props.iconName ?? (props.external ? 'external' : undefined);

  const internalOnFollow = useInternalOnFollow();
  const onFollow = props.onFollow ?? internalOnFollow;
  buttonProps.onFollow = (e) => {
    const detail: LinkProps.FollowDetail = {
      href: props.href,
      external: props.external as boolean,
      target: buttonProps.target,
    };
    if (!props.external) {
      e.preventDefault();
    }
    onFollow({ detail, preventDefault: e.preventDefault } as CustomEvent<any>);
  };
  return <Button {...filterDataAttributes(props)} {...buttonProps} />;
}
