import React from 'react';
import { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { ColumnContainer } from '../../helpers';
import ClusterSelectContent from '../ClusterSelectContent';
import Content, { Rules } from 'src/components/helpers/content/';

/**
 * An editable container for a LOAD/COPY activity's load target configuration
 */
export default function LoadConfigContainer(props: ActivityContainerProps) {
  // See README for Redux explanation
  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;

  return (
    <ColumnContainer header={props.header}>
      <ClusterSelectContent
        label="Target cluster (write-access)"
        access="WRITE"
        path="clusterName"
        rules={(l) => ({ required: Rules.required(l) })}
        {...contentProps}
      />
      <Content.Text
        label="Database"
        // min/max lengths should match the regex. It's not redundant because we need to tell the user an input is outside of boundaries dynamically
        editDescription="Database names are not case sensitive, please use lowercase letters."
        rules={(l) => ({
          required: Rules.required(l),
          minLength: Rules.minLength(1, l),
          maxLength: Rules.maxLength(64, l),
          validate: { redshiftDatabaseName: Rules.redshiftDatabaseName(l) },
        })}
        path="config.TARGET_DATABASE_NAME"
        {...contentProps}
      />
      <Content.Text
        label="Schema"
        rules={(l) => ({
          required: Rules.required(l),
          minLength: Rules.minLength(1, l),
          maxLength: Rules.maxLength(127, l),
          validate: { redshiftStandardId: Rules.redshiftStandardId(l) },
        })}
        path="config.TARGET_SCHEMA_NAME"
        {...contentProps}
      />
      <Content.Text
        label="Table"
        rules={(l) => ({
          required: Rules.required(l),
          minLength: Rules.minLength(1, l),
          maxLength: Rules.maxLength(127, l),
          validate: { redshiftStandardId: Rules.redshiftStandardId(l) },
        })}
        path="config.TARGET_TABLE_NAME"
        {...contentProps}
      />
    </ColumnContainer>
  );
}
