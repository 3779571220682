// https://polaris.a2z.com/components/date-picker/?tabId=api
export const datePicker_ariaLabels = {
  nextMonthAriaLabel: 'Next month',
  openCalendarAriaLabel: (selectedDate: string) =>
    'Choose dates' + (selectedDate ? `, selected date is ${selectedDate}` : ''),
  previousMonthAriaLabel: 'Previous month',
  todayAriaLabel: 'Today',
} as const;

/** Saltire, AKA "☓" symbol, in unicode */
export const SALTIRE = '\u2613';
