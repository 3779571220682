// https://docs.aws.amazon.com/redshift/latest/dg/r_pg_keywords.html
const REDSHIFT_RESERVED_KEYWORDS = [
  'AES128',
  'AES256',
  'ALL',
  'ALLOWOVERWRITE',
  'ANALYSE',
  'ANALYZE',
  'AND',
  'ANY',
  'ARRAY',
  'AS',
  'ASC',
  'AUTHORIZATION',
  'AZ64',
  'BACKUP',
  'BETWEEN',
  'BINARY',
  'BLANKSASNULL',
  'BOTH',
  'BYTEDICT',
  'BZIP2',
  'CASE',
  'CAST',
  'CHECK',
  'COLLATE',
  'COLUMN',
  'CONSTRAINT',
  'CREATE',
  'CREDENTIALS',
  'CROSS',
  'CURRENT_DATE',
  'CURRENT_TIME',
  'CURRENT_TIMESTAMP',
  'CURRENT_USER',
  'CURRENT_USER_ID',
  'DEFAULT',
  'DEFERRABLE',
  'DEFLATE',
  'DEFRAG',
  'DELTA',
  'DELTA32K',
  'DESC',
  'DISABLE',
  'DISTINCT',
  'DO',
  'ELSE',
  'EMPTYASNULL',
  'ENABLE',
  'ENCODE',
  'ENCRYPT',
  'ENCRYPTION',
  'END',
  'EXCEPT',
  'EXPLICIT',
  'FALSE',
  'FOR',
  'FOREIGN',
  'FREEZE',
  'FROM',
  'FULL',
  'GLOBALDICT256',
  'GLOBALDICT64K',
  'GRANT',
  'GROUP',
  'GZIP',
  'HAVING',
  'IDENTITY',
  'IGNORE',
  'ILIKE',
  'IN',
  'INITIALLY',
  'INNER',
  'INTERSECT',
  'INTERVAL',
  'INTO',
  'IS',
  'ISNULL',
  'JOIN',
  'LANGUAGE',
  'LEADING',
  'LEFT',
  'LIKE',
  'LIMIT',
  'LOCALTIME',
  'LOCALTIMESTAMP',
  'LUN',
  'LUNS',
  'LZO',
  'LZOP',
  'MINUS',
  'MOSTLY16',
  'MOSTLY32',
  'MOSTLY8',
  'NATURAL',
  'NEW',
  'NOT',
  'NOTNULL',
  'NULL',
  'NULLS',
  'OFF',
  'OFFLINE',
  'OFFSET',
  'OID',
  'OLD',
  'ON',
  'ONLY',
  'OPEN',
  'OR',
  'ORDER',
  'OUTER',
  'OVERLAPS',
  'PARALLEL',
  'PARTITION',
  'PERCENT',
  'PERMISSIONS',
  'PIVOT',
  'PLACING',
  'PRIMARY',
  'RAW',
  'READRATIO',
  'RECOVER',
  'REFERENCES',
  'REJECTLOG',
  'RESORT',
  'RESPECT',
  'RESTORE',
  'RIGHT',
  'SELECT',
  'SESSION_USER',
  'SIMILAR',
  'SNAPSHOT ',
  'SOME',
  'SYSDATE',
  'SYSTEM',
  'TABLE',
  'TAG',
  'TDES',
  'TEXT255',
  'TEXT32K',
  'THEN',
  'TIMESTAMP',
  'TO',
  'TOP',
  'TRAILING',
  'TRUE',
  'TRUNCATECOLUMNS',
  'UNION',
  'UNIQUE',
  'UNNEST',
  'UNPIVOT',
  'USER',
  'USING',
  'VERBOSE',
  'WALLET',
  'WHEN',
  'WHERE',
  'WITH',
  'WITHOUT',
];

// Create a RegEx that matches any string that IS NOT exactly one of the above Redshift keywords
// EXAMPLE: /^(?!one$|two$|three$).*$/ will match any string that is NOT exactly "one", "two", or "three"
const REDSHIFT_NOT_RESERVED_KEYWORD_STRING = `^(?!${REDSHIFT_RESERVED_KEYWORDS.map((kw) => kw + '$').join('|')}).*$`;
// Creates a RegEx that matches the above conditions but is case insensitive
const REDSHIFT_NOT_RESERVED_KEYWORD_REGEX = new RegExp(REDSHIFT_NOT_RESERVED_KEYWORD_STRING, 'i');
// Matches strings that have between 1 and 64 characters
const REDSHIFT_1_64_CHARS_REGEX = /^.{1,64}$/;
// Matches strings that have NO uppercase (only lowercase) characters
const REDSHIFT_NO_UPPERCASE_REGEX = /^[^A-Z]*$/;
// Matches strings that have only alphanumeric characters
const REDSHIFT_ALPHANUMERIC_OR_DASH_OR_UNDERSCORE_ONLY_REGEX = /^[a-zA-Z0-9_\-]*$/;
// Matches strings that begin with an alphabetic character
const REDSHIFT_INIT_ALPHABET_OR_UNDERSCORE = /^[_a-zA-Z]/;
// Matches strings that have only alphanumeric, underscore, or dollar-sign characters
const REDSHIFT_ALPHANUMERIC_UNDERSCORE_OR_DOLLAR_REGEX = /^[a-zA-Z0-9\_\$]*$/;
// Matches strings that do not contain any spaces or double-quotes
const REDSHIFT_NO_QUOTES_OR_SPACES = /^[^ "]*$/;
// Matches strings that have between 1 and 64 characters
const REDSHIFT_1_127_CHARS_REGEX = /^.{1,127}$/;

/**
 * Checks whether the input is a valid Database name according to Redshift's naming constraints
 * The string must be 1-64 lowercase alphanumeric characters and cannot be one of the protected Redshift Keywords
 * 
 * From Oct 20, 2023 it appears that about 230 cluster database names include hyphens or underscores in their name.
 * According to Redshift's documentation, these database names should violate naming constraints.
 * These constraints may be incorrect, but even if Redshift now enforces this rule moving forward,
 * we should still maintain backwards compatibility with customers' existing jobs. 
 * 
 * See "Standard Identifiers": https://docs.aws.amazon.com/redshift/latest/dg/r_names.html
 * See "Naming Constraints": https://docs.aws.amazon.com/redshift/latest/mgmt/amazon-redshift-limits.html (potentially outdated)
 * List of Keywords: https://docs.aws.amazon.com/redshift/latest/dg/r_pg_keywords.html
 */
export function isRedshiftDatabaseName(value: string) {
  return [
    REDSHIFT_NOT_RESERVED_KEYWORD_REGEX,
    REDSHIFT_1_64_CHARS_REGEX,
    REDSHIFT_ALPHANUMERIC_OR_DASH_OR_UNDERSCORE_ONLY_REGEX,
    REDSHIFT_NO_UPPERCASE_REGEX,
  ].every((regex) => regex.test(value));
}

/**
 * Checks whether the input is a valid Standard Identifier according to Redshift's naming constraints
 * The string must be 1-127 alphabetic, underscore, or dollar-sign characters (not beginning with dollar-sign) and cannot be one of the protected Redshift Keywords
 * We explicitly disallow "UTF-8 multibyte characters two to four bytes long" to keep things simple for our backend.
 * If this is a customer requirement (exceedingly rare), please adjust accordingly.
 *
 * See "Standard Identifiers": https://docs.aws.amazon.com/redshift/latest/dg/r_names.html
 * List of Keywords: https://docs.aws.amazon.com/redshift/latest/dg/r_pg_keywords.html
 */
export function isRedshiftStandardId(value: string) {
  return [
    REDSHIFT_NOT_RESERVED_KEYWORD_REGEX,
    REDSHIFT_INIT_ALPHABET_OR_UNDERSCORE,
    REDSHIFT_ALPHANUMERIC_UNDERSCORE_OR_DOLLAR_REGEX,
    REDSHIFT_1_127_CHARS_REGEX,
    REDSHIFT_NO_QUOTES_OR_SPACES,
  ].every((regex) => regex.test(value));
}

/* These values are listed in  https://docs.aws.amazon.com/redshift/latest/dg/c_Supported_data_types.html */
export const REDSHIFT_DATA_TYPES_TO_ALIASES = {
  'SMALLINT': ['INT2'],
  'INTEGER': ['INT', 'INT4'],
  'BIGINT': ['INT8'],
  'DECIMAL': ['NUMERIC'],
  'REAL': ['FLOAT4'],
  'DOUBLE PRECISION': ['FLOAT8', 'FLOAT'],
  'BOOLEAN': ['BOOL'],
  'CHAR': ['CHARACTER', 'NCHAR', 'BPCHAR'],
  'VARCHAR': ['CHARACTER VARYING', 'NVARCHAR', 'TEXT'],
  'DATE': [],
  'TIMESTAMP': ['TIMESTAMP WITHOUT TIME ZONE'],
  'TIMESTAMPTZ': ['TIMESTAMP WITH TIME ZONE'],
  'GEOMETRY': [],
  'GEOGRAPHY': [],
  'HLLSKETCH': [],
  'SUPER': [],
  'TIME': ['TIME WITHOUT TIME ZONE'],
  'TIMETZ': ['TIME WITH TIME ZONE'],
  'VARBYTE': ['VARBINARY', 'BINARY VARYING'],
} as const;

/* Derived by flattening REDSHIFT_DATA_TYPES_TO_ALIASES, values listed in  https://docs.aws.amazon.com/redshift/latest/dg/c_Supported_data_types.html */
export const ALL_REDSHIFT_DATA_TYPES = new Set(
  Object.entries(REDSHIFT_DATA_TYPES_TO_ALIASES)
    .map(([TYPE, ALIASES]) => [TYPE, ...ALIASES])
    .flat(),
);
