import { Badge, Button, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { CustomStatusIndicator } from './CustomStatusIndicator';

/** Takes in an alias string. Returns a span with that alias and a profile button linking to that alias' phonetool page */
export function userProfile(alias: string) {
  return (
    <span>
      <Button
        iconName="user-profile"
        iconAlt="phonetool user icon link button"
        href={alias ? `https://phonetool.amazon.com/users/${alias}` : ''}
        target="_blank"
        variant="inline-icon"
      />{' '}
      {alias}
    </span>
  );
}

/** Takes in a status string. Returns a `<CustomStatusIndicator>` which maps the status to it's humanized string and status icon.   */
export function statusIndicator(status: string) {
  return <CustomStatusIndicator>{status}</CustomStatusIndicator>;
}

/** Takes in a list of strings and returns a list of badges for each of those strings. Typically used for visualizing the selected options from `<Multiselect>`.  */
export function badgeList(values: string[]) {
  return (
    <SpaceBetween direction="horizontal" size="xs">
      {React.Children.toArray(values.map((value) => <Badge>{value}</Badge>))}
    </SpaceBetween>
  );
}
