// --- === COMPONENT HELPERS === ---
import { DependencyList, EffectCallback, useEffect } from 'react';

/**
 * Source: https://stackoverflow.com/a/33928558
 *
 * Copies a string to the clipboard. Must be called from within an
 * event handler such as click. May return false if it failed, but
 * this is not always possible. Browser support for Chrome 43+,
 * Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
 * Internet Explorer: The clipboard feature may be disabled by
 * an administrator. By default a prompt is shown the first
 * time the clipboard is used (per session).
 *
 * @param {string} text The text to be copied to the clipboard
 */
export function copyToClipboard(text: string) {
  const w: any = window;
  const doc: any = document;
  if (w.clipboardData && w.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return w.clipboardData.setData('Text', text);
  } else if (doc.queryCommandSupported && doc.queryCommandSupported('copy')) {
    const textarea = doc.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    doc.body.appendChild(textarea);
    textarea.select();
    try {
      return doc.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.error('Copy to clipboard failed.', ex);
      return prompt('Copy to clipboard: Ctrl+C, Enter', text);
    } finally {
      doc.body.removeChild(textarea);
    }
  }
}

/**
 * Create a sortComparator function (useful for sorting table) based on a particular key and comparison function
 *
 * @param {string} key The key of the object that will be passed to the comparison function (`<Table>` passes in type of `props.items[number]`)
 * @param {Function} comparisonFunc A comparison function which takes two parameters and returns a number representing their partial ordering
 *
 * @returns A Comparator function which takes two objects containing the given key, and returns a comparison of the objects' value at the key
 */
export function compareBy<O>(key: keyof O, comparisonFunc: (a: any, b: any) => number) {
  return (a: O, b: O) => {
    // Pushes undefined values to bottom
    if (!b || typeof b[key] === 'undefined' || b[key] === null) {
      return +1;
    } else if (!a || typeof a[key] === 'undefined' || a[key] === null) {
      return -1;
    } else {
      return comparisonFunc(b[key], a[key]);
    }
  };
}

/**
 * A custom hook which creates an effect that only executes if all the dependencies are truthy.
 *
 * More on Effects: https://www.w3schools.com/react/react_useeffect.asp
 * More on Truthiness and Falsiness: https://basarat.gitbook.io/typescript/recap/truthy
 * Documentation: https://reactjs.org/docs/hooks-effect.html
 */
export function useConditionalEffect(effect: EffectCallback, deps: DependencyList) {
  useEffect(() => {
    if (deps.every((dep) => dep)) {
      return effect();
    }
  }, deps);
}

export const changeBy = {
  value: (updater: Function) => (e: CustomEvent) => updater(e.detail.value),
  checked: (updater: Function) => (e: CustomEvent) => updater(e.detail.checked),
  option: (updater: Function) => (e: CustomEvent) => updater(e.detail.selectedOption.value),
  options: (updater: Function) => (e: CustomEvent) => {
    updater(e.detail.selectedOptions.map(({ value }: { value: string }) => value));
  },
};
