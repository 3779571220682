import React, { Component } from 'react';
import { HAMMERSTONE_HELP_DESK } from 'src/constants/staticUrls';

/**
 * If the React application fails, this component will render.
 * This is the FINAL error boundary, meaning that all descendant error boundaries must also have failed if it renders (e.g. PageErrorBoundary)
 * Since this is the highest-level component in the application, it should not have access to Routes, the Redux store, or any lower level components or states.
 * As a rule of thumb, try to avoid introducing external dependencies (e.g. Polaris) which may cause additional failures.
 *
 * For more information: https://reactjs.org/docs/error-boundaries.html
 */
export default class AppErrorBoundary extends Component<{}, { hasError: boolean; error: any }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  // componentDidCatch(error: any, errorInfo: any) {
  // TODO: Log error to services? Report failure?
  // }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Hammerstone encountered a fatal error</h1>
          <p>
            We apologize for the inconvenience. You may try to{' '}
            <button
              onClick={() => {
                location.reload();
              }}
            >
              refresh
            </button>{' '}
            the page or navigate back to the Hammerstone home page.
          </p>
          <p>
            If the problem persists, please cut us a ticket <a href={HAMMERSTONE_HELP_DESK}>here</a>
          </p>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
