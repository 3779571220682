// --- === DATA PREPROCESSING CONVERTERS === ---
import React from 'react';

/**
 * Returns "yes" if the input is  true, otherwise returns "no"
 */
export const boolToYesNo = (input: boolean) => (input ? 'Yes' : 'No');

/**
 * Returns a green "Enabled" span if the input is  true, otherwise returns a red "Disabled" span.
 */
export const boolToEnableDisable = (input: boolean) =>
  input ? <span className="toggle-enabled">Enabled</span> : <span className="toggle-disabled">Disabled</span>;

/**
 *Re-implementation of `get_cluster_permissions` function in `AWSDWHammerstoneWebsite/rails-root/app/controllers/manageactivity_controller.rb`
 *
 * @param clusterUsingGroupOutput the raw output of a `GetClusterUsingGroup` API call
 * @returns {object} A dictionary with "READ" and "WRITE" properties, corresponding to a list of clusterNames which have those permissions.
 */
export const getClusterPermissions = (
  clusterUsingGroupOutput: {
    accessType: string;
    clusterName: string;
    [key: string]: string;
  }[],
): { READ: string[]; WRITE: string[] } => {
  const initValue = { READ: new Set<string>(), WRITE: new Set<string>() };
  const permissionsReducer = (output: typeof initValue, cluster: (typeof clusterUsingGroupOutput)[number]) => {
    const { accessType, clusterName } = cluster;
    if (['READ', 'WRITE', 'ADMIN'].includes(accessType)) {
      output.READ.add(clusterName);
    }
    if (['WRITE-ONLY', 'WRITE', 'ADMIN'].includes(accessType)) {
      output.WRITE.add(clusterName);
    }
    return output;
  };
  const permissionSet = clusterUsingGroupOutput.reduce(permissionsReducer, initValue);

  return { READ: Array.from(permissionSet.READ).sort(), WRITE: Array.from(permissionSet.WRITE).sort() };
};
