import { PostPipelineInfoRequest } from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import PAGES from '../../nav/Pages';
import { urlSearchString } from '../../nav/navHelper';
import { HammerstoneAppLayout, tempFlashbarMessage } from '../helpers';
import PipelineContainerLayout from './PipelineContainerLayout';
import { PostPipelineInfo } from 'src/lib/hammerstoneApi';
import { actions, useAlias, useGroupName } from 'src/data/redux';
import { fetchPipelineNames } from 'src/data/api/fetchFromAPI';
import ReactHookFormWrapper from '../helpers/ReactHookFormWrapper';
import Pipeline from 'src/interfaces/pipelineInterfaces';
import { DisplayMode } from '../helpers/content/contentInterfaces';

/** An ID used to de-duplicate flashbar messages for this particular action (e.g. warning, success, loading, error) */
const CREATE_PIPELINE_FLASHBAR_ID = 'create_pipeline';

export default function CreatePipeline() {
  const navigate = useNavigate();

  const form = useForm<Pipeline>({ defaultValues: {}, mode: 'onTouched', reValidateMode: 'onChange' });
  const groupName = useGroupName();
  const alias = useAlias();
  const dispatch = useDispatch();

  const onCancel = () =>
    navigate({
      pathname: PAGES.MANAGE_PIPELINES.path,
      search: urlSearchString({ groupName }),
    });

  const onSuccess = (newPipelineName: string) => {
    // Flash a success message to the user
    tempFlashbarMessage({
      id: CREATE_PIPELINE_FLASHBAR_ID,
      message: { header: `Successfully created pipeline ${newPipelineName}` },
    });
    // Refresh pipelines list after update
    fetchPipelineNames({ groupName }, true);
    // Go to the view page of the newly created pipeline
    navigate({
      pathname: PAGES.VIEW_PIPELINE.path.replace(':pipelineName', newPipelineName),
      search: urlSearchString({ groupName }),
    });
  };

  const onValidSubmit = async (pipeline: Pipeline) => {
    try {
      const input: PostPipelineInfoRequest = {
        groupName,
        body: {
          createdBy: alias,
          pipelineName: pipeline.pipelineName,
          description: pipeline.description,
          // Dummy values as defaults for the CreatePipeline API; these fields currently cannot be retrieved or edited from the current RS or HS API and so are not providing any customer value
          // TODO: Eventually refactor HS API, Repo Service, ... and others to either allow setting these fields or otherwise entirely remove them
          defaultPriority: 4,
          severity: 5,
          login: alias,
          category: '',
          type: '',
          item: '',
        },
      };
      const { pipelineName } = await PostPipelineInfo(input);
      onSuccess(pipelineName);
    } catch (error) {
      console.error('Error encountered while creating a pipeline', error);
      dispatch(
        actions.page.addToFlashbar({
          id: CREATE_PIPELINE_FLASHBAR_ID,
          message: {
            type: 'error',
            header: `Failed to create pipeline ${pipeline.pipelineName}=>`,
            content: error.message,
            dismissible: true,
          },
        }),
      );
    }
  };

  return (
    <HammerstoneAppLayout {...PAGES.CREATE_PIPELINE.pageLayoutProps({ groupName })}>
      <ReactHookFormWrapper
        form={form}
        header={'Create new pipeline'}
        onCancel={onCancel}
        onValidSubmit={onValidSubmit}
      >
        <SpaceBetween size="xl">
          <PipelineContainerLayout mode={DisplayMode.Edit} />
        </SpaceBetween>
      </ReactHookFormWrapper>
    </HammerstoneAppLayout>
  );
}
