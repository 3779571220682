export * from './TextareaContent';
export * from './TextContent';
export * from './SelectContent';
export * from './MultiselectContent';
export * from './ToggleContent';
export * from './NumberContent';
export * from './DatetimeContent';
export * from './CodeContent';
export * from './AttributeEditorContent';

export * as Rules from './Rules';
export * from './contentHelpers';

import { TextareaContent } from './TextareaContent';
import { TextContent } from './TextContent';
import { SelectContent } from './SelectContent';
import { MultiselectContent } from './MultiselectContent';
import { ToggleContent } from './ToggleContent';
import { NumberContent } from './NumberContent';
import { DatetimeContent } from './DatetimeContent';
import { CodeContent } from './CodeContent';
import { AttributeEditorContent } from './AttributeEditorContent';

export const Content = {
  Textarea: TextareaContent,
  Text: TextContent,
  Select: SelectContent,
  Multiselect: MultiselectContent,
  Toggle: ToggleContent,
  Number: NumberContent,
  Datetime: DatetimeContent,
  Code: CodeContent,
  AttributeEditor: AttributeEditorContent,
} as const;
export default Content;
