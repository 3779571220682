export type DateLike = string | number | Date;
export type TimeLike = `${number}:${number}` | `${number}:${number}:${number}` | Date;

/** Recursively defined type for creating indexed Activity Configuration Metadata objects */
export type kodlObjType = { [objKey: string]: { keyObjectsList: kodlObjType; values: { [valueKey: string]: any } } };

export interface KeyObjectDefinition {
  key: string;
  values: {
    key: string;
    value: any;
  }[];
  keyObjectsList: KeyObjectDefinition[];
}

/**
 * Converts a type to the union of its base type and a list of that same type
 * Example:
 *    OneOrList<string> = OneOrList<string[]> = string | string[]
 */
export type OneOrList<T> = T extends [] ? T | T[number] : T | T[];

/**
 * Converts an input that may be a single item T or a list of items T[] to a list of T[]
 * This simplifies the logic for working with values that may or may not be a list
 */
export function ToList<T>(input: OneOrList<T>): T[] {
  return Array.isArray(input) ? input : [input as T];
}
