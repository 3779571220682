import React, { useState } from 'react';
import { Alert, Box, Checkbox, Link } from '@amzn/awsui-components-react';
import { useDispatch } from 'react-redux';
import { usePreferences, actions } from 'src/data/redux';
import { HOW_TO_DISABLE_AD_BLOCKER } from 'src/constants/staticUrls';

const DO_NOT_SHOW_BANNER_AGAIN = 'doNotShowAdBlockBannerAgain';

export function AdblockAlert() {
  const dispatch = useDispatch();
  const { dismissAdblockAlert } = usePreferences('announcementBanners');
  const [shouldIgnore, setShouldIgnore] = useState(false);
  const dismissedPermanently = window.localStorage.getItem(DO_NOT_SHOW_BANNER_AGAIN) === 'true';
  const onDismiss = () => {
    if (shouldIgnore) {
      window.localStorage.setItem(DO_NOT_SHOW_BANNER_AGAIN, 'true');
    }
    dispatch(actions.user.updatePreferences({ key: 'announcementBanners', value: { dismissAdblockAlert: true } }));
  };
  const dismissed = dismissAdblockAlert || dismissedPermanently;
  // https://polaris.a2z.com/patterns/general/opt-in-opt-out/migration-communication/#general-guidelines

  return dismissed ? null : (
    <Alert
      type="info"
      header="Please disable your ad blocker"
      dismissible
      dismissAriaLabel="Dismiss adblock alert message"
      onDismiss={onDismiss}
      data-testid="adblockAlert"
    >
      <Box variant="p">
        We do not serve ads or track personal info, but we do use Cloudwatch to aggregate metrics. Please help us
        improve your experience by <Link href={HOW_TO_DISABLE_AD_BLOCKER}>disabling</Link> Adblock.
      </Box>
      <Checkbox checked={shouldIgnore} onChange={(e) => setShouldIgnore(e.detail.checked)}>
        <i style={{ color: 'darkgrey' }}>Do not show again</i>
      </Checkbox>
    </Alert>
  );
}
