import React, { useMemo } from 'react';
import { get } from 'lodash';
import { Select } from '@amzn/awsui-components-react';
import { objToOptions } from 'src/commons';
import { StatusCode } from 'src/constants/hammerstoneConstants';
import { useSearchParams } from 'react-router-dom';

// API requires status IDs (instead of status code strings): a full mapping can be found in hammerstoneConstants. This subset was selected to reflect the old Hammerstone site's options:
// https://code.amazon.com/packages/AWSDWHammerstoneWebsite/blobs/07e9c5a306acaf0f4e7539e62cf41a2833cf7619/--/rails-root/app/views/monitoractivities/monitoractivities.html.erb#L78-L83
const instanceStatusIdObj = {
  '-1': 'Any',
  '3': StatusCode.RUNNING,
  '4': StatusCode.ENQUEUED,
  '5': StatusCode.FAILURE,
  '6': StatusCode.SUCCESS,
  '22': StatusCode.ONHOLD,
} as const;

/**
 * This component manages the value of { statusId } in the Redux user slice at state.user.preferences.monitorActivity
 */
export function InstanceStatusIdSelect() {
  const [searchParams, setSearchParams] = useSearchParams();
  const statusId = parseInt(searchParams.get('instanceStatusId')) || -1;
  const StatusIdOptions = useMemo(() => objToOptions(instanceStatusIdObj), []);

  return (
    <Select
      options={StatusIdOptions}
      selectedOption={{ label: get(instanceStatusIdObj, statusId), value: statusId.toString() }}
      onChange={({ detail }) => {
        setSearchParams((params) => {
          params.set('instanceStatusId', detail.selectedOption.value);
          return params;
        });
      }}
    />
  );
}

export default InstanceStatusIdSelect;
