// The default English arialabels, used for the Polaris `<CodeEditor>` according to their best practices
// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/API/i18n

import { CodeEditorProps, DateRangePickerProps } from '@amzn/awsui-components-react';

// https://polaris.a2z.com/components/code-editor/?tabId=api
export const codeEditor_i18n: CodeEditorProps['i18nStrings'] = {
  loadingState: 'Loading editor and JSON data',
  errorState: 'There was an error loading the code editor.',
  errorStateRecovery: 'Retry',

  editorGroupAriaLabel: 'Code editor',
  statusBarGroupAriaLabel: 'Status bar',

  cursorPosition: (row: number | string, column: number | string) => `Ln ${row}, Col ${column}`,
  errorsTab: 'Errors',
  warningsTab: 'Warnings',
  preferencesButtonAriaLabel: 'Preferences',

  paneCloseButtonAriaLabel: 'Close',

  preferencesModalHeader: 'Preferences',
  preferencesModalCancel: 'Cancel',
  preferencesModalConfirm: 'Confirm',
  preferencesModalWrapLines: 'Wrap lines',
  preferencesModalTheme: 'Theme',
  preferencesModalLightThemes: 'Light themes',
  preferencesModalDarkThemes: 'Dark themes',
};

// https://polaris.a2z.com/components/date-range-picker/?tabId=api
export const dateRangePicker_i18n: DateRangePickerProps['i18nStrings'] = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range relative to today past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: ({ amount, unit }) => (amount === 1 ? `last ${unit}` : `last ${amount} ${unit}s`),
  formatUnit: (e, t) => (1 === t ? e : `${e}s`),
  dateTimeConstraintText: `For date, use year/month/day. For time, use 24 hr format. Note that all datetimes are in UTC`,
  relativeModeTitle: 'Relative',
  absoluteModeTitle: 'Absolute',
  relativeRangeSelectionHeading: 'Select a range relative to now',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};
