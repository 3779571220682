import { Button, Popover, StatusIndicator } from '@amzn/awsui-components-react';
import React from 'react';
import { copyToClipboard, getNodeText } from '../../commons';
import { DataAttributes, filterDataAttributes } from 'src/commons/dataAttributes';

interface CopyButtonProps extends DataAttributes {
  toCopy: React.ReactNode;
  successText?: string;
  children?: React.ReactNode;
}

/**
 * @param {ReactNode} toCopy The text content to be copied (will parse text from nodes, or can just be string)
 * @param {string} successText Optional: The text to display in a popup upon succesful copying
 * @param {ReactNode} children  Optional: The content displayed within the button
 * @returns A button which copies the given text
 */
export function CopyButton({ toCopy, successText = 'Copied!', children = 'Copy', ...props }: CopyButtonProps) {
  return (
    <Popover
      size="small"
      position="top"
      triggerType="custom"
      dismissButton={false}
      content={<StatusIndicator type="success">{successText}</StatusIndicator>}
    >
      <Button
        iconName="copy"
        {...filterDataAttributes(props)}
        onClick={() => {
          copyToClipboard(getNodeText(toCopy));
        }}
      >
        {children}
      </Button>
    </Popover>
  );
}
