// Hammerstone Wikis
export const HAMMERSTONE_WIKI = 'https://w.amazon.com/bin/view/Hammerstone/';
export const PERMISSIONS_TOOL_WIKI = 'https://w.amazon.com/index.php/Hammerstone/Hammerstone_Permissions_Tool';
export const TIPS_AND_TRICKS_WIKI = 'https://w.amazon.com/bin/view/Hammerstone/TipsAndTricks/';
export const FAQ_WIKI = 'https://w.amazon.com/index.php/Hammerstone/Hammerstone_FAQ';
export const ENCRYPTION_WIKI = 'https://w.amazon.com/index.php/Hammerstone/Hammerstone_FAQ#HDataEncryption';
export const ONBOARDING_WIKI ='https://w.amazon.com/index.php/Hammerstone/Hammerstone_FAQ#HHowcanIgetaccesstoHammerstone3F';
export const WHAT_IS_HAMMERSTONE_WIKI = 'https://w.amazon.com/index.php/Hammerstone/Hammerstone_FAQ#HWhatisHammerstone3F';
export const HOW_TO_USE_HAMMERSTONE_WIKI = 'https://w.amazon.com/index.php/Hammerstone/Hammerstone_FAQ#HHowtouseHammerstone';
export const ADD_ACL_WIKI = 'https://w.amazon.com/index.php/Hammerstone/Hammerstone_FAQ#H22add_acl22isfailingmyEXTRACT2FTRANSFORMjob';
export const REMEDY_ALARM_FOR_PRECONDITIONS_WIKI = 'https://w.amazon.com/index.php/Hammerstone/Hammerstone_FAQ#HIwanttobenotifiedwhenpreconditionsarenotarrivingontime.';
export const REDSHIFT_CLUSTER_SETUP_WIKI = 'https://w.amazon.com/bin/view/Hammerstone/#Independent_Redshift_Cluster_Setup';
export const IAM_ROLE_CHAINING_WIKI = 'https://w.amazon.com/bin/view/Hammerstone/IAMRoleChaining/';
export const CONFIDENCE_FILE_WIKI = 'https://w.amazon.com/bin/view/Hammerstone/Confidence_Files/';
export const DTS_WIKI = 'https://w.amazon.com/bin/view/Hammerstone/DTS/UserGuide';
export const SNS_NOTIFICATION_USER_GUIDE_WIKI = 'https://w.amazon.com/bin/view/Users/alezhou/Hammerstone_SNS_User_Guide/';
export const PRECONDITIONS_WIKI = 'https://w.amazon.com/index.php/AWSDW/Hammerstone/Preconditions';
export const JSON_HELP_WIKI = 'https://w.amazon.com/index.php/Hammerstone_json_help';

// Hammerstone API Wikis
export const HAMMERSTONE_API_USER_GUIDE_WIKI = 'https://w.amazon.com/bin/view/HammerstoneAPIUserGuideTemp/';
export const HAMMERSTONE_API_DOCS_WIKI = 'https://w.amazon.com/bin/view/HammerstoneAPIUserGuideTemp/Documentations%2FAPIs/';

// DW Wikis
export const CUSTOM_PARAMETERS_UNLOAD_WIKI = 'https://w.amazon.com/bin/view/AWSDW/Oracle_to_Redshift_Migration/Hammerstone_Extract_Activity/#Custom_UNLOAD_Parameters';
export const CUSTOM_PARAMETERS_LOAD_WIKI = 'https://w.amazon.com/index.php?title=AWSDW/Oracle_to_Redshift_Migration/Hammerstone_Load_Activity#Custom_COPY_Parameters';
export const EXTRACT_ACTIVITY_WIKI = 'https://w.amazon.com/bin/view/AWSDW/Oracle_to_Redshift_Migration/Hammerstone_Extract_Activity/';
export const TRANSFORM_ACTIVITY_WIKI = 'https://w.amazon.com/bin/view/AWSDW/Oracle_to_Redshift_Migration/Hammerstone_Transform_Activity/';
export const TRANSFORM_AUDIT_WIKI = 'https://w.amazon.com/bin/view/AWSDW/Oracle_to_Redshift_Migration/Hammerstone_Transform_Activity/#HHowdoIuseaTransformActivitytoperformanAuditCheck3F';
export const LOAD_ACTIVITY_WIKI = 'https://w.amazon.com/bin/view/AWSDW/Oracle_to_Redshift_Migration/Hammerstone_Load_Activity/';
export const LOAD_SQL_WIKI = 'https://w.amazon.com/index.php?title=AWSDW/Oracle_to_Redshift_Migration/Using_Pre-SQL_Post-SQL_in_Hammerstone';
export const REDSHIFT_CLUSTER_LIST_WIKI = 'https://w.amazon.com/index.php/AWSDW/Redshift/Access#Connecting_to_the_AWS_Data_Warehouse_Redshift_Clusters';

// Org Wikis
export const DATA_PLATFORM_WIKI = 'https://w.amazon.com/bin/view/AWSDataPlatform';
export const HAMMERSTONE_INTEREST_MAILING_LIST = 'https://email-list.amazon.com/email-list/email-list.mhtml?action=search&name=hammerstone-interest%40amazon.com';
export const DATA_WAREHOUSE_MAILING_LIST = 'https://email-list.amazon.com/email-list/email-list.mhtml?action=search&name=aws-business-metrics-dw-interest%40amazon.com';

// Misc Internal
export const DATA_TOOLS_CUSTOMERS_SLACK = 'https://amzn-aws.slack.com/archives/C01B498MMRQ';
export const DATA_WAREHOUSE_CUSTOMERS_SLACK = 'https://amzn-aws.slack.com/archives/C018P7ZBP4Y';
export const WHEELJACK_V2 = 'https://w.amazon.com/bin/view/Wheeljack/v2';
export const OMNI = 'https://omni.datalake.aws.a2z.com/';
export const EDIT_ACTIVITY_ISSUE_SIM_TEMPLATE = 'https://t.corp.amazon.com/create/templates/2b260874-4f61-4aa2-abdb-e3a3ca5a382f';
export const FEATURE_REQUESTS = 'https://tiny.amazon.com/zsr715wu/hammerstoneopenfeaturerequests';
export const DATA_WAREHOUSE_HELP_DESK = 'https://tiny.amazon.com/3ozp5b2z/tcorpamazcreatemp4d0d';
export const HAMMERSTONE_HELP_DESK = 'https://tiny.amazon.com/l9gku0bl/hammerstonehelpdesk';
export const DATA_TOOLS_OFFICE_HOURS = 'https://w.amazon.com/bin/view/Hammerstone/operations/DataToolsOfficeHour/';
export const DATA_WAREHOUSE_OFFICE_HOURS = 'https://w.amazon.com/bin/view/AWS_datawarehouse_office_hours';
export const HAMMERSTONE_FEATURE_REQUESTS = 'https://tiny.amazon.com/z2fxmkux/hammerstonefeaturerequest';
export const ODIN_TO_IAM_MIGRATION_GUIDE = 'https://w.amazon.com/index.php/Hammerstone_Migration_from_Odin_to_IAM_Role_Guide';

// Permissions Tool Relative Paths: 
export const PT_CLUSTER_MANAGEMENT_PATH = '/cluster-management';
export const PT_REDSHIFT_LDAP_MANAGEMENT_PATH = '/ldap-redshift-management';
export const PT_HS_LDAP_MANAGEMENT_PATH = '/hammerstone-ldap-management';
export const PT_ODIN_REDSHIFT_MANAGEMENT_PATH = '/odin-redshift-management';

// Redshift Docs
export const REDSHIFT_COPY_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/r_COPY.html';
export const REDSHIFT_UNLOAD_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/r_UNLOAD.html';
export const REDSHIFT_WELCOME_DOCS = 'http://docs.aws.amazon.com/redshift/latest/dg/welcome.html';
export const REDSHIFT_SQL_REFERENCE_DOCS = 'http://docs.aws.amazon.com/redshift/latest/dg/cm_chap_SQLCommandRef.html';
export const REDSHIFT_COPY_FORMATS_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-data-format.html';
export const REDSHIFT_COPY_JSON_FORMAT_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-data-format.html#copy-json';
export const REDSHIFT_COPY_CSV_FORMAT_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-data-format.html#copy-csv';
export const REDSHIFT_COPY_DELIMITER_FORMAT_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-data-format.html#copy-delimiter';
export const REDSHIFT_COPY_PARQUET_FORMAT_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-data-format.html#copy-parquet';
export const REDSHIFT_COPY_COLUMNAR_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/copy-usage_notes-copy-from-columnar.html';
export const REDSHIFT_COPY_JSON_USAGE_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/copy-usage_notes-copy-from-json.html#copy-usage-json-options';
export const REDSHIFT_MANIFEST_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/loading-data-files-using-manifest.html';
export const REDSHIFT_MANIFEST_LOAD_EXAMPLE_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/r_COPY_command_examples.html#copy-command-examples-manifest';
export const REDSHIFT_LOADING_ENCRYPTION_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/c_loading-encrypted-files.html';
export const REDSHIFT_UNLOADING_ENCRYPTION_DOCS = 'https://docs.aws.amazon.com/redshift/latest/dg/t_unloading_encrypted_files.html';

// S3 Docs
export const S3_SERVER_SIDE_ENCRYPTION = 'https://docs.aws.amazon.com/AmazonS3/latest/userguide/serv-side-encryption.html';
export const S3_CLIENT_SIDE_ENCRYPTION = 'https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingClientSideEncryption.html';

// Misc External Docs
export const POLARIS_HOME = 'https://polaris.a2z.com/';
export const S3_HOME = 'https://aws.amazon.com/s3/';
export const REDSHIFT_HOME = 'https://aws.amazon.com/redshift/';
export const RAGNAROK = 'https://w.amazon.com/bin/view/Secrets/Ragnarok/';
export const CLOUDWATCH_RUM = 'https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM.html';
export const HOW_TO_DISABLE_AD_BLOCKER = 'https://www.wikihow.com/Disable-AdBlock';

//DJS
export const DEFAULT_DJS_CONSOLE_LINK = 'https://djs.amazon.com';
export const DJS_CONSOLE_LINKS = {
  'us-east-1': 'https://djs.amazon.com',
  'us-iso-east-1': 'https://djs.dca.c2s-border.ic.gov',
  'us-isob-east-1': 'https://djs.lck.aws-border.com',
  'us-west-2': 'https://djs-pdx.amazon.com',
  'cn-northwest-1': 'https://djs.zhy.aws-border.com',
} as const;
