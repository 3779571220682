import { Header, TextContent, Link, SpaceBetween, ColumnLayout, Box, Container } from '@amzn/awsui-components-react';
import React from 'react';
import { LazyImage } from '../helpers';
import * as urls from 'src/constants/staticUrls';

export default function ContactUs() {
  return (
    <div>
      <SpaceBetween size="l">
        <div>
          <Container variant="stacked" header={<Header variant="h2">Teams</Header>}>
            <ColumnLayout columns={2}>
              <Box>
                <Header variant="h2">Data Tools</Header>
                <LazyImage src="./hammerstone-react-logo.png" alt="New Hammerstone Logo Banner" width="80%" />
              </Box>
              <Box>
                <Header variant="h2">Data Warehouse</Header>
                <LazyImage src="./AWSDW_Logo.png" alt="AWS Data Warehouse Logo" width="50%" />
              </Box>
            </ColumnLayout>
          </Container>
          <Container variant="stacked" header={<Header variant="h2">SIM</Header>}>
            <ColumnLayout variant="text-grid" columns={2}>
              <SpaceBetween size="m">
                <Box variant="p">
                  <b>Issues</b>
                  <TextContent>
                    To report bugs, get assistance with your jobs, or seek help for a specific technical problem, please
                    cut a ticket to our{' '}
                    <Link href={urls.HAMMERSTONE_HELP_DESK} external>
                      Help Desk.
                    </Link>
                  </TextContent>
                </Box>
                <Box variant="p">
                  <b>Requests</b>
                  <TextContent>
                    We look forward to improving the Hammerstone experience! If you have a feature request, or other
                    ideas on how Hammerstone could better serve your needs, please cut as a ticket with details using
                    our{' '}
                    <Link href={urls.HAMMERSTONE_FEATURE_REQUESTS} external>
                      Feature Request template.
                    </Link>
                  </TextContent>
                </Box>
              </SpaceBetween>
              <TextContent>
                If you have issues relating to jobs or onboarding to shared AWSDW Redshift clusters, please contact the
                Data Warehouse team via their{' '}
                <Link href={urls.DATA_WAREHOUSE_HELP_DESK} external>
                  Help Desk.
                </Link>
              </TextContent>
            </ColumnLayout>
          </Container>

          <Container variant="stacked" header={<Header variant="h2">Office Hours</Header>}>
            <ColumnLayout variant="text-grid" columns={2}>
              <TextContent>
                AWS Data Tools holds virtual office hours every Tuesday at 2PM Pacific Time (PST/PDT). Please sign-up
                through this{' '}
                <Link href={urls.DATA_TOOLS_OFFICE_HOURS} external>
                  wiki.
                </Link>
              </TextContent>
              <TextContent>
                AWS Data Warehouse holds virtual office hours every Tuesday at 2PM Pacific Time (PST/PDT). Please
                sign-up through this{' '}
                <Link href={urls.DATA_WAREHOUSE_OFFICE_HOURS} external>
                  wiki.
                </Link>
              </TextContent>
            </ColumnLayout>
          </Container>

          <Container variant="stacked" header={<Header variant="h2">Slack</Header>}>
            <ColumnLayout variant="text-grid" columns={2}>
              <TextContent>
                To discuss questions, comments, or concerns with the Data Tools team and other customers, feel free to
                post in the{' '}
                <Link href={urls.DATA_TOOLS_CUSTOMERS_SLACK} external>
                  #aws-data-tools-customers
                </Link>{' '}
                channel.
              </TextContent>
              <TextContent>
                To discuss questions, comments, or concerns with the Data Warehouse team and their customers, please
                post in the{' '}
                <Link href={urls.DATA_WAREHOUSE_CUSTOMERS_SLACK} external>
                  #aws-datawarehouse-customers
                </Link>{' '}
                channel.
              </TextContent>
            </ColumnLayout>
          </Container>
        </div>
      </SpaceBetween>
    </div>
  );
}
