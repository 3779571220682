import React, { Children, useState } from 'react';

import { Header, Box, Link, Container } from '@amzn/awsui-components-react';

import PAGES from '../../nav/Pages';
import { urlSearchString, useInternalOnFollow } from 'src/nav/navHelper';
import { iRecentPage, HS_RECENT_PAGES } from 'src/commons';
import { DataTypes } from 'src/interfaces/reduxInterfaces';

/**
 * A helper function which will generate the appropriately formatted href for each dataType, given its dataIds
 *
 * @param {DataType} type The DataType of the page (e.g. 'pipeline', 'activity', 'instance')
 * @param {object} dataIds The dataIds object corresponding to the given type (e.g. activity -> {activityId, groupName})
 * @returns The complete path to the defined resource
 */
function hrefByDataType(type: iRecentPage['type'], dataIds: iRecentPage['dataIds']): string {
  const { activityId, pipelineName, groupName, instanceId } = dataIds;
  switch (type) {
    case 'activity':
      return PAGES.VIEW_ACTIVITY.path.replace(':activityId', activityId) + urlSearchString({ groupName });
    case 'pipeline':
      return PAGES.VIEW_PIPELINE.path.replace(':pipelineName', pipelineName) + urlSearchString({ groupName });
    case 'instance':
      return (
        PAGES.VIEW_INSTANCE.path.replace(':activityId', activityId).replace(':instanceId', instanceId) +
        urlSearchString({ groupName })
      );
    default:
      return '/';
  }
}

export const RecentPages = () => {
  const onFollow = useInternalOnFollow();
  const [recentPages] = useState(window?.localStorage?.getItem(HS_RECENT_PAGES));

  if (!recentPages) {
    return null;
  }

  return (
    <Container header={<Header variant="h2">Recent pages</Header>}>
      <ul aria-label="Recent Pages" className="custom-list-separator">
        {Children.toArray(
          JSON.parse(recentPages).map(({ title, type, dataIds }: iRecentPage) => {
            return (
              <li>
                <Box variant="span" color="text-body-secondary">
                  {DataTypes[type]}{' '}
                  <Link href={hrefByDataType(type, dataIds)} onFollow={onFollow}>
                    {title}
                  </Link>
                </Box>
              </li>
            );
          }),
        )}
      </ul>
    </Container>
  );
};
