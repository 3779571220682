import React from 'react';
import { Spinner } from '@amzn/awsui-components-react';
const { LazyLoadImage } = require('react-lazy-load-image-component');

export function LazyImage(props: { src: string; alt: string; width?: string | number }) {
  return (
    <LazyLoadImage
      className="lazy-image"
      src={props.src}
      alt={props.alt}
      width={props.width}
      placeholder={
        <div style={{ width: '100%', textAlign: 'center', backgroundColor: 'lightgrey' }}>
          <Spinner size="large" />
        </div>
      }
    />
  );
}
