import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { LabeledContent } from '..';

/**
 * A Loading placeholder for `<LabeledContent>`
 */
export function LabeledContentSkeleton(props: { label?: React.ReactNode; height?: string | number }) {
  return (
    <LabeledContent label={props.label ?? <Skeleton width={150} height="1.2em" />}>
      <Skeleton height={props.height ?? '1.1em'} />{' '}
    </LabeledContent>
  );
}
