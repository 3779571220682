import {
  GetInstanceInfoByActivityIdResponse,
  GetInstanceInfoByInstanceIdResponse,
} from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import { msBetweenDates } from 'src/commons';
import { DjsExecutionStatus } from '../../constants/hammerstoneConstants';
import InstanceFromActivity, { InstanceFromId } from '../../interfaces/instanceInterfaces';

export function instancesByActivityPreprocess(output: GetInstanceInfoByActivityIdResponse): InstanceFromActivity[] {
  const instances = output?.instanceStatusList || [];
  return instances.map((i) => {
    return {
      ...i,
      status: i.instanceStatusCode as keyof typeof DjsExecutionStatus,
      duration: msBetweenDates(i.startDateTime, i.endDateTime),
      retryCount: i.activityInstanceRetryCount,
    } as InstanceFromActivity;
  });
}

export function instanceByIdPreprocess(output: GetInstanceInfoByInstanceIdResponse): InstanceFromId {
  return {
    ...output,
    status: output.instanceStatusCode as keyof typeof DjsExecutionStatus,
    duration: msBetweenDates(output.startDateTime, output.endDateTime),
    retryCount: output.activityInstanceRetryCount,
  } as InstanceFromId;
}
