import React, { useState } from 'react';
import { Alert, Box, Checkbox, Link } from '@amzn/awsui-components-react';
import { getOldHammerstoneEndpoint } from 'src/constants/config';
import { useDispatch } from 'react-redux';
import { useOldHammerstoneHref, usePreferences, actions } from 'src/data/redux';
import { DATA_TOOLS_CUSTOMERS_SLACK } from 'src/constants/staticUrls';

const DO_NOT_SHOW_BANNER_AGAIN = 'doNotShowWelcomeBannerAgain';

export function WelcomeAlert() {
  const dispatch = useDispatch();
  const { dismissOldConsole } = usePreferences('announcementBanners');
  const oldHSHref = useOldHammerstoneHref() ?? '';
  const [shouldIgnore, setShouldIgnore] = useState(false);
  const dismissedPermanently = window.localStorage.getItem(DO_NOT_SHOW_BANNER_AGAIN) === 'true';
  const onDismiss = () => {
    if (shouldIgnore) {
      window.localStorage.setItem(DO_NOT_SHOW_BANNER_AGAIN, 'true');
    }
    dispatch(actions.user.updatePreferences({ key: 'announcementBanners', value: { dismissOldConsole: true } }));
  };
  const dismissed = dismissOldConsole || dismissedPermanently;

  // https://polaris.a2z.com/patterns/general/opt-in-opt-out/migration-communication/#general-guidelines

  return dismissed ? null : (
    <Alert
      type="info"
      header="Introducing the new Hammerstone console experience"
      dismissible
      dismissAriaLabel="Dismiss welcome message"
      onDismiss={onDismiss}
      data-testid="welcomeAlert"
    >
      <Box variant="p">
        We have redesigned the Hammerstone experience to make it easier to use. See our{' '}
        <Link target="_blank" href={'https://quip-amazon.com/yqEhAD2Cb0zN'}>
          launch announcement
        </Link>{' '}
        and{' '}
        <Link target="_blank" href={'https://quip-amazon.com/EV6LAfXwKqaM'}>
          release notes.
        </Link>{' '}
        You can always return to{' '}
        <Link target="_blank" href={getOldHammerstoneEndpoint() + oldHSHref}>
          the old console.
        </Link>{' '}
        Please use the{' '}
        <Link target="_blank" href={DATA_TOOLS_CUSTOMERS_SLACK}>
          #aws-data-tools-customers
        </Link>{' '}
        Slack channel with any questions, comments, or concerns.
      </Box>
      <Checkbox checked={shouldIgnore} onChange={(e) => setShouldIgnore(e.detail.checked)}>
        <i style={{ color: 'darkgrey' }}>Do not show again</i>
      </Checkbox>
    </Alert>
  );
}
