// WARNING: FOR ALL IMPORT STATEMENTS IN FILES THAT ARE U-TESTED, DO NOT USE INDEX FILES. IMPORT DIRECTLY FROM EACH RESPECTIVE FILE (https://quip-amazon.com/bUC1A5ds7Z9M/)
import { GetActivityInfoResponse } from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import { get } from 'lodash';

import {
  LoadFileFormat,
  LoadReplaceOption,
  LoadSourceType,
  TargetDBType,
  TransformParamType,
  TransformSQLType,
} from '../../constants/hammerstoneConstants';
import {
  _DefaultConfigMetadataKeys,
  ExtractConfigMetadataKeys,
  TransformConfigMetadataKeys,
  LoadConfigMetadataKeys,
  ConfigMetadataKeysType,
  ConfigMetadata,
} from '../../interfaces/configMetadataInterfaces';
import { LoadTargetObj, transformParamItem, transformSQLItem } from '../../interfaces/activityInterfaces';
import keys from '../../constants/hammerstoneConstantKeys';
import { keyObjectDefinitionListToObj, keyValuesListToObj } from '../../commons/objectManipulators';

// NOTE: Preprocessing was determined by the types and values defined in the Hammerstone Exposed API Wiki & Model
//https://w.amazon.com/bin/view/HammerstoneAPIUserGuideTemp/Documentations/APIs#HGetPipelineInfoResponseModel
//https://code.amazon.com/packages/AWSHammerstoneExposedRESTfulServiceLambdaModel/blobs/b83d8d7596cc4fd3aab5d7bc7335fa27b7f72002/--/model/operations.xml#L36

/**
 * This helper functions simplifies preprocessing significantly by setting keys to the correct type, or otherwise giving them a default value to avoid `undefined` errors.
 * In addition to setting the correct types for default keys, users may also define additional keys specific to each config metadata type (E vs T vs L).
 * For examples, see the usage below.
 *
 * @param {object} metadata A metadata object which will be updated in-place
 * @param {object} keys An object mapping type-strings to a list of keys with that type. The list of keys will be preprocessed in ADDITION to the default keys of that type.
 * Types: 'string'-->string, 'splitString'-->string[], 'number'-->number, 'boolean'-->boolean
 */
const setDefaultMetadataValues = (metadata: any, keys: ConfigMetadataKeysType) => {
  // STRINGS
  // Combines the default string keys with the user-defined keys['string'] list
  keys.string.forEach((key) => {
    //For each string key, converts to a string (and trims off whitespace), or sets to an empty string if the key does not already exist.
    if (key in metadata) {
      metadata[key] = metadata[key].toString();
      /** Key can't be displayed as "\t"; otherwise, it displays as an actual blank tab instead of the tab character "\t".
       * Also, when a blank tab delimiter is submitted to the backend, the API interprets it as whitespace and stores it
       * as an empty string rather than a tab character.
       *  */
      if (key === 'FIELD_DELIMITER' && metadata[key] === '\t') {
        metadata[key] = '\\t';
      }
    } else {
      metadata[key] = '';
    }
  });

  // SPLIT STRINGS (string-->string[])
  // Combines the default splitString keys with the user-defined keys['splitString'] list
  keys.splitString.forEach((key) => {
    //For each key, trims and filters out empty strings (after trimming whitespace), or sets to an empty array if the key does not already exist.
    if (key in metadata && metadata[key]) {
      //Converts to array if string
      if (typeof metadata[key] === 'string') {
        metadata[key] = (metadata[key] as string)
          .split(',')
          .map((k) => k.trim())
          .filter((k) => k);
      }
    } else {
      metadata[key] = [];
    }
  });

  // NUMBERS
  // Combines the default number keys with the user-defined keys['number'] list
  keys.number.forEach((key) => {
    //For each key, parses the number if it is a string (or returns itself if it is already a number). Otherwise returns 0.
    if (key in metadata) {
      metadata[key] = parseFloat(metadata[key]);
    } else {
      metadata[key] = 0;
    }
  });

  // BOOLEANS
  // Combines the default boolean keys with the user-defined keys['boolean'] list. Defaults to false
  keys.boolean.forEach((key) => {
    if (key in metadata) {
      const val = metadata[key].toString().toUpperCase();
      // For some reason, some of the values are stored as Y/N rather than actual boolean
      // This check captures [true, 'true', 'Y']==> true, [false, 'false', 'N']==> false
      metadata[key] = val === 'TRUE' || val === 'Y';
    } else {
      if (['ADD_ACL', 'DJS_ALARM_ALL_EXECUTIONS_FAILED'].includes(key)) {
        // These newly added fields default to true if not provided
        metadata[key] = true;
      } else {
        metadata[key] = false;
      }
    }
  });

  // Combine IAM Roles for custom value
  metadata['IAM_ROLES'] = ((metadata['IAM_ROLE_ARN'] ?? '') + (metadata['IAM_CHAIN_ROLES'] ?? ''))
    .split(',')
    .map((value: string) => ({ value }));
};

/**
 * Preprocesses the configurationMetadata of an `EXTRACT` activity
 */
function processExtractMetadata(activity: GetActivityInfoResponse): ConfigMetadata<'EXTRACT'> {
  const rawConfigMetadata = activity.configurationMetadata;
  const { keyValuesList } = rawConfigMetadata;
  const metadata = keyValuesListToObj(keyValuesList) as ConfigMetadata<'EXTRACT'>;

  setDefaultMetadataValues(metadata, ExtractConfigMetadataKeys);

  return metadata;
}

function processTransformMetadata(activity: GetActivityInfoResponse): ConfigMetadata<'TRANSFORM'> {
  const rawConfigMetadata = activity.configurationMetadata;
  const { keyValuesList, keyObjectDefinitionList } = rawConfigMetadata;
  const metadata = keyValuesListToObj(keyValuesList) as ConfigMetadata<'TRANSFORM'>;

  // Key-Value List
  setDefaultMetadataValues(metadata, TransformConfigMetadataKeys);

  //Key Object Definition List
  const { TRANSFORM_OPERATION, AUDIT_OPERATION } = keyObjectDefinitionListToObj(keyObjectDefinitionList);

  metadata['TRANSFORM_PARAMETERS'] = [] as transformParamItem[];
  metadata['TRANSFORM_SQLS'] = [] as transformSQLItem[];

  //TRANSFORMATIONS:
  Object.entries(TRANSFORM_OPERATION.keyObjectsList).forEach(([name, { values }]) => {
    //TODO: Assuming unique key names for the time being, should we handle repeated names? (e.g. append "_#")
    if ('VALUE' in values) {
      metadata['TRANSFORM_PARAMETERS'].push({
        name,
        type: values.TYPE as keyof typeof TransformParamType,
        value: values.VALUE,
      });
    } else if ('SQL' in values) {
      metadata['TRANSFORM_SQLS'].push({
        type: values.TYPE as keyof typeof TransformSQLType,
        value: values.SQL,
      });
    }
  });

  //IDENTITY:
  metadata['IDENTITY_SEED_SCHEMA'] = TRANSFORM_OPERATION.values['IDENTITY_SEED_SCHEMA'];
  metadata['IDENTITY_SEED_TABLE'] = TRANSFORM_OPERATION.values['IDENTITY_SEED_TABLE'];
  metadata['IDENTITY_COLUMN'] = TRANSFORM_OPERATION.values['IDENTITY_COLUMN'];
  metadata['PARAMETERS'] = TRANSFORM_OPERATION.values['PARAMETERS'];
  metadata['IDENTITY'] = TRANSFORM_OPERATION.values['IDENTITY'] === 'Y';

  metadata['USE_AUDITOR'] = typeof AUDIT_OPERATION !== 'undefined';
  if (metadata['USE_AUDITOR']) {
    const { RUN_VALIDATION, STORE_AUDIT_RESULT } = AUDIT_OPERATION.keyObjectsList;
    const { FAILURE_CONDITION } = RUN_VALIDATION.keyObjectsList;

    //TODO: Add operator constants
    metadata['AUDIT_FAILURE_CONDITION'] = FAILURE_CONDITION.values.OPERATOR as keyof typeof keys.CompareOperator;
    const failureValues = FAILURE_CONDITION.values.VALUE.split(',').map(parseFloat);
    metadata['AUDIT_FAILURE_VALUE_HI'] = failureValues[failureValues.length - 1];
    metadata['AUDIT_FAILURE_VALUE_LO'] = failureValues.length === 2 ? failureValues[0] : undefined;

    metadata['VALIDATION_QUERY'] = RUN_VALIDATION.values.VALIDATION_QUERY;

    metadata['STORE_AUDIT'] = typeof STORE_AUDIT_RESULT !== 'undefined';

    metadata['STORE_AUDIT_QUERY'] = get(STORE_AUDIT_RESULT, 'values.QUERY');
    metadata['STORE_AUDIT_SCHEMA'] = get(STORE_AUDIT_RESULT, 'values.SCHEMA');
    metadata['STORE_AUDIT_TABLE'] = get(STORE_AUDIT_RESULT, 'values.AUDIT_TABLE');
    metadata['STORE_AUDIT_LOCATION'] = get(STORE_AUDIT_RESULT, 'values.UNLOAD_AUDIT_LOCATION');
    metadata['STORE_AUDIT_UNLOAD'] = get(STORE_AUDIT_RESULT, 'values.UNLOAD_AUDIT_RESULT') === 'Y';
  } else {
    metadata['AUDIT_FAILURE_CONDITION'] = undefined;
    metadata['AUDIT_FAILURE_VALUE_HI'] = undefined;
    metadata['AUDIT_FAILURE_VALUE_LO'] = undefined;
    metadata['VALIDATION_QUERY'] = undefined;
    metadata['STORE_AUDIT_QUERY'] = undefined;
    metadata['STORE_AUDIT_SCHEMA'] = undefined;
    metadata['STORE_AUDIT_TABLE'] = undefined;
    metadata['STORE_AUDIT_LOCATION'] = undefined;
    metadata['STORE_AUDIT_UNLOAD'] = undefined;
  }

  return metadata;
}

function processLoadMetadata(activity: GetActivityInfoResponse): ConfigMetadata<'COPY'> {
  const rawConfigMetadata = activity.configurationMetadata;
  const { keyValuesList, keyObjectDefinitionList } = rawConfigMetadata;
  const metadata = keyValuesListToObj(keyValuesList) as ConfigMetadata<'COPY'>;

  setDefaultMetadataValues(metadata, LoadConfigMetadataKeys);

  metadata.DATA_REPLACE_OPTION = metadata.DATA_REPLACE_OPTION as keyof typeof LoadReplaceOption;
  metadata.SOURCE_TYPE = metadata.SOURCE_TYPE as keyof typeof LoadSourceType;
  metadata.TARGET_DATABASE_TYPE = metadata.TARGET_DATABASE_TYPE as keyof typeof TargetDBType;
  metadata.FILE_FORMAT = metadata.FILE_FORMAT as keyof typeof LoadFileFormat;

  const loadingTargets: LoadTargetObj[] = [];

  loadingTargets.push({
    cluster: activity.clusterName,
    schema: metadata.TARGET_SCHEMA_NAME,
    db: metadata.TARGET_DATABASE_NAME,
    table: metadata.TARGET_TABLE_NAME,
  });

  // Get the first-returned SOURCE_DEFINITION list, typically at index 0 of the keyObjectDefinitionList (but not guaranteed, so filters to be safe)
  const [API_SOURCE_DEFINITION] = (keyObjectDefinitionList ?? []).filter(({ key }) => key === 'SOURCE_DEFINITION');
  // Get all the values under the keyObjectsList (ignore the key and keyObjectsList children)
  const keyObjectsList = API_SOURCE_DEFINITION?.keyObjectsList.map(({ values }) => values);
  // Uses this format to stay in parity with the old RoR website and the helper functions here: https://w.amazon.com/bin/view/Hammerstone_json_help/
  const LOCAL_SOURCE_DEFINITION = { keyObjectsList };
  // Use a formatted stringify to nicely print the JSON object
  metadata.SOURCE_DEFINITION = JSON.stringify(LOCAL_SOURCE_DEFINITION, null, 2);

  // Filter for the MERGE_KEY_FIELD in the KODL
  const [API_MERGE_KEY_FIELD] = (keyObjectDefinitionList ?? []).filter(({ key }) => key === 'MERGE_KEY_FIELD');
  // Map the each definition to its first `value` from each singleton `values` lists
  metadata.MERGE_KEY_FIELD = API_MERGE_KEY_FIELD?.keyObjectsList.map((keyObjects) => keyObjects.values[0]?.value);
  return metadata;
}

export function configMetadataPreprocess(activity: GetActivityInfoResponse): ConfigMetadata {
  switch (activity.activityType) {
    case 'EXTRACT':
      return processExtractMetadata(activity);
    case 'TRANSFORM':
      return processTransformMetadata(activity);
    case 'COPY':
      return processLoadMetadata(activity);
  }
}

export default configMetadataPreprocess;
