import React from 'react';

// To test, edit, or visualize particular svg paths, you can use this useful tool: https://yqnn.github.io/svg-path-editor/
// Cloudscape Icon Component: https://cloudscape.aws.dev/components/icon

// VCR-style || pause symbol, from https://www.svgviewer.dev/s/457850/pause
export const Pause = (
  <svg
    version="1.1"
    id="Uploaded to svgrepo.com"
    xmlns="http://www.w3.org/2000/svg"
    width="800px"
    height="800px"
    viewBox="0 0 32 32"
  >
    <path strokeWidth="2" color="currentColor" d="M4,28h8V4H4V28z" />
    <path strokeWidth="2" color="currentColor" d="M20,4v24h8V4H20z" />
  </svg>
);

// VCR-style |> resume symbol, from https://www.svgviewer.dev/s/13181/resume
export const Resume = (
  <svg width="400px" height="400px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="none" stroke="#000" strokeWidth="2" d="M1,20 L6,20 L6,4 L1,4 L1,20 Z M11,19 L22,12 L11,5 L11,19 Z" />
  </svg>
);

// VCR-style Play with repeat icon in bottom left corner, shoutout to mayomeri@ for designing this!
export const Rerun = (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207 192">
    <path
      fill="currentColor"
      strokeWidth="0"
      d="m19.51,140.05v.02c-.06-.6-1.29-13,4.51-19.4,4.08-4.52,9.05-5.89,16.7-5.89h35.16l-10.73,8.71-.72.58.72.7,10.33,10.01,32.46-27.69-32.8-28.06-9.99,9.68-1.07,1.04,1.07.87,10.74,8.76h-35.16c-12.35,0-21.89,3.79-28.37,11.25-10.41,11.98-8.17,28.8-8.07,29.51l15.24-.1Z"
    />
    <path
      fill="currentColor"
      strokeWidth="0"
      d="m92.56,128.78c.09.8,1.23,13.03-4.5,19.38-4.09,4.52-9.05,5.89-16.7,5.89h-35.16l11.45-9.3-11.05-10.71-32.45,27.69,32.8,28.06,11.06-10.72-11.81-9.63h35.16c12.35,0,21.89-3.78,28.37-11.25,2.86-3.29,4.77-6.95,6.02-10.54,3.32-9.47,2.12-18.45,2.05-18.97l-15.24.1Z"
    />
    <polygon
      fill="currentColor"
      strokeWidth="0"
      points="121.07 142.16 204 93.75 49.75 2.55 49.75 77.33 65.14 77.33 65.14 29.52 173.76 93.75 121.07 124.28 121.07 142.16"
    />
  </svg>
);

// VCR-style |<< Created from the rerun button
export const Rewind = (
  <svg width="600px" height="400px" viewBox="0 0 29 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      stroke="#000"
      strokeWidth="2"
      d="M 1 20 L 6 20 L 6 4 L 1 4 L 1 20 Z M 17 5 L 6 12 L 17 19 L 17 5 Z M 28 5 L 17 12 L 28 19 L 28 5 Z"
    />
  </svg>
);

// VCR-style >, from https://www.svgviewer.dev/s/462430/play
export const Play = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="400px"
    height="400px"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#000000"
    strokeWidth="2"
    strokeLinecap="round"
  >
    <polygon points="5 3 19 12 5 21 5 3" />
  </svg>
);

// VCR-style [], from https://www.svgviewer.dev/s/481480/stop
export const Stop = (
  <svg width="800px" height="800px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 3.5H3.5V11.5H11.5V3.5Z" stroke="#000000" />
  </svg>
);
