import React from 'react';
import { getServicePartition } from 'src/constants/config';
import { actions, useCategories, useItems, useTypes } from 'src/data/redux';
import { useLargeDropdownOptions, useConditionalEffect, valueListToOptions } from 'src/commons';
import { useDispatch } from 'react-redux';
import { GetCategories, GetItems, GetTypes } from 'src/lib/hammerstoneApi';
import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react';
import Content, { Rules } from './content/';
import { DisplayMode, ResourcePath, ResourceType, iContent } from './content/contentInterfaces';
import { useFormContext, useWatch, UseFormSetValue } from 'react-hook-form';

interface iCtiContent<RType extends keyof ResourceType, DisableOnPaths extends ResourcePath<RType>[] = []>
  extends Pick<
    iContent<RType, DisableOnPaths>,
    'disabled' | 'disableOn' | 'mode' | 'resourceType' | 'resourceId' | 'rules'
  > {
  path: {
    category: ResourcePath<RType>;
    type: ResourcePath<RType>;
    item: ResourcePath<RType>;
  };
}

/**  A custom content component which will render an input field for Category, Type, and Item while handling logic for fetching and rendering the correct options. */
export function CtiContent<RType extends keyof ResourceType, DisableOnPaths extends ResourcePath<RType>[] = []>(
  props: iCtiContent<RType, DisableOnPaths>,
) {
  // Tickety API is currently only available in classic partition, update this logic as Tickety becomes available in ADC & China regions
  // "In what AWS regions is Tickety API accessible?" https://w.amazon.com/bin/view/IssueManagement/SIMTicketing/TicketyAPI/FAQ#HService
  const isCtiApiAvailable = getServicePartition() === 'aws';

  if (isCtiApiAvailable) {
    return <CtiSelectContent {...props} />;
  } else {
    return <CtiTextContent {...props} />;
  }
}

/** Render C,T, and I fields as text inputs if the Tickety API is not available in the current partition */
export function CtiTextContent<RType extends keyof ResourceType, DisableOnPaths extends ResourcePath<RType>[] = []>(
  props: iCtiContent<RType, DisableOnPaths>,
) {
  return (
    <SpaceBetween size="m">
      <Content.Text
        {...props}
        path={props.path.category}
        label="Category"
        rules={{ ...props.rules, maxLength: Rules.maxLength(64, 'Category') }}
      />
      <Content.Text
        {...props}
        path={props.path.type}
        label="Type"
        rules={{ ...props.rules, maxLength: Rules.maxLength(64, 'Type') }}
      />
      <Content.Text
        {...props}
        path={props.path.item}
        label="Item"
        rules={{ ...props.rules, maxLength: Rules.maxLength(64, 'Item') }}
      />
    </SpaceBetween>
  );
}

/** Render C,T, and I fields as Select dropdowns if the Tickety API is available and handle the custom logic for fetching C,T,I from API */
export function CtiSelectContent<RType extends keyof ResourceType, DisableOnPaths extends ResourcePath<RType>[] = []>(
  props: iCtiContent<RType, DisableOnPaths>,
) {
  const dispatch = useDispatch();

  let category: string;
  let type: string;
  try {
    // Subscribes to the selected category and type fields
    category = useWatch({ name: props.path.category });
    type = useWatch({ name: props.path.type });
  } catch {
    category = '';
    type = '';
  }

  // Subscribes to categories, types, and items from Redux store's CTI Slice
  const categories = useCategories();
  const types = useTypes(category);
  const items = useItems(category, type);

  useConditionalEffect(() => {
    // If editing, fetch categories if they have not yet been loaded
    if (props.mode === DisplayMode.Edit && !categories?.length) {
      GetCategories({}).then(({ results }) => {
        if (results?.length) {
          dispatch(actions.cti.setCategories({ categories: results }));
        }
      });
    }
  }, [props.mode]);

  useConditionalEffect(() => {
    // If editing, fetch types of this category if they are not yet loaded
    if (props.mode === DisplayMode.Edit && !types?.length) {
      GetTypes({ category }).then(({ results }) => {
        if (results?.length) {
          dispatch(actions.cti.setTypes({ category, types: results }));
        }
      });
    }
  }, [category, props.mode]);

  useConditionalEffect(() => {
    // If editing, fetch items of this category and type if they are not yet loaded
    if (props.mode === DisplayMode.Edit && !items?.length) {
      GetItems({ category, type }).then(({ results }) => {
        if (results?.length) {
          dispatch(actions.cti.setItems({ category, type, items: results }));
        }
      });
    }
  }, [category, type, props.mode]);

  const categoryFilterProps = useLargeDropdownOptions(valueListToOptions(categories));
  const typeFilterProps = useLargeDropdownOptions(valueListToOptions(types));
  const itemFilterProps = useLargeDropdownOptions(valueListToOptions(items));

  let setFieldValue: UseFormSetValue<ResourceType[RType]> = () => {};
  try {
    const { setValue } = useFormContext<ResourceType[RType]>();
    setFieldValue = setValue;
  } catch (error) {
    if (props.mode === DisplayMode.Edit) {
      console.error(
        'Failed to retrieve `react-hook-form` context (`useFormContext()`) in `<Content.Cti>`; make sure component is rendered within a `<FormProvider>`',
      );
    }
  }

  return (
    <SpaceBetween size="m">
      <Content.Select
        {...props}
        {...categoryFilterProps}
        label="Category"
        path={props.path.category}
        onChange={({ detail }) => {
          setFieldValue(props.path.category, detail.selectedOption.value);
          setFieldValue(props.path.type, undefined);
          setFieldValue(props.path.item, undefined);
        }}
      />
      <Content.Select
        {...props}
        {...typeFilterProps}
        label="Type"
        path={props.path.type}
        onChange={({ detail }) => {
          setFieldValue(props.path.type, detail.selectedOption.value);
          setFieldValue(props.path.item, undefined);
        }}
        disabled={props.disabled || !category}
      />
      <Content.Select
        {...props}
        {...itemFilterProps}
        label="Item"
        path={props.path.item}
        // Disable Item field if no category & type is selected
        disabled={!category || !type || props.disabled}
      />
      {props.mode === DisplayMode.Edit && (
        <Box float="right" padding={{ top: 'l' }}>
          <Button
            disabled={!category}
            onClick={() => {
              setFieldValue(props.path.category, undefined);
              setFieldValue(props.path.type, undefined);
              setFieldValue(props.path.item, undefined);
            }}
          >
            Clear CTI
          </Button>
        </Box>
      )}
    </SpaceBetween>
  );
}

export default CtiContent;
