import React from 'react';
import { Header, SpaceBetween, Tabs } from '@amzn/awsui-components-react';
import PAGES from '../../nav/Pages';
import FAQ from './FAQ';
import ContactUs from './ContactUs';
import { HelpProps } from '../../interfaces/helpInterfaces';
import { HammerstoneAppLayout } from '../helpers';
import { useNavigate } from 'react-router';
import { urlSearchString } from 'src/nav/navHelper';
import { useGroupName } from 'src/data/redux';

export default function Help(props: HelpProps) {
  const navigate = useNavigate();
  const groupName = useGroupName();

  return (
    <HammerstoneAppLayout {...PAGES[props.action === 'faq' ? 'HELP' : 'CONTACT_US'].pageLayoutProps()}>
      <SpaceBetween size="xs">
        <Header variant="h1">Help</Header>
        <Tabs
          tabs={[
            {
              label: 'FAQ',
              id: 'faq',
              content: <FAQ />,
            },
            {
              label: 'Contact Us',
              id: 'contact_us',
              content: <ContactUs />,
            },
          ]}
          activeTabId={props.action === 'faq' ? 'faq' : 'contact_us'}
          onChange={({ detail }) => {
            if (detail.activeTabId === 'faq') {
              navigate({ pathname: PAGES.HELP.path, search: urlSearchString({ groupName }) });
            } else {
              navigate({ pathname: PAGES.CONTACT_US.path, search: urlSearchString({ groupName }) });
            }
          }}
        />
      </SpaceBetween>
    </HammerstoneAppLayout>
  );
}
