import React from 'react';
import Home from '../components/home/Home';
import Error404 from '../components/error/Error404';
import Activity from '../components/activity/Activity';
import Help from '../components/faq/Help';
import GetActivity from '../components/activity/GetActivity';
import ManagePipelines from '../components/pipeline/ManagePipelines';
import Pipeline from '../components/pipeline/Pipeline';
import ViewInstance from '../components/instance/ViewInstance';
import { useRoutes } from 'react-router';
import { urlSearchString } from './navHelper';
import {
  ActivityBreadcrumbs,
  HammerstoneAppLayoutProps,
  HomeBreadcrumbs,
  InstanceBreadcrumbs,
  ManagePipelinesBreadcrumbs,
  PipelineBreadcrumbs,
} from 'src/components/helpers';
import { Navigate, RouteObject } from 'react-router-dom';
import { useIsCustomerOnboarded } from 'src/data/redux/selectors';
import { DisplayMode } from 'src/components/helpers/content/contentInterfaces';

/** The output type of the (optional) pageLayoutProps function of a page, which is used as an input to the HammerstoneAppLayout component */
interface iPageLayoutProps extends Partial<Omit<HammerstoneAppLayoutProps, 'children'>> {}

export const PAGES = {
  HOME: {
    path: '/',
    element: <Home />,
    // "Don't include a help panel on a service home page", from Polaris Help System: https://cloudscape.aws.dev/patterns/general/help-system/
    pageLayoutProps: (): iPageLayoutProps => ({ title: 'Home', oldHref: '/', toolsHide: true }),
    isPublic: true,
  },
  VIEW_ACTIVITY: {
    path: '/activity/view/:activityId',
    element: <Activity action={DisplayMode.View} />,
    pageLayoutProps: ({ activityId }: { activityId: string }): iPageLayoutProps => ({
      title: `View Activity ${activityId}`,
      oldHref: `/manageactivity/${activityId}`,
      breadcrumbs: <ActivityBreadcrumbs activityId={activityId} action="View" />,
      groupSelectorDisabled: true,
    }),
  },
  VIEW_ACTIVITY_REPO: {
    path: '/activityrepo/view/:activityId',
    element: <GetActivity action={DisplayMode.View} />,
    pageLayoutProps: ({ activityId }: { activityId: string }): iPageLayoutProps => ({
      title: `View Activity ${activityId}`,
      oldHref: `/manageactivity/${activityId}`,
      breadcrumbs: <ActivityBreadcrumbs activityId={activityId} action="View" />,
      groupSelectorDisabled: true,
    }),
  },
  ACTIVITY_HISTORY: {
    path: '/activity/history/:activityId',
    element: <Activity action="history" />,
    pageLayoutProps: ({ activityId }: { activityId: string }): iPageLayoutProps => ({
      title: `Activity History ${activityId}`,
      oldHref: `/manageactivity/${activityId}/history`,
      breadcrumbs: <ActivityBreadcrumbs activityId={activityId} action="History" />,
      groupSelectorDisabled: true,
    }),
  },
  EDIT_ACTIVITY: {
    path: '/activity/edit/:activityId',
    element: <Activity action={DisplayMode.Edit} />,
    pageLayoutProps: ({ activityId }: { activityId: string }): iPageLayoutProps => ({
      title: `Edit Activity ${activityId}`,
      oldHref: `/manageactivity/edit/${activityId}`,
      breadcrumbs: <ActivityBreadcrumbs activityId={activityId} action="Edit" />,
      groupSelectorDisabled: true,
    }),
  },
  CREATE_ACTIVITY: {
    path: '/activity/create',
    element: <Activity action="create" />,
    pageLayoutProps: ({ groupName, pipelineName }: { groupName: string; pipelineName: string }): iPageLayoutProps => ({
      title: `Create Activity`,
      oldHref: groupName && pipelineName ? `/manageactivity/new${urlSearchString({ pipelineName, groupName })}` : null,
      breadcrumbs: <PipelineBreadcrumbs pipelineName={pipelineName} action="Create Activity" />,
      groupSelectorDisabled: true,
    }),
  },
  COPY_ACTIVITY: {
    path: '/activity/copy/:activityId',
    element: <Activity action="copy" />,
    pageLayoutProps: ({ activityId }: { activityId: string }): iPageLayoutProps => ({
      title: `Copy Activity ${activityId}`,
      oldHref: `/manageactivity/copy/${activityId}`,
      breadcrumbs: <ActivityBreadcrumbs activityId={activityId} action="Copy" />,
      groupSelectorDisabled: true,
    }),
  },
  MONITOR_ACTIVITY: {
    path: '/activity/monitor/:activityId',
    element: <Activity action="monitor" />,
    pageLayoutProps: ({
      activityId,
      groupName,
      pipelineName,
      activityName,
    }: {
      activityId: string;
      groupName: string;
      pipelineName: string;
      activityName: string;
    }): iPageLayoutProps => ({
      title: `Monitor Activity ${activityId}`,
      oldHref: `/monitoractivities${urlSearchString({ groupName, pipelineName, activityName, loadSearch: 'true' })}`,
      breadcrumbs: <ActivityBreadcrumbs activityId={activityId} action="Monitor" />,
      groupSelectorDisabled: true,
    }),
  },
  VIEW_INSTANCE: {
    path: '/activity/monitor/:activityId/:instanceId',
    element: <ViewInstance />,
    pageLayoutProps: ({ instanceId, activityId }: { instanceId: string; activityId: string }): iPageLayoutProps => ({
      title: `View Instance ${instanceId}`,
      oldHref: `/runhistory/${instanceId}${urlSearchString({ activityId })}`,
      breadcrumbs: <InstanceBreadcrumbs {...{ activityId, instanceId }} action="View" />,
      groupSelectorDisabled: true,
    }),
  },
  MANAGE_PIPELINES: {
    path: '/pipeline/view',
    element: <ManagePipelines />,
    pageLayoutProps: ({ groupName }: { groupName: string }): iPageLayoutProps => ({
      title: `Manage Pipelines`,
      oldHref: `/managepipeline/new${urlSearchString({ groupName })}`,
      breadcrumbs: <ManagePipelinesBreadcrumbs action="Manage" />,
      toolsHide: true, //Currently there are no info links on this page
    }),
  },
  VIEW_PIPELINE: {
    path: '/pipeline/view/:pipelineName',
    element: <Pipeline action="view" />,
    pageLayoutProps: ({ pipelineName, groupName }: { pipelineName: string; groupName: string }): iPageLayoutProps => ({
      title: `View Pipeline ${pipelineName}`,
      oldHref: `/managepipeline/show${urlSearchString({ groupName, pipelineName })}`,
      breadcrumbs: <PipelineBreadcrumbs pipelineName={pipelineName} action="View" />,
      groupSelectorDisabled: true,
    }),
  },
  EDIT_PIPELINE: {
    path: '/pipeline/edit/:pipelineName',
    element: <Pipeline action="edit" />,
    pageLayoutProps: ({ pipelineName, groupName }: { pipelineName: string; groupName: string }): iPageLayoutProps => ({
      title: `Edit Pipeline ${pipelineName}`,
      oldHref: `/managepipeline/show${urlSearchString({ groupName, pipelineName })}`,
      breadcrumbs: <PipelineBreadcrumbs pipelineName={pipelineName} action="Edit" />,
      groupSelectorDisabled: true,
      toolsHide: true, //Currently there are no info links on this page
    }),
  },
  CREATE_PIPELINE: {
    path: '/pipeline/create',
    element: <Pipeline action="create" />,
    pageLayoutProps: ({ groupName }: { groupName: string }): iPageLayoutProps => ({
      title: 'Create Pipeline',
      oldHref: `/managepipeline/new${urlSearchString({ groupName })}`,
      breadcrumbs: <ManagePipelinesBreadcrumbs action="Create" />,
    }),
  },
  DASHBOARD: {
    path: '/dashboard',
    element: <div>ETL Dashboard will be displayed here.</div>,
  },
  HELP: {
    path: '/help',
    element: <Help action="faq" />,
    pageLayoutProps: (): iPageLayoutProps => ({
      title: 'FAQ',
      oldHref: '/faq',
      breadcrumbs: <HomeBreadcrumbs action="FAQ" />,
      toolsHide: true,
    }),
    isPublic: true,
  },
  CONTACT_US: {
    path: '/contact_us',
    element: <Help action="contact_us" />,
    pageLayoutProps: (): iPageLayoutProps => ({
      title: 'Contact Us',
      breadcrumbs: <HomeBreadcrumbs action="Contact Us" />,
      toolsHide: true,
    }),
    isPublic: true,
  },
  ERROR_404: {
    path: '*',
    element: <Error404 />,
    isPublic: true,
    pageLayoutProps: (): iPageLayoutProps => ({ title: 'Error 404: Page not found', toolsHide: true }),
  },
} as const;

interface iProtectPage {
  path: string;
  element: React.ReactNode;
  isPublic?: boolean;
}

// Handle redirect for customers that are not authorized.
const protectPage = ({ path, element, isPublic }: iProtectPage, isCustomerOnboarded: boolean): RouteObject => {
  const allowNavigation = isCustomerOnboarded || isPublic;
  return allowNavigation ? { path, element } : { path, element: <Navigate to="/" /> };
};

export const PageRouter = () => {
  const isCustomerOnboarded = useIsCustomerOnboarded();
  return useRoutes([
    { index: true, element: <Home /> },
    ...Object.values(PAGES).map((page) => protectPage(page, isCustomerOnboarded)),
  ]);
};

export default PAGES;
