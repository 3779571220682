import React from 'react';
import { Box, Link, SpaceBetween, Header, HelpPanel, Icon } from '@amzn/awsui-components-react';
import * as urls from 'src/constants/staticUrls';
import { S3CredentialType } from 'src/constants/hammerstoneConstants';
import { getOldPermissionsToolEndpoint } from 'src/constants/config';

/** === POLARIS / CLOUDSCAPE BEST PRACTICES AND WIKIS ===
PLEASE REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS TO THE HELP PANELS BELOW
- Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/
- Help System: https://cloudscape.aws.dev/patterns/general/help-system/
   - Writing Guidelines: https://cloudscape.aws.dev/patterns/general/help-system/#writing-guidelines

MORE READING:
- App Layout Component: https://cloudscape.aws.dev/components/app-layout/
- Help Panel Component: https://cloudscape.aws.dev/components/help-panel/


TLDR;     (=== SUMMARY ===)
- Use sentence case, not Title Case
- Keep sentences short, simple, and declarative
- Links to external sources that are INSIDE the help panel should be marked with `external` and `target="_blank"` to avoid interrupting a user's session
- Help Panels should briefly explain how a field is use, and then provide further reading with reputable links
    - Further reading can be added using the `<ExternalLearnMore>` component, which takes in `links` prop, which is a list of urls and their corresponding text 
- Do not hard-code links, but instead add them to `src/constants/staticUrls.ts`
*/

// TODO: Eventually create way to link each wiki (enable customer sharing, create centralized wiki page)
// TODO: Eventually allow wikis to point to each other and add interlinking

/** Renders a "Learn more" component which links the user to external, reputable sources. This can be rendered in the `<HelpPanel footer>` */
function ExternalLearnMore(props: { links: { href: string; text: string }[] }) {
  return (
    <Box>
      <Header variant="h3">
        Learn more <Icon name="external" />
      </Header>
      <ul>
        {React.Children.toArray(
          (props.links ?? []).map(({ href, text }) => (
            <li>
              {/* These links do not each need an "external" Icon since they are all external, and the icon is included in the header "Learn more" */}
              <Link target="_blank" href={href}>
                {text}
              </Link>
            </li>
          )),
        )}
      </ul>
    </Box>
  );
}

const CustomParametersInstructions = (props: { commandType: 'UNLOAD' | 'COPY' }) => {
  return (
    <Box>
      To define more advanced parameters, you may override the {props.commandType} configurations (found under the
      header "{props.commandType === 'COPY' ? 'Redshift COPY' : 'S3 UNLOAD'} Command Parameters") by defining custom
      parameters in this text area. Click the <Icon name="copy" /> button in the description below to generate a
      template for this activity's custom parameters and copy it to your clipboard.
    </Box>
  );
};

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const CustomParameters_EXTRACT_TRANSFORM = (
  <HelpPanel
    header={<Header variant="h2">Custom parameters</Header>}
    footer={
      <ExternalLearnMore
        links={[
          { href: urls.REDSHIFT_UNLOAD_DOCS, text: 'Redshift UNLOAD command docs' },
          { href: urls.CUSTOM_PARAMETERS_UNLOAD_WIKI, text: 'UNLOAD custom parameters Wiki' },
        ]}
      />
    }
  >
    <SpaceBetween size="m">
      <Box>
        Hammerstone generates the following S3 command from the parameters in this container:{' '}
        <Box variant="code">
          <div className="custom-code-box">
            {/* note that "&#123;" corresponds to "{" and "&#125;" corresponds to "}". */}
            UNLOAD (&#123;extract_sql&#125;) TO &#123;s3_location&#125; CREDENTIALS &#123;s3_access_keys&#125;
            &#123;encrypted&#125; <b>&#123;custom_parameters&#125;</b>
          </div>
        </Box>
      </Box>
      <CustomParametersInstructions commandType="UNLOAD" />
    </SpaceBetween>
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const CustomParameters_LOAD = (
  <HelpPanel
    header={<Header variant="h2">Custom parameters</Header>}
    footer={
      <ExternalLearnMore
        links={[
          { href: urls.REDSHIFT_COPY_DOCS, text: 'Redshift COPY command docs' },
          { href: urls.CUSTOM_PARAMETERS_LOAD_WIKI, text: 'COPY custom parameters wiki' },
        ]}
      />
    }
  >
    <SpaceBetween size="m">
      <Box>
        Hammerstone's WorkerService generates the following Redshift command from the parameters in this container:{' '}
        <Box variant="code">
          <div className="custom-code-box">
            {/* note that "&#123;" corresponds to "{" and "&#125;" corresponds to "}". */}
            COPY &#123;target_table&#125; FROM &#123;s3_location&#125; CREDENTIALS &#123;iam_roles&#125; &#123;manifest
            <b>&#125; &#123;custom_parameters&#125;</b> STATUPDATE ON ;
          </div>
        </Box>
      </Box>
      <CustomParametersInstructions commandType="COPY" />
    </SpaceBetween>
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const Encryption = (
  <HelpPanel
    header={<Header variant="h2">Encryption</Header>}
    footer={
      <ExternalLearnMore
        links={[
          { href: urls.ENCRYPTION_WIKI, text: 'Hammerstone encryption wiki' },
          { href: urls.REDSHIFT_UNLOADING_ENCRYPTION_DOCS, text: 'Redshift UNLOAD encryption wiki' },
          { href: urls.REDSHIFT_LOADING_ENCRYPTION_DOCS, text: 'Redshift COPY/Load encryption wiki' },
        ]}
      />
    }
  >
    {/* TODO: Eventually provide brief summary of encryption here */}
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const IamRoleChaining = (
  <HelpPanel
    header={<Header variant="h2">IAM role chaining</Header>}
    footer={
      <ExternalLearnMore
        links={[
          { href: urls.IAM_ROLE_CHAINING_WIKI, text: 'Hammerstone IAM role chaining wiki' },
          {
            href: urls.PERMISSIONS_TOOL_WIKI,
            text: 'Hammerstone Permissions Tool wiki',
          },
        ]}
      />
    }
  >
    {/* TODO: Eventually provide brief summary of role chaining here */}
    <Box variant="p">
      You may manage the IAM roles corresponding to your LDAP and Hammerstone groups using the Hammerstone Permissions
      Tool's{' '}
      <Link external href={getOldPermissionsToolEndpoint() + urls.PT_HS_LDAP_MANAGEMENT_PATH} target="_blank">
        Hammerstone-LDAP Management page
      </Link>
      .
    </Box>
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const ConfidenceFile = (
  <HelpPanel
    header={<Header variant="h2">Confidence file</Header>}
    footer={
      <ExternalLearnMore links={[{ href: urls.CONFIDENCE_FILE_WIKI, text: 'Hammerstone confidence file wiki' }]} />
    }
  >
    {/* TODO: Eventually provide brief summary of confidence file here */}
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const ActivityType = (
  <HelpPanel
    header={<Header variant="h2">Activity types</Header>}
    footer={
      <ExternalLearnMore
        links={[
          { href: urls.HOW_TO_USE_HAMMERSTONE_WIKI, text: 'How to use Hammerstone wiki' },
          { href: urls.EXTRACT_ACTIVITY_WIKI, text: 'Extract activity wiki' },
          { href: urls.TRANSFORM_ACTIVITY_WIKI, text: 'Transform activity wiki' },
          { href: urls.LOAD_ACTIVITY_WIKI, text: 'Load activity wiki' },
        ]}
      />
    }
  >
    <SpaceBetween size="m">
      <Box>
        <b>Extract</b> - Queries data on Redshift with SQL and outputs the results to S3.
      </Box>
      <Box>
        <b>Transform</b> - Queries data on Redshift with SQL and outputs the results to S3. Supports multiple SQL
        statements, identity key management, and auditing steps.
      </Box>
      <Box>
        <b>Load</b> - Loads data from S3 to Redshift clusters using a COPY job.
      </Box>
      <hr />
    </SpaceBetween>
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const JsonPathFile = (
  <HelpPanel
    header={<Header variant="h2">JSON path file</Header>}
    footer={
      <ExternalLearnMore
        links={[{ href: urls.REDSHIFT_COPY_JSON_USAGE_DOCS, text: 'Redshift COPY JSON usage docs' }]}
      />
    }
  >
    {/* TODO: Eventually provide brief summary of JSON path file here */}
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const EncryptionOdinAndKMS = (
  <HelpPanel
    header={<Header variant="h2">Encryption Odin and KMS</Header>}
    footer={
      <ExternalLearnMore
        links={[
          { href: urls.ENCRYPTION_WIKI, text: 'Hammerstone encryption wiki' },
          {
            href: urls.ODIN_TO_IAM_MIGRATION_GUIDE,
            text: 'Hammerstone Odin to IAM migration guide',
          },
          { href: urls.S3_CLIENT_SIDE_ENCRYPTION, text: 'S3 client-side encryption' },
          { href: urls.S3_SERVER_SIDE_ENCRYPTION, text: 'S3 server-side encryption' },
        ]}
      />
    }
  >
    <SpaceBetween size="m">
      <Box>
        <b>Precondition: </b>Encryption is required for both KMS and Odin.
      </Box>
      <Box>
        <b>With Publisher:</b> You may only use Odin. If no material set is provided, Redshift multi-part files will be
        encrypted with S3 server-side AES-256 encryption with an automatically generated KMS Key; the Publisher file
        sent by email, however, will NOT be encrypted.
      </Box>
      <Box>
        <b>Without Publisher:</b> You may provide your own KMS Key (as long as your pipeline uses IAM as S3
        credentials), Odin material set, or leave both blank to use automated AES-256 encryption.
      </Box>
    </SpaceBetween>
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const AddAcl = (
  <HelpPanel
    header={<Header variant="h2">Add ACL</Header>}
    footer={<ExternalLearnMore links={[{ href: urls.ADD_ACL_WIKI, text: 'Hammerstone add ACL FAQ' }]} />}
  >
    {/* TODO: Eventually provide brief summary of add ACL here */}
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const RemedyAlarmForPreconditions = (
  <HelpPanel
    header={<Header variant="h2">Remedy alarm for preconditions</Header>}
    footer={
      <ExternalLearnMore
        links={[
          {
            href: urls.REMEDY_ALARM_FOR_PRECONDITIONS_WIKI,
            text: 'Hammerstone remedy alarm for preconditions Wiki',
          },
        ]}
      />
    }
  >
    {/* TODO: Eventually provide brief summary of remedy alarm for preconditions here */}
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const S3CredentialTypes = (
  <HelpPanel
    header={<Header variant="h2">S3 credential type</Header>}
    footer={
      <ExternalLearnMore
        links={[
          {
            href: urls.ODIN_TO_IAM_MIGRATION_GUIDE,
            text: 'Hammerstone Odin to IAM migration guide',
          },
          { href: urls.RAGNAROK, text: 'Project Ragnarok (Odin deprecation)' },
        ]}
      />
    }
  >
    <SpaceBetween size="m">
      <Box variant="p">
        Hammerstone currently supports the following credential types: {S3CredentialType.IAM} and{' '}
        {S3CredentialType.ODIN}. All new resources default to an IAM type due to{' '}
        <Link external href={urls.RAGNAROK} target="_blank">
          Project Ragnarok
        </Link>
        . We strongly encourage legacy users to migrate their Odin pipelines to IAM.
      </Box>
    </SpaceBetween>
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const S3OdinMaterialSet = (
  <HelpPanel
    header={<Header variant="h2">S3 Odin material set</Header>}
    footer={
      <ExternalLearnMore
        links={[
          {
            href: urls.ODIN_TO_IAM_MIGRATION_GUIDE,
            text: 'Hammerstone Odin to IAM migration guide',
          },
          { href: urls.RAGNAROK, text: 'Project Ragnarok (Odin deprecation)' },
          {
            href: urls.PERMISSIONS_TOOL_WIKI,
            text: 'Hammerstone Permissions Tool wiki',
          },
        ]}
      />
    }
  >
    <SpaceBetween size="m">
      {/* TODO: Eventually provide brief description of what s3 odin material sets are and how they are used */}
      <Header variant="h3">Odins are deprecated</Header>
      <Box variant="p">
        Odins are being deprecated in{' '}
        <Link external href={urls.RAGNAROK} target="_blank">
          Project Ragnarok
        </Link>{' '}
        . Old pipelines which still use Odins are prohibited from creating new activities. Please migrate this Pipeline
        to use IAM Roles for S3 credentials instead, following the guide below.
      </Box>
      <Box variant="p">
        You may manage the Odin material sets corresponding to your LDAP and Hammerstone groups using the Hammerstone
        Permissions Tool's{' '}
        <Link external href={getOldPermissionsToolEndpoint() + urls.PT_ODIN_REDSHIFT_MANAGEMENT_PATH} target="_blank">
          Odin-Redshift Management page
        </Link>
        .
      </Box>
    </SpaceBetween>
  </HelpPanel>
);

// These elements are pre-defined to simplify the DestinationFilepath component below, note that "&#123;" corresponds to "{" and "&#125;" corresponds to "}".
// Special characters are used because React interprets the curly braces {} as a JS/TS injection. Therefore, "&#123;YYYY&#125;" = "{YYYY}"
const YYYY = <b className="custom-code-box">&#123;YYYY&#125;</b>;
const MM = <b className="custom-code-box">&#123;MM&#125;</b>;
const DD = <b className="custom-code-box">&#123;DD&#125;</b>;
const HH = <b className="custom-code-box">&#123;HH&#125;</b>;

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const DestinationFilepath_EXTRACT_TRANSFORM = (
  <HelpPanel header={<Header variant="h2">Destination S3 filepath</Header>}>
    <SpaceBetween size="m">
      <Box variant="p">
        {/* Example and best practices suggested by alezhou@ */}
        An ideal filepath clearly indicates the activity, pipeline, and schedule.
      </Box>
      <Box variant="p">
        You may include uppercase UTC datetime placeholders for the year {YYYY}, month {MM}, day {DD}, and hour {HH}{' '}
        (0-23).
      </Box>
      <Box variant="p">
        For example, a monthly activity might have the following path:
        <div className="custom-code-box">
          s3://hammerstone-output/this-pipeline/this-monthly-activity/{YYYY}/{MM}/output_
        </div>{' '}
        while an hourly activity might have the following:
        <div className="custom-code-box">
          s3://hammerstone-output/this-pipeline/this-hourly-activity/{YYYY}/{MM}/{DD}/{HH}/output_
        </div>
        . When these files are written, the datetime placeholders will be written as the corresponding numeric values.
      </Box>
    </SpaceBetween>
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const Overwrite = (
  <HelpPanel
    header={<Header variant="h2">Overwrite</Header>}
    footer={<ExternalLearnMore links={[{ href: urls.REDSHIFT_UNLOAD_DOCS, text: 'Redshift UNLOAD command docs' }]} />}
  >
    <Box variant="p">
      By default,{' '}
      <Link external href={urls.REDSHIFT_UNLOAD_DOCS} target="_blank">
        UNLOAD
      </Link>{' '}
      commands in Redshift will not overwrite files in the S3 destination, but will instead fail. To bypass this error
      and overwrite files (inc. manifest files), enable this toggle, or manually include the{' '}
      <span className="custom-code-box">ALLOWOVERWRITE</span> parameter in the custom parameters. You may also avoid
      naming collisions by defining a destination with datetime placeholders (see the "Destination filepath" above).
    </Box>
  </HelpPanel>
);

/** REVIEW THE FOLLOWING BEFORE MAKING SIGNIFICANT CHANGES/ADDITIONS: Writing Guidelines: https://cloudscape.aws.dev/foundation/core-principles/writing-guidelines/ and Help System: https://cloudscape.aws.dev/patterns/general/help-system/ */
export const Manifest_EXTRACT_TRANSFORM = (
  <HelpPanel
    header={<Header variant="h2">Manifest</Header>}
    footer={
      <ExternalLearnMore
        links={[
          { href: urls.REDSHIFT_MANIFEST_DOCS, text: 'Redshift loading manifest docs' },
          { href: urls.REDSHIFT_COPY_DOCS, text: 'Redshift COPY command docs' },
          { href: urls.REDSHIFT_UNLOAD_DOCS, text: 'Redshift UNLOAD command docs' },
        ]}
      />
    }
  >
    <SpaceBetween size="m">
      <Box variant="p">
        A manifest is a JSON-formatted list of the files that an UNLOAD command has <i>written</i> and that a COPY
        command should <i>read</i>.
      </Box>
      <Box variant="p">
        To enable manifest in Extract and Transform jobs, select this toggle or manually add "MANIFEST" to the custom
        parameters. Redshift will write a manifest to the destination bucket, listing all the files created by the
        UNLOAD command.
      </Box>
    </SpaceBetween>
  </HelpPanel>
);

export const ClusterManagement = (
  <HelpPanel
    header={<Header variant="h2">How do I manage my Redshift clusters?</Header>}
    footer={
      <ExternalLearnMore
        links={[
          {
            href: urls.REDSHIFT_CLUSTER_SETUP_WIKI,
            text: 'Cluster setup wiki',
          },
          {
            href: urls.PERMISSIONS_TOOL_WIKI,
            text: 'Hammerstone Permissions Tool wiki',
          },
        ]}
      />
    }
  >
    <Box variant="p">
      You may manage the Redshift clusters corresponding to your LDAP and Hammerstone groups using the Hammerstone
      Permissions Tool's{' '}
      <Link external href={getOldPermissionsToolEndpoint() + urls.PT_REDSHIFT_LDAP_MANAGEMENT_PATH} target="_blank">
        Cluster Management
      </Link>{' '}
      page and
      <Link external href={getOldPermissionsToolEndpoint() + urls.PT_REDSHIFT_LDAP_MANAGEMENT_PATH} target="_blank">
        Redshift-LDAP Management page
      </Link>
      .
    </Box>
  </HelpPanel>
);
