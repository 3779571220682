import { AppLayout, AppLayoutProps, Flashbar, HelpPanel } from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import SidebarNavigation from '../../nav/SidebarNavigation';
import TopbarNavigation from '../../nav/TopbarNavigation';
import { useDispatch } from 'react-redux';
import { actions } from '../../data/redux';
import PageErrorBoundary from '../error/PageErrorBoundary';
import { WelcomeAlert, AdblockAlert, BreadcrumbsFromItems, flashbarItemsToList, HelpPanels } from '.';
import {
  useFlashbarItems,
  useFormFieldErrors,
  useHelpPanel,
  useIsCustomerOnboarded,
  useToolsOpen,
} from 'src/data/redux/selectors';
import { FormErrorFieldsText } from './ReactHookFormWrapperHelpers';
export interface HammerstoneAppLayoutProps extends AppLayoutProps {
  children?: React.ReactNode;
  removeHeader?: boolean;
  title?: string;
  oldHref?: string;
  groupSelectorDisabled?: boolean;
  pageHelpPanel?: React.ReactNode;
}

/**
 * An AppLayout component to be used on each distinct Hammerstone page, with default values which can be overwritten.
 *
 * Introduces ability to embed page content with `children` props instead of `content`
 * Includes a `TopbarNavigation` header which can be removed (`removeHeader`)
 *
 * Example:
 *      <HammerstoneAppLayout>
 *          {...your content goes here}
 *      </HammerstoneAppLayout>
 */
export function HammerstoneAppLayout(props: HammerstoneAppLayoutProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    // Update whether the hammerstone group selector is disabled
    dispatch(actions.page.setGroupSelectorDisabled(props.groupSelectorDisabled));
  }, []);

  useEffect(()=>{
    // Update the hammerstone link:
    dispatch(actions.page.setOldHammerstoneHref(props.oldHref));
  },[props.oldHref])
  

  document.title = props.title ? props.title + ' - Hammerstone' : 'Hammerstone';

  const p = { ...props };

  // Sets default navigationOpen to `false` to avoid overwhelming user. Navigating pages currently resets the navigation to default
  const [navigationOpen, setNavigationOpen] = useState(false);
  const isCustomerOnboarded = useIsCustomerOnboarded();
  // Subscribes to changes in the Redux `state.page.toolsOpen` value
  const isToolsOpen = useToolsOpen();

  p.content = (
    <PageErrorBoundary>
      <WelcomeAlert />
      <AdblockAlert />
      <AppFlashbar />
      {p.content ?? p.children}
    </PageErrorBoundary>
  );
  // Hide the Navigation panel (left) if a customer is not yet onboarded to Hammerstone
  p.navigationHide = !isCustomerOnboarded;

  // Certain pages may specify that there are no Help Panels / ToolDrawer, e.g. Home page
  p.toolsHide = props.toolsHide;
  // Display the Help Panels in the Tools Drawer (right)
  p.tools = <ToolsDrawer />;
  // Opens up the tools drawer if it or an info Link is clicked (`<HelpPanelInfoLink>`)
  p.toolsOpen = isToolsOpen;
  // This is the event which toggles whether the tools drawer is open upon clicking on it
  p.onToolsChange = ({ detail }) => {
    dispatch(actions.page.setToolsOpen(detail.open));
  };

  p.navigation ??= <SidebarNavigation />;
  p.navigationOpen ??= navigationOpen;
  p.onNavigationChange ??= ({ detail }) => setNavigationOpen(detail.open);
  p.contentType ??= 'default';
  p.headerSelector ??= '#myTopbarNavigation';
  p.breadcrumbs = Array.isArray(p.breadcrumbs) ? BreadcrumbsFromItems(p.breadcrumbs) : p.breadcrumbs;

  return (
    <div id="HammerstoneAppLayout">
      {p.removeHeader || <TopbarNavigation />}
      <AppLayout {...(p as AppLayoutProps)} />
    </div>
  );
}

/** A helper component which subscribes to and displays the help panel defined by the redux store's state.page.helpPanel
 * Maintaining a separate functional components for this prevents rendering the page on every info link click */
function ToolsDrawer() {
  // TODO: Eventually refactor to display separate tabs for nested help panels, e.g. Page > Section > Field
  const dispatch = useDispatch();
  useEffect(() => {
    //On each new HammerstoneAppLayout page, clear all tool tips
    return () => {
      dispatch(actions.page.resetTools());
    };
  }, []);

  const helpPanel = useHelpPanel();
  return (
    HelpPanels[helpPanel] ?? <HelpPanel header="No help panel selected">Click an 'info' link to read more</HelpPanel>
  );
}

/** Renders the Cloudscape Flashbar component at the top of the app. This component subscribes to the relevant paths of the Redux store to retrieve the flashbar items and field errors */
function AppFlashbar() {
  const dispatch = useDispatch();
  useEffect(() => {
    // Upon leaving a page, clear the non persisting flashbar items
    return () => {
      dispatch(actions.page.clearNonPersistingFlashbarItems());
    };
  }, []);

  const items = useFlashbarItems();
  const formFieldErrors = useFormFieldErrors();
  const formFieldErrorsItem = useMemo(() => {
    if (formFieldErrors && Object.keys(formFieldErrors).length) {
      return [
        {
          id: 'form_field_errors',
          // Since React Node's / Elements are not serializable, this hook is necessary in order to display the content of the flashbar as a formatted list of invalid messages
          content: <FormErrorFieldsText invertLink />,
          header: 'Invalid form fields',
          type: 'warning' as const,
        },
      ];
    } else {
      // If there are no field errors, do not display any validation flashbar message
      return [];
    }
  }, [formFieldErrors]);

  return <Flashbar items={[...flashbarItemsToList(items), ...formFieldErrorsItem]} />;
}
