import React from 'react';

import { Header, SpaceBetween, ColumnLayout, Box, Grid, Icon, Link, Container } from '@amzn/awsui-components-react';
import { HammerstoneAppLayout, LazyImage, ExternalLinkItem } from '../helpers';

import 'src/assets/styles/landing-page.scss';
import PAGES from '../../nav/Pages';
import { urlSearchString, useInternalOnFollow } from 'src/nav/navHelper';
import { useGroupName } from 'src/data/redux';
import { RecentPages } from './RecentPages';
import { FeatureHighlights } from './FeatureHighlights';
import { useIsCustomerOnboarded } from 'src/data/redux/selectors';
import { PrimaryCTA, NewCustomerCTA } from './HomeCTA';
import * as urls from 'src/constants/staticUrls';

export default function Home() {
  const onFollow = useInternalOnFollow();
  const groupName = useGroupName();
  const isCustomerOnboarded = useIsCustomerOnboarded();

  return (
    <HammerstoneAppLayout disableContentPaddings {...PAGES.HOME.pageLayoutProps()}>
      <Box margin={{ bottom: 'l' }}>
        <div className="custom-home__header">
          <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
            <Grid
              gridDefinition={[
                { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
                { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
              ]}
            >
              <Box fontWeight="light" padding={{ top: 'xs' }}>
                <span className="custom-home__category">AWS Data Platform</span>
              </Box>
              <div className="custom-home__header-title">
                <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                  Hammerstone
                </Box>
                <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                  Redshift data warehouse integration reimagined
                </Box>
                <Box variant="p" fontWeight="light">
                  <span className="custom-home__header-sub-title">
                    Hammerstone is a managed ETL workflow service that automates data aggregation by integrating
                    structured and unstructured data between Redshift data warehouses and S3 buckets.
                  </span>
                </Box>
              </div>
              {isCustomerOnboarded ? <PrimaryCTA /> : <NewCustomerCTA />}
            </Grid>
          </Box>
        </div>

        <Box padding={{ top: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
              { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
            ]}
          >
            <SpaceBetween size="xxl">
              <FeatureHighlights />

              <div id="howItWorks">
                <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                  How it works
                </Box>
                <Container>
                  <div
                    role="img"
                    aria-label="End user configures activity with ETL configurations, which triggers a backend workflow on Redshift"
                  >
                    <div className="awsui-util-hide-in-dark-mode">
                      <LazyImage src="./Hammerstone_user_workflow.png" alt="Hammerstone User Workflow" width="100%" />
                    </div>
                  </div>
                </Container>
              </div>

              <div>
                <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                  Planned expansions
                </Box>
                <Container>
                  <ColumnLayout columns={2} variant="text-grid">
                    <div>
                      <Box variant="h3" padding={{ top: 'n' }}>
                        Console improvements
                      </Box>
                      <Box variant="p">
                        We have received feedback that the prior Hammerstone console was difficult to use. We are
                        actively addressing these concerns by partnering with UX teams to incrementally upgrade our UI
                        to{' '}
                        <Link href={urls.POLARIS_HOME} external>
                          AWS Console standards.
                        </Link>
                      </Box>
                    </div>
                    <div>
                      <Box variant="h3" padding={{ top: 'n' }}>
                        {/* TODO: replace with actual roadmap */}
                        <Link fontSize="heading-m" href="#" external>
                          Feature roadmap (coming soon)
                        </Link>
                      </Box>
                      <Box variant="p">
                        We collect our internal backlog every two weeks and aim to release a yearly feature roadmap that
                        will always be open for feedback. We encourage you to{' '}
                        <Link href={urls.HAMMERSTONE_FEATURE_REQUESTS} external>
                          submit your own feature request!
                        </Link>
                      </Box>
                    </div>
                  </ColumnLayout>
                </Container>
              </div>
              <div>
                <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                  Related services
                </Box>
                <Container>
                  <ColumnLayout columns={2} variant="text-grid">
                    <div>
                      <Box variant="h3" padding={{ top: 'n' }}>
                        <Link fontSize="heading-m" href={urls.S3_HOME} external>
                          Amazon S3
                        </Link>
                      </Box>
                      <Box variant="p">Use Amazon S3 to store unstructured data in buckets.</Box>
                    </div>
                    <div>
                      <Box variant="h3" padding={{ top: 'n' }}>
                        <Link fontSize="heading-m" href={urls.REDSHIFT_HOME} external>
                          Amazon Redshift
                        </Link>
                      </Box>
                      <Box variant="p">Use Amazon Redshift to store structured data in data warehouses.</Box>
                    </div>
                    <div>
                      <Box variant="h3" padding={{ top: 'n' }}>
                        <Link fontSize="heading-m" href={urls.WHEELJACK_V2} external>
                          Wheeljack
                        </Link>
                      </Box>
                      <Box variant="p">
                        Use Wheeljack to integrate your Data Lakes with Amazon Athena, EMR, and S3. With native S3
                        integration, it combines easily with Hammerstone.
                      </Box>
                    </div>
                    <div>
                      <Box variant="h3" padding={{ top: 'n' }}>
                        <Link fontSize="heading-m" href={urls.OMNI} external>
                          Omni
                        </Link>
                      </Box>
                      <Box variant="p">
                        Use Omni to discover and understand your datasets in the AWS Data Lake data catalog.
                      </Box>
                    </div>
                  </ColumnLayout>
                </Container>
              </div>
            </SpaceBetween>
            <div className="custom-home__sidebar">
              <SpaceBetween size="xxl">
                {isCustomerOnboarded && <RecentPages />}
                <Container
                  header={
                    <Header variant="h2">
                      Getting started{' '}
                      <span role="img" aria-label="Icon external Link">
                        <Icon name="external" size="inherit" />
                      </span>
                    </Header>
                  }
                >
                  <ul aria-label="Getting started documentation" className="custom-list-separator">
                    <li>
                      <ExternalLinkItem href={urls.WHAT_IS_HAMMERSTONE_WIKI} text="What is Hammerstone?" />
                    </li>
                    <li>
                      <ExternalLinkItem href={urls.ONBOARDING_WIKI} text="Getting started with Hammerstone" />
                    </li>
                    <li>
                      <ExternalLinkItem
                        href={urls.HOW_TO_USE_HAMMERSTONE_WIKI}
                        text="Working with Hammerstone resources"
                      />
                    </li>
                  </ul>
                </Container>

                <Container
                  header={
                    <Header variant="h2">
                      More resources{' '}
                      <span role="img" aria-label="Icon external Link">
                        <Icon name="external" size="inherit" />
                      </span>
                    </Header>
                  }
                >
                  <ul aria-label="Additional resource links" className="custom-list-separator">
                    <li>
                      <ExternalLinkItem href={urls.HAMMERSTONE_API_DOCS_WIKI} text="API documentation" />
                    </li>
                    <li>
                      <Link href={PAGES.HELP.path + urlSearchString({ groupName })} onFollow={onFollow}>
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <ExternalLinkItem href={urls.DATA_TOOLS_CUSTOMERS_SLACK} text="Slack channel" />
                    </li>
                    <li>
                      <Link onFollow={onFollow} href={PAGES.CONTACT_US.path + urlSearchString({ groupName })}>
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </Container>
              </SpaceBetween>
            </div>
          </Grid>
        </Box>
      </Box>
    </HammerstoneAppLayout>
  );
}
