import React from 'react';
import { Link } from '@amzn/awsui-components-react';

/** A helper function which creates an external link to a useful doc/wiki */
export function ReadMoreHere(props: { href: string }) {
  return (
    <>
      Read more{' '}
      <Link href={props.href} external target="_blank">
        here
      </Link>
    </>
  );
}
