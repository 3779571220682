import {
  GetPipelineInfoRequest,
  GetPipelineInfoResponse,
} from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import keys from '../../constants/hammerstoneConstantKeys';
import Pipeline, { ActivityInfo } from '../../interfaces/pipelineInterfaces';
import store, { actions } from '../redux';

export function pipelinePreprocess(output: GetPipelineInfoResponse, input: GetPipelineInfoRequest) {
  const data = { ...output } as any;

  data['pipelineName'] = input.pipelineName;
  data['status'] = data.pipelineStatusCode as keyof typeof keys.StatusCode;
  data['s3CredentialType'] = output.s3CredentialType as keyof typeof keys.S3CredentialType;

  delete data['pipelineStatusCode'];

  // Using a single object mapping activityId -> {activityName, pipelineName} instead of dispatching multiple setData actions
  const activityInfoCache: any = {};
  data.activityInfoList = data.activityInfoList?.map((activityInfo: GetPipelineInfoResponse['activityInfoList']) => {
    const activity = { ...activityInfo } as any;
    activity.status = activity.activityStatusCode as keyof typeof keys.StatusCode;
    delete activity['activityStatusCode'];
    activity.activityType = activity.activityType as keyof typeof keys.ActivityType;
    activity.activityPriority = activity.activityPriority as keyof typeof keys.ActivityPriority;

    // When there is no previous exeuction and when DJS throttles, the API will return nothing. In that case, we set the values to empty strings and display an 'Undetermined' message, as suggested by a customer (ecaglar@)
    activity.djsLastExecutionStatus = (activity.djsLastExecutionStatus ?? '') as keyof typeof keys.DjsExecutionStatus;
    activity.djsLastExecutionDate = activity.djsLastExecutionDate ?? '';

    activityInfoCache[activity.activityId.toString()] = {
      activityName: activity.activityName,
      pipelineName: input.pipelineName,
    };

    return activity as ActivityInfo;
  });

  store.dispatch(
    actions.data.setData({
      datatype: 'activityInfoCache',
      data: activityInfoCache,
      dataId: undefined,
      // Setting dataKeysAsIds=true deconstructs the `activityInfoCache` object
      dataKeysAsIds: true,
    }),
  );

  return data as Pipeline;
}

export default pipelinePreprocess;
