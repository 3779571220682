import React from 'react';
import { Alert } from '@amzn/awsui-components-react';
import { useParams } from 'react-router';
import ViewPipeline from './ViewPipeline';
import EditPipeline from './EditPipeline';
import CreatePipeline from './CreatePipeline';

type pipelineActions = 'view' | 'edit' | 'create';

/**
 * A routing component which helps render the correct components (and fetch certain data) ONLY IF the user is authorized to do so.
 */
export default function Pipeline(props: { action: pipelineActions }) {
  const { pipelineName } = useParams();

  //TODO: Implement correct clearance checks
  //TODO: Handle improper pipelinename routing
  if (props.action === 'create') {
    return <CreatePipeline />;
  } else if (props.action === 'edit' && pipelineName) {
    return <EditPipeline pipelineName={pipelineName} />;
  } else if (props.action === 'view' && pipelineName) {
    return <ViewPipeline pipelineName={pipelineName} />;
  } else {
    //TODO: Design site-wide error state/component and handling
    return (
      <Alert dismissAriaLabel="Close alert" type="error" header="Permission Denied">
        You are not authorized to access this page.
      </Alert>
    );
  }
}
