import keys from '../constants/hammerstoneConstantKeys';

export interface ActivityInfo {
  activityName: string;
  activityId: number;
  status: keyof typeof keys.StatusCode;
  activityType: keyof typeof keys.ActivityType;
  activityPriority: keyof typeof keys.ActivityPriority;
  updateDate: string;
  djsLastExecutionDate?: string;
  djsLastExecutionStatus?: keyof typeof keys.DjsExecutionStatus;
}

export interface Pipeline {
  pipelineName: string;
  updateDate: string; //Format will be "yyyy-MM-dd HH:mm:ss"
  updatedBy: string;
  description: string;
  status: keyof typeof keys.StatusCode; //Are there enum valeus for this?
  activityInfoList: ActivityInfo[];
  s3CredentialType: keyof typeof keys.S3CredentialType;
}

/** Pipelines names are only unique within their owning Hammerstone group. Since users can belong
 * to multiple Hammerstone groups, it is possible that they have pipelines across their groups which share a name
 * In order to uniquely identify a pipeline we need to combine the groupName and pipelineName to avoid bugs. */
export function pipelineIdentifier<P extends string, G extends string>(id: {
  pipelineName: P;
  groupName: G;
}): `${G}::${P}` {
  return `${id.groupName}::${id.pipelineName}`;
}

export default Pipeline;
