import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGroupName, useInstance, useInstances } from '../../data/redux';
import { Button, ButtonProps } from '@amzn/awsui-components-react';
import { fetchInstanceById } from '../../data/api/fetchFromAPI';
import keys from '../../constants/hammerstoneConstantKeys';
import { PutInstanceStatus } from 'src/lib/hammerstoneApi';
import { CustomSVG, tempFlashbarMessage } from '../helpers';

const { SUCCESS, FAILURE } = keys.DjsExecutionStatus;
const { rerun, pause } = keys.Operation;

export default function RerunExecutionInstanceButton(
  props: {
    instanceId: string;
    activityId: string;
    onSuccess?: (fetchReturn: Awaited<ReturnType<typeof fetchInstanceById>>) => Promise<void> | void;
  } & Omit<ButtonProps, 'onClick'>,
) {
  const activityId: number = parseInt(props.activityId);
  const groupName = useGroupName();
  const [isCalling, setIsCalling] = useState(false);
  const { data: instance, fetching } = useInstance(props.instanceId);
  const { data: instances } = useInstances(props.activityId);
  const instanceFromActivity = instances?.filter((i) => i.instanceId === props.instanceId).pop();
  const status = instance?.instanceStatusCode ?? instanceFromActivity?.status;

  props = { ...props };
  props.onSuccess ??= () => {};
  props.disabled ||= !props.instanceId || !activityId;
  props.loading ||= isCalling || fetching;
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onClick = useCallback(
    (operation: keyof typeof keys.Operation) => async (e: CustomEvent) => {
      e.preventDefault();
      setIsCalling(true);
      try {
        const { instanceId } = await PutInstanceStatus({
          instanceId: props.instanceId,
          activityId,
          groupName,
          body: { operation },
        });
        tempFlashbarMessage({
          id: `instance_` + instanceId,
          message: {
            header: `Successfully ${
              operation === rerun ? 'reran' : operation === pause ? 'stopped' : 'updated'
            } instance ${props.instanceId} as ${instanceId}`,
          },
        });
        const output = await fetchInstanceById({ instanceId, activityId, groupName }, true, false, 5);

        await props.onSuccess(output);
      } catch (error) {}

      if (isMountedRef.current) {
        // Prevent memory leaks if/when the component unmounts upon updating `fetchActivityInfo`
        setIsCalling(false);
      }
    },
    [props.instanceId, activityId, groupName],
  );

  if (status === SUCCESS || status === FAILURE) {
    props.children ??= 'Rerun execution';
    props.iconSvg ??= CustomSVG.Rerun;
    return <Button onClick={onClick(rerun)} {...(props as ButtonProps)} />;
    // TODO: eventually implement "stop execution" if status is RUNNING / ENQUEUED
  } else {
    return <></>;
  }
}
