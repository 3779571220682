import React from 'react';
import { Box, Spinner, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { CopySpan } from './CopySpan';
import { CodeTextBox } from './CodeTextBox';
import { CopyButton } from './CopyButton';
import { getNodeText } from '../../commons';

import '../../assets/styles/highlight-new.css';
import { DataAttributes, filterDataAttributes } from 'src/commons/dataAttributes';

interface LabeledContentProps extends DataAttributes {
  label: React.ReactNode;
  children: string | React.ReactNode;
  display?: boolean;
  loading?: boolean;
  code?: boolean;
  copy?: boolean | string;
  href?: string;
  inline?: boolean;
  missingText?: string;
  width?: number | string;
  info?: React.ReactNode;
}

/**
 * A multipurpose component for displaying labeled text information
 *
 * Example:
 *
 *      // Will link the s3 Filename to its corresponding bucket in the S3 File Console
 *      // Note that clicking the rendered copy icon will paste the filename, NOT the link, to the clipboard
 *      <LabeledContent label="S3 File" copy href={'...'} loading={isLoading}>
 *          {s3File}
 *       </LabeledContent>
 *
 * @param {ReactNode} label The label corresponding to the text
 * @param {string} children The text information
 * @param {boolean} display Whether to display the Labeled Content or instead return an empty component
 * @param {boolean} loading Optional: Whether the text content is loading
 * @param {boolean} code Optional: Whether the text should be displayed as a formatted code block.
 * @param {boolean | string} copy Optional: Whether the text content should be copiable to the clipboard. The text content of the children node will be automatically parsed, but can be overwritten by `copy` if it is a non-empty string
 * @param {string} href Optional: Converts the text to a link pointing to this  external URL
 * @returns
 */
export function LabeledContent({
  label,
  children,
  display = true,
  loading = false,
  copy = false,
  code = false,
  inline = false,
  missingText = 'undefined',
  href,
  width = 'auto',
  info,
  ...props
}: LabeledContentProps) {
  if (!display) {
    return null;
  }

  const childrenText = getNodeText(children);
  const toCopy = typeof copy === 'string' && copy.trim().length > 0 ? copy : childrenText;

  // Define the label content
  const labelBox = (
    <span className="labeled-content-label">
      <Box variant="awsui-key-label" display={inline ? 'inline' : 'block'}>
        {label} {info}
        {inline && ': '}
        {/* If copying code, render a CopyButton instead of a CopySpan */}
        {code && copy && (
          <Box float="right" display="inline">
            <CopyButton toCopy={toCopy} />
          </Box>
        )}
      </Box>
    </span>
  );

  // Define the text content: if there is no child text in any of the components, then display a missing-data message
  let text = !childrenText && missingText ? <span className="missing-data">{missingText}</span> : children;

  if (code) {
    // Content is a code block
    text = <CodeTextBox>{text}</CodeTextBox>;
  } else if (href) {
    // Content is a Link
    text = (
      <Link href={href} target="_blank" external>
        {text}
      </Link>
    );
  }

  //Wraps non-code text in a CopySpan
  if (copy && !code) {
    text = <CopySpan toCopy={toCopy}>{text}</CopySpan>;
  }

  const content = (
    <>
      {labelBox}
      {loading ? <Spinner /> : <span className="labeled-content-content">{text}</span>}
    </>
  );

  if (!display) {
    return null;
  } else if (inline) {
    return (
      <div {...filterDataAttributes(props)} className="labeled-content">
        <SpaceBetween size="s" direction="horizontal">
          {content}
        </SpaceBetween>
      </div>
    );
  } else {
    return (
      <div {...filterDataAttributes(props)} className="labeled-content" style={{ width }}>
        {content}
      </div>
    );
  }
}
