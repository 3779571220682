import { ColumnLayout, Container, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { fetchPipelineNames } from 'src/data/api/fetchFromAPI';
import { useGroupName, usePipelines } from 'src/data/redux';
import { transformTo } from '../helpers';
import { S3CredentialType } from 'src/constants/hammerstoneConstants';
import { useConditionalEffect, objToOptions } from 'src/commons';
import Content, { Rules } from '../helpers/content/';
import { DisplayMode } from '../helpers/content/contentInterfaces';
import { pipelineIdentifier } from 'src/interfaces/pipelineInterfaces';

export default function PipelineContainerLayout(props: { pipelineName?: string; mode: DisplayMode }) {
  const S3CredentialTypeOptions = useMemo(() => objToOptions(S3CredentialType), []);
  const groupName = useGroupName();
  const { data: pipelines } = usePipelines(groupName);
  const pipelineNames = (pipelines ?? []).filter((pipelineName) => pipelineName !== props.pipelineName);

  useConditionalEffect(() => {
    fetchPipelineNames({ groupName });
  }, [groupName]);

  /** Common props for all of the Content components below */
  const contentProps = {
    resourceType: 'pipeline',
    resourceId: pipelineIdentifier({ pipelineName: props.pipelineName, groupName }),
    mode: props.mode,
  } as const;

  return (
    <SpaceBetween size="xl">
      <Container
        header="Pipeline"
        data-testid={`column-container:pipeline`}
        footer={
          props.mode === DisplayMode.View && (
            <ColumnLayout columns={4} variant="text-grid">
              <Content.Text
                {...contentProps}
                label="Status"
                path="status"
                viewTransform={transformTo.statusIndicator}
              />
              <Content.Datetime {...contentProps} label="Last updated" path="updateDate" mode={props.mode} />
              <Content.Text
                {...contentProps}
                label="Updated by"
                path="updatedBy"
                viewTransform={transformTo.userProfile}
              />
              <Content.Select
                {...contentProps}
                label="S3 credential type"
                path="s3CredentialType"
                options={S3CredentialTypeOptions}
                infoHelpPanel="S3CredentialTypes"
              />
            </ColumnLayout>
          )
        }
      >
        <SpaceBetween size="l">
          <ColumnLayout columns={2} variant="text-grid">
            <Content.Text
              {...contentProps}
              label="Name"
              path="pipelineName"
              placeholder={'A unique name for the pipeline...'}
              rules={(l) => ({
                required: Rules.required(l),
                minLength: Rules.minLength(1, l),
                maxLength: Rules.maxLength(128, l),
                pattern: Rules.pattern.lettersNumbersHyphenUnderscore(l),
                validate: {
                  uniqueName: (pipelineName: string) =>
                    !pipelineNames.includes(pipelineName) ||
                    `This pipeline name already exists in hammerstone group "${groupName}", please choose a unique name`,
                },
              })}
            />
            <Content.Textarea
              {...contentProps}
              label="Description"
              path="description"
              placeholder={'A brief description of the pipeline...'}
              rules={(l) => ({
                required: Rules.required(l),
                minLength: Rules.minLength(1, l),
                maxLength: Rules.maxLength(255, l),
              })}
            />
          </ColumnLayout>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
}
