import { Link } from '@amzn/awsui-components-react';
import React, { Component } from 'react';
import ErrorLayout from './ErrorLayout';
import { HAMMERSTONE_HELP_DESK } from 'src/constants/staticUrls';

/**
 * If a descendant component fails, this component will render.
 * Use this ErrorBoundary around Page-level components (it is already included in `<HammerstoneAppLayout>`)
 * If an error occurs within this component itself, the error will propogate up the next error boundary (currently `<AppErrorBoundary>`)
 *
 * You may use Polaris, Redux, and other dependencies within this component, but please keep this page focused on explaining the error to users
 * and redirecting them to the appropriate actions or Pages (i.e. bring them back to safety)
 *
 * For more information: https://reactjs.org/docs/error-boundaries.html
 */
export default class PageErrorBoundary extends Component<{}, { hasError: boolean; error: any }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  // componentDidCatch(error: any, errorInfo: any) {
  // TODO: Log error to services? Report failure?
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorLayout
          header="An unexpected error has occured"
          subheader="The page has failed to load correctly..."
          backtrackButtons
        >
          ...We apologize for the inconvenience. If the problem persists, please cut us a ticket{' '}
          <Link href={HAMMERSTONE_HELP_DESK}>here</Link>. <br />
          In the meantime, you may want to try the following:
        </ErrorLayout>
      );
    } else {
      return this.props.children;
    }
  }
}
