import { addToRecentPages } from '.';
import { useEffect } from 'react';

export function useAddActivityToRecentPages(activityId: string, activityName: string, groupName: string) {
  useEffect(() => {
    if (activityId && activityName && groupName) {
      addToRecentPages({
        type: 'activity',
        title: `${activityName}`,
        dataIds: { activityId, groupName },
      });
    }
  }, [activityId, activityName, groupName]);
}

export function useAddPipelineToRecentPage(pipelineName: string, groupName: string) {
  useEffect(() => {
    if (pipelineName && groupName) {
      addToRecentPages({
        type: 'pipeline',
        title: `${pipelineName}`,
        dataIds: { pipelineName, groupName },
      });
    }
  }, [pipelineName, groupName]);
}

export function useAddInstanceToRecentPages(activityId: string, instanceId: string, groupName: string) {
  useEffect(() => {
    if (activityId && instanceId && groupName) {
      addToRecentPages({
        type: 'instance',
        title: `${instanceId}`,
        dataIds: { activityId, instanceId, groupName },
      });
    }
  }, [activityId, instanceId, groupName]);
}
