import { Box, Header, TextContent, Link, SpaceBetween, Container, ColumnLayout } from '@amzn/awsui-components-react';
import React from 'react';
import { LazyImage } from '../helpers';
import * as urls from 'src/constants/staticUrls';

export default function FAQ() {
  return (
    <div>
      <SpaceBetween size="l">
        <Container variant="stacked" header={<Header variant="h2">Who are we?</Header>}>
          <ColumnLayout variant="text-grid" columns={2}>
            <SpaceBetween size="m">
              <Box variant="p">
                <b>AWS Data Platform</b>
                <TextContent>
                  Hammerstone was created by a software tooling team and a data engineering team in the{' '}
                  <Link href={urls.DATA_PLATFORM_WIKI} external>
                    AWS Data Platform
                  </Link>.{' '}
                  The AWS Data Platform serves as a single source of truth for revenue and usage data for internal business
                  reporting and analytics purposes, providing daily visibility into the state of AWS. Our vision is to empower a
                  data driven culture in AWS, by making it simple for internal users to get access to data sets for decision
                  making, while raising the bar on data security and governance at scale. In addition, we have services that
                  improve productivity of our customers by enabling them to curate and get the most value out of their data
                  without undifferentiated heavy lifting to manage infrastructure, orchestrate ETL pipelines, and secure their
                  datasets.
                </TextContent>
              </Box>
              <Box variant="p">
                <b>Data Tools</b>
                <TextContent>
                  Hammerstone is owned by the AWS Data Tools team, primarily bult for workloads between Redshift and S3. Our
                  customers include analytics teams across AWS and CDO, including sales, finance, marketing, business
                  development. We also own other data engineering services, such as:
                  <ul>
                    <li>
                      <Link href={urls.WHEELJACK_V2} external>
                        Wheeljack
                      </Link>
                      , for workloads between Athena/EMR and S3.
                    </li>
                    <li>
                      <Link href={urls.DTS_WIKI} external>
                        Data Transfer Zone
                      </Link>
                      , for transferring S3 data from China to Commercial AWS regions.
                    </li>
                  </ul>{' '}
                </TextContent>
              </Box>
              <Box>
                <b>AWSDW</b>
                <TextContent>
                  AWS Data Warehouse is our original and largest data provider. It provides AWS with billing, metering,
                  sellers of record (SOR), and other critical business analytics data. They own large shared Redshift
                  clusters, usually prefixed with "AWSDW". The full list of clusters and tables can be found{' '}
                  <Link href={urls.REDSHIFT_CLUSTER_LIST_WIKI} external>
                    here.
                  </Link>
                </TextContent>
              </Box>
            </SpaceBetween>
            <SpaceBetween size="l">
              <Box variant="p">
                <LazyImage src="./AWS-Data-Platform-Light-Mode.png" alt="Data Platform" width="50%" />
              </Box>
              <Box>
                <LazyImage src="./hammerstone-react-logo.png" alt="New Hammerstone Logo Banner" width="80%" />
              </Box>
              <Box>
                <LazyImage src="./AWSDW_Logo.png" alt="AWS Data Warehouse Logo" width="50%" />
              </Box>
            </SpaceBetween>
          </ColumnLayout>
        </Container>

        <Container variant="stacked" header={<Header variant="h2">What is Hammerstone?</Header>}>
          <ColumnLayout variant="text-grid" columns={2}>
            <SpaceBetween size="m">
              <TextContent>
                <ul>
                  <li>
                    <Link href={urls.HAMMERSTONE_WIKI} external>
                      Wiki
                    </Link>
                  </li>
                  <li>
                    <Link href={urls.FAQ_WIKI} external>
                      FAQ / Common Issues
                    </Link>
                  </li>
                  <li>
                    <Link href={urls.TIPS_AND_TRICKS_WIKI} external>
                      Tips & Tricks
                    </Link>
                  </li>
                  <li>
                    <Link href={urls.FEATURE_REQUESTS} external>
                      Open Feature Requests
                    </Link>{' '}
                    - prioritized biweekly during backlog grooming
                  </li>
                  <li>
                    <Link href={urls.PERMISSIONS_TOOL_WIKI} external>
                      Permissions Tool Wiki
                    </Link>{' '}
                    - renamed to Group management and Redshift cluster access in this new UI experience
                  </li>
                </ul>
              </TextContent>
            </SpaceBetween>
          </ColumnLayout>
        </Container>

        <Container variant="stacked" header={<Header variant="h2">How do I access data in Hammerstone?</Header>}>
          <ColumnLayout variant="text-grid" columns={2}>
            <SpaceBetween size="m">
              <TextContent>
                <ul>
                  <li>
                    <Link href={urls.REDSHIFT_CLUSTER_SETUP_WIKI} external>
                      Setting up your own Redshift cluster
                    </Link>
                  </li>
                </ul>
              </TextContent>
            </SpaceBetween>
          </ColumnLayout>
        </Container>

        <Container variant="stacked" header={<Header variant="h2">How do I use Redshift?</Header>}>
          <ColumnLayout variant="text-grid" columns={2}>
            <SpaceBetween size="m">
              <TextContent>
                <ul>
                  <li>
                    <Link href={urls.REDSHIFT_WELCOME_DOCS} external>
                      Overview
                    </Link>
                  </li>
                  <li>
                    <Link href={urls.REDSHIFT_SQL_REFERENCE_DOCS} external>
                      Redshift SQL Reference
                    </Link>
                  </li>
                  <li>
                    <Link href={urls.REDSHIFT_COPY_DOCS} external>
                      Redshift COPY command
                    </Link>{' '}
                    - also known as Load in Hammerstone
                  </li>
                  <li>
                    <Link href={urls.REDSHIFT_UNLOAD_DOCS} external>
                      Redshift UNLOAD command
                    </Link>{' '}
                    - also known as Extract in Hammerstone
                  </li>
                </ul>
              </TextContent>
            </SpaceBetween>
          </ColumnLayout>
        </Container>

        <Container variant="stacked" header={<Header variant="h2">Do you have a mailing list to hear about new features and operational issues?</Header>}>
          <ColumnLayout variant="text-grid" columns={2}>
            <SpaceBetween size="m">
              <TextContent>
                <ul>
                  <li>
                    <Link href={urls.HAMMERSTONE_INTEREST_MAILING_LIST} external>
                      Hammerstone Interest mailing list
                    </Link>{' '}
                    - we won't spam you, we promise!
                  </li>
                  <li>
                    <Link href={urls.DATA_WAREHOUSE_MAILING_LIST} external>
                      Data Warehouse mailing list
                    </Link>{' '}
                    - used by AWS DW to announce data quality issues
                  </li>
                </ul>
              </TextContent>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    </div>
  );
}
