import { Box, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { colorTextStatusInfo } from '@amzn/awsui-design-tokens';
import { dateToUTCString } from 'src/commons/time';
import React from 'react';

// Manually defined regex to parse the logs into a slightly more readable, user-friendly format (feel free to add more)
// good examples in beta/gamma with a diverse group of logs
// https://beta.hammerstone.us-east-1.data-tools.aws.a2z.com/activity/monitor/49830/bdc12de3-928f-4c0c-a01b-3fe7dca9e3eb?groupName=AWS-DW-PRIMARY
// https://beta.hammerstone.us-east-1.data-tools.aws.a2z.com/activity/monitor/46216/73ee9b3a-5b39-4e7a-8b23-c024d99790b6?groupName=Hammerstone-TestGroup
const infoRegex = /^(.+) utc (\w+.\w+) (.+) \[info\] (.+):(\d+) (.*)/;
const warningRegex = /^([^\:]+):(\d+): warning: (.+)/;
const fatalRegex = /^(.+) utc (\w+.\w+) (.+) \[fatal\] (.+):(\d+) (.*)/;
const debugRegex = /^(.+) utc (\w+.\w+) (.+) \[debug\] (.+):(\d+) (.*)/;

// Colors set based on customer request: https://t.corp.amazon.com/V586796833/communication
// Following Polaris light mode convention as much as possible: https://polaris.a2z.com/foundation/visual-foundation/colors/
const originStyle: React.CSSProperties = { color: colorTextStatusInfo };
const typeStyle: React.CSSProperties = { textTransform: 'uppercase', fontWeight: 'bold' };
const warningStyle: React.CSSProperties = { ...typeStyle, color: '#232f3e', backgroundColor: '#ff9900' };
const infoStyle: React.CSSProperties = { ...typeStyle, color: '#232f3e', backgroundColor: '#99cbe4' };
const fatalStyle: React.CSSProperties = { ...typeStyle, color: '#232f3e', backgroundColor: '#ff5d64' };
const fatalTextStyle: React.CSSProperties = { ...typeStyle, color: '#d13212' };
const debugStyle: React.CSSProperties = { ...typeStyle, color: '#fafafa', backgroundColor: '#545b64' };

/**
 * @param {string[]} logsList A list of string logs to be displayed
 * @param {string} filterText A list of search term strings separated by spaces (' ') that determines what logs to show
 * @param {boolean} rawToggle Whether to display the logs as raw strings or to format them according to regex patterns
 * @param {boolean} pathToggle Whether to display the logs with the Apollo host, code file, and line number
 * @param {boolean} timestampToggle Whether to display the logs with timestamps, if they have any (e.g. warning does not)
 */
type FormattedLogsProps = { 
  logsList: string[]; 
  filterText: string;
  rawToggle?: boolean;
  pathToggle?: boolean;
  timestampToggle?: boolean;
};

/**
 * @param props A group of configurable logs to display with certain settings
 * @returns A component listing out the logs in a readable format
 */
export default function FormattedLogs(props: FormattedLogsProps) {
  // parse log filter text, but remove empty strings as they are not used by user
  let searchTerms: string[] = props.filterText.split(' ');
  searchTerms = searchTerms.filter((term) => term !== '');

  // remove log lines that do not include the terms or display all lines if no terms are searched
  const componentList = (props.logsList || []).filter((line) => {
    for (const searchTerm of searchTerms) {
      if (!line.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      };
    };
    return true;
  }).map((line) => {
    return beautifyLogs(props, line);
  });

  return <SpaceBetween size="m">{React.Children.toArray(componentList)}</SpaceBetween>;
}

/**
 * @param props A group of configurable logs to display with certain settings
 * @param line Individual log line to augment
 * @returns Beautiful JSX of one log line
 */
function beautifyLogs(props: FormattedLogsProps, line: string) {
  const infoMatch = line.match(infoRegex);
  const warningMatch = line.match(warningRegex);
  const fatalMatch = line.match(fatalRegex);
  const debugMatch = line.match(debugRegex);

  if (props.rawToggle) {
    //If raw return a basic component containing the text
    return <code style={{ fontSize: '0.9em' }}>{line}</code>;
  }
  
  // Decorate and beautify the logs based on log type
  if (infoMatch) {
    // Make sure to update these named indexes if the Regex Expression above changes!
    const [original, time, networkFile, networkOrigin, fileOrigin, fileLine, message] = infoMatch;

    [original, networkFile]; // Do nothing with these variables

    return (
      <Box variant="code">
        {props.timestampToggle &&
          <>
            <div>{dateToUTCString(new Date(time))}</div>
          </>
        }
        {props.pathToggle &&
          <>
            <div>
              Running on host <span style={originStyle}>{networkOrigin}</span>
            </div>
            <div>
              From <span style={originStyle}>{fileOrigin}</span> at line {fileLine}:
            </div>
          </>
        }
        <div>
          <span style={infoStyle}>
            <Icon name="status-info" variant="normal" size="small" /> INFO
          </span>
          <span>: {message}</span>
        </div>
      </Box>
    );
  } else if (warningMatch) {
    // Make sure to update these named indexes if the Regex Expression above changes!
    const [original, fileOrigin, fileLine, message] = warningMatch;

    [original]; // Do nothing with these variables
    return (
      <Box variant="code">
        {props.pathToggle && 
          <>
            <div>
              From <span style={originStyle}>{fileOrigin}</span> at line {fileLine}:
            </div>
          </>
        } 
        <div>
          <span style={warningStyle}>
            <Icon name="status-warning" variant="warning" size="small" /> WARNING
          </span>
          <span> {message}</span>
        </div>
      </Box>
    );
  } else if (fatalMatch) {
    // Make sure to update these named indexes if the Regex Expression above changes!
    const [original, time, networkFile, networkOrigin, fileOrigin, fileLine, message] = fatalMatch;

    [original, networkFile]; // Do nothing with these variables

    return (
      <Box variant="code">
        {props.timestampToggle &&
          <>
            <div>{dateToUTCString(new Date(time))}</div>
          </>
        }
        {props.pathToggle && 
          <>
            <div>
              Running on host <span style={originStyle}>{networkOrigin}</span>
            </div>
            <div>
              From <span style={originStyle}>{fileOrigin}</span> at line {fileLine}:
            </div>
          </>
        }
        <div>
          <span style={fatalStyle}>
            <Icon name="bug" variant="error" size="small" /> FATAL
          </span>
          <span style={fatalTextStyle}>: {message}</span>
        </div>
      </Box>
    );
  } else if (debugMatch) {
    // Make sure to update these named indexes if the Regex Expression above changes!
    const [original, time, networkFile, networkOrigin, fileOrigin, fileLine, message] = debugMatch;

    [original, networkFile]; // Do nothing with these variables

    return (
      <Box variant="code">
        {props.timestampToggle &&
          <>
            <div>{dateToUTCString(new Date(time))}</div>
          </>
        }
        {props.pathToggle && 
          <>
            <div>
              Running on host <span style={originStyle}>{networkOrigin}</span>
            </div>
            <div>
              From <span style={originStyle}>{fileOrigin}</span> at line {fileLine}:
            </div>
          </>
        }
        <div>
          <span style={debugStyle}>
            <Icon name="caret-right-filled" variant="error" size="small" /> DEBUG
          </span>
          <span>: {message}</span>
        </div>
      </Box>
    );
  } else {
    return <Box variant="code">{line}</Box>;
  }
}