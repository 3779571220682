import React, { useEffect, useState } from 'react';
import { ExpandableSection, FormField, Toggle } from '@amzn/awsui-components-react';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash-es';
import { useEditOnChange, useIsEditContentDisabled, useViewContent } from './contentHelpers';
import { DisplayMode, ResourcePath, ResourceType, iToggleContent } from './contentInterfaces';
import { LabeledContentSkeleton } from '../skeletons/LabeledContentSkeleton';
import { HelpPanelInfoLink, LabeledContent } from '..';
import { boolToEnableDisable, getNodeText } from 'src/commons';
import { DataAttributes, filterDataAttributes, getContentDataAttributes } from 'src/commons/dataAttributes';
import { ContentErrorWrapper } from 'src/components/error/ContentErrorBoundary';
import { Rules } from '.';

/** Renders the field identified by the `path` as a Polaris `<Toggle>` component. Manipulates the value as a `boolean` type
 * For an overview of `<Content>` components, see the [README.md](./README.md). For usage examples, see the [How-to guide](./HowToGuide.md) */
export const ToggleContent = ContentErrorWrapper(_ToggleContent);

function _ToggleContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iToggleContent<RType, DisabledOnPaths>,
) {
  // Processed at top level component to avoid unneccessary executions of a relatively heavy functions between renders
  const dataAttributes = { ...filterDataAttributes(props), ...getContentDataAttributes('Toggle', props) };

  switch (props.mode) {
    case DisplayMode.View:
      return <ViewToggleContent {...props} dataAttributes={dataAttributes} />;
    case DisplayMode.Edit:
      return <EditToggleContent {...props} dataAttributes={dataAttributes} />;
    case DisplayMode.Loading:
      return <LabeledContentSkeleton label={props.label} />;
    default:
      return null;
  }
}

/** A component which will render the provided `toggleContent` component, and wrap it in an [`<ExpandableSection>`](https://cloudscape.aws.dev/components/expandable-section/) if the ToggleContent is expandable.
 * This component then manages the expanded and defaultExpanded states of the ExpandableSection, and stops click propogation from the toggle to prevent frustrating UX. */
function ExpandableWrapper(props: {
  content: React.ReactNode;
  value?: boolean;
  expandable?: boolean;
  children?: React.ReactNode;
}) {
  const [expanded, setExpanded] = useState(false);
  const defaultExpanded = props.value ? true : false;
  useEffect(() => {
    // Expand the footer if the "Retry on failure" toggle is set to true, collapse if set to `false`
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);
  if (props.expandable) {
    return (
      <ExpandableSection
        expanded={expanded}
        defaultExpanded={defaultExpanded}
        onChange={({ detail }) => setExpanded(detail.expanded)}
        headerText={
          // Stop click propogation from toggle to prevent confusing UI behavior where the Expandable content collapses when the toggle is set to `true`
          <span onClick={(e) => e.stopPropagation()}>{props.content}</span>
        }
      >
        {props.children}
      </ExpandableSection>
    );
  } else {
    return <>{props.content}</>;
  }
}

function ViewToggleContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iToggleContent<RType, DisabledOnPaths> & { dataAttributes: DataAttributes },
) {
  const { value, disabled } = useViewContent(props);
  return (
    <LabeledContent
      label={props.label}
      missingText={props.missingText}
      info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
    >
      <ExpandableWrapper
        content={
          <span
            className={disabled ? 'disabled-content' : ''}
            {...props.dataAttributes}
            data-testid={getNodeText(props.label)}
          >
            {/* Default view transform is 'Enabled'/'Disabled' */}
            {props.viewTransform ? props.viewTransform(value) : boolToEnableDisable(value)}
          </span>
        }
        expandable={props.expandable}
        children={props.children}
        value={value ? true : false}
      />
    </LabeledContent>
  );
}

function EditToggleContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iToggleContent<RType, DisabledOnPaths> & { dataAttributes: DataAttributes },
) {
  const { control, clearErrors, setValue } = useFormContext<ResourceType[RType]>();
  const disabled = useIsEditContentDisabled(props);

  return (
    <Controller
      control={control}
      name={props.path}
      rules={Rules.useRules(props.rules, props.label, disabled)}
      render={({ field, formState }) => {
        const onChange = useEditOnChange(props, field.onChange, setValue, 'checked');
        useEffect(() => {
          // This effect will ignore any potential validation errors if the particular field is disabled
          if (disabled) {
            clearErrors(props.path);
          }
        }, [disabled, formState.isSubmitting, formState.isValidating]);

        const value = field.value ? true : false;
        return (
          <FormField
            label={props.label}
            description={props.editDescription}
            errorText={get(formState, `errors.${props.path}.message`)}
            info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
          >
            <ExpandableWrapper
              content={
                <span {...props.dataAttributes}>
                  <Toggle
                    data-testid={getNodeText(props.label)}
                    checked={value}
                    onChange={onChange}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    disabled={disabled}
                    // A toggle cannot be invalid or have a placeholder (default is false)
                  >
                    {/* ToggleContent is an exception: it displays its viewTransform'ed value alongside the toggle switch in the edit mode */}
                    {props.viewTransform ? props.viewTransform(value) : boolToEnableDisable(value)}
                  </Toggle>
                </span>
              }
              expandable={props.expandable}
              children={props.children}
              value={value}
            />
          </FormField>
        );
      }}
    />
  );
}
