import { SelectProps } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useEffect, useState } from 'react';

type StringMatcher = (s: string) => boolean;

/** Returns `true` if any of an `option`'s relevant defined properties match the given function */
export function doesOptionMatch(option: OptionDefinition, matcher: StringMatcher): boolean {
  return (
    (option.value && matcher(option.value)) ||
    (option.label && matcher(option.label)) ||
    (option.description && matcher(option.description)) ||
    (option.labelTag && matcher(option.labelTag)) ||
    (option.tags && option.tags.some(matcher)) ||
    (option.filteringTags && option.filteringTags.some(matcher))
  );
}

/** Manually filters options by a custom `matcher` function */
export function filterOptions(options: SelectProps.Options, matcher: StringMatcher): SelectProps.Options {
  const newOptions = (options ?? []).map((option) => {
    if ('options' in option) {
      // option has `OptionGroup` type
      if (matcher(option.label)) {
        // If the group label matches the function, return the whole group
        return option;
      }
      // Otherwise, filter options with matcher and return matching options in group (if there are any)
      option.options = filterOptions(option.options, matcher);
      return option.options?.length ? option : undefined;
    } else {
      // option has `OptionDefinition` type, return it if it matches
      return doesOptionMatch(option, matcher) ? option : undefined;
    }
  });
  // Only return the option(s) which matched
  return newOptions.filter((option) => option);
}

/**
 * A custom React hook which dynamically returns the props necessary to filter large dropdowns
 *
 * @param allOptions The complete list of options which will be filtered down
 * @param optionsFilteringThreshold - Optional , If there are more options than this, custom manual filtering.
 * If there are fewer, 'auto' filtering is used and all options are initially rendered. Default is 250
 * @param filteringTextContainsThreshold - Optional , If the filtering text is shorter than this (when manually filtering), options will only be matched by "startsWith".
 * If the filtering text is longer, then options will be matched by "contains". Default is 3
 * This results in a faster and less overwhelming UI experience by enforcing a stricter filtering criterion on shorter queries
 * @returns The props necessary to manually filter large lists of options in a (Multi)Select component:
 *    { onLoadItems, options, filteringType, statusType, errorText, finishedText }
 */
export function useLargeDropdownOptions(
  allOptions: SelectProps.Options,
  optionsFilteringThreshold: number = 500,
  filteringTextContainsThreshold: number = 3,
): Required<Pick<SelectProps, 'options' | 'filteringType'>> &
  Pick<SelectProps, 'onLoadItems' | 'statusType' | 'finishedText' | 'loadingText' | 'errorText'> {
  const [filteredOptions, setFilteredOptions] = useState<SelectProps.Options>([]);
  const [filteringText, setFilteringText] = useState('');

  // CUSTOM FILTERING LOGIC DEFINED FOR LARGE DROPDOWNS
  useEffect(() => {
    const prefixMatcher = (o: string) => (o ?? '').toLowerCase().startsWith(filteringText);
    const containsMatcher = (o: string) => (o ?? '').toLowerCase().includes(filteringText);

    if (!filteringText?.length) {
      // If there is not filtering text defined, do not display any options
      setFilteredOptions([]);
    } else if (filteringText.length < filteringTextContainsThreshold) {
      // If the filtering text is too short, match by prefix (avoids rendering too many options by enforcing stricter match criterion)
      setFilteredOptions(filterOptions(allOptions, prefixMatcher));
    } else {
      // If the filtering text is long enough, check for any match
      setFilteredOptions(filterOptions(allOptions, containsMatcher));
    }
  }, [filteringText]);

  if (!allOptions?.length) {
    // If no options have loaded, inform the user
    return { options: [], filteringType: 'none', statusType: 'loading', loadingText: 'Loading options' };
  } else if (allOptions.length < optionsFilteringThreshold) {
    // If the number of options is below the filtering threshold, display all and use `auto` filtering
    return { options: allOptions, filteringType: 'auto' };
  } else {
    // If the number of options excedes the threshold, implement manual filtering logic. Read more here: https://cloudscape.aws.dev/components/select/?tabId=api#static-options

    const onLoadItems: SelectProps['onLoadItems'] = (p) => {
      // Update the filteringText state to the lowercased filter search.
      setFilteringText((p.detail.filteringText ?? '').toLowerCase());
    };
    let statusType: SelectProps['statusType'];
    let finishedText: SelectProps['finishedText'];
    let errorText: SelectProps['errorText'];

    if (!filteringText) {
      // No filter
      statusType = 'finished';
      finishedText = `Begin typing to filter all ${allOptions.length} options`;
    } else if (!filteredOptions.length) {
      // Filter but no match
      statusType = 'error';
      errorText = `None of the ${allOptions.length} options ${
        filteringText.length < filteringTextContainsThreshold ? 'begin with' : 'contain'
      } "${filteringText}"`;
    } else {
      // Filter and match
      statusType = 'finished';
      finishedText = `Showing the ${filteredOptions.length}/${allOptions.length} options ${
        filteringText.length < filteringTextContainsThreshold ? 'beginning with' : 'containing'
      } "${filteringText}"`;
    }

    return { onLoadItems, options: filteredOptions, filteringType: 'manual', statusType, errorText, finishedText };
  }
}

export type OptionsObj = { [key: string]: SelectProps.Option };

/** Converts a list of (Multi)Select options to an object mapping values to options.
 * This is useful for retrieving an option by its value without manually iterating over the list */
export function optionsToObj(options: SelectProps['options']): OptionsObj {
  const valueToOptionsMap: OptionsObj = {};
  const addToMap = (option: SelectProps.Option) => {
    if (!(option.value in valueToOptionsMap)) {
      valueToOptionsMap[option.value] = option;
    }
  };

  (options ?? []).forEach((option) => {
    const isOptionsGroup = 'options' in option;
    if (isOptionsGroup) {
      option.options.forEach(addToMap);
    } else {
      addToMap(option);
    }
  });
  return valueToOptionsMap;
}

/**
 * Converts a key-value pair object to a list of Options for `<Select>` or `<Multiselect>`
 *
 * @param {object} obj An object where the keys are the "value" proprety of an options object and the values are the "label" property
 * @param {object} groups -Optional, A map from group labels to a list of values in that group
 * @returns {SelectProps.Option[]} A list of objects defining the Options to be used in `<Select>` or `<Multiselect>
 */
export function objToOptions(obj: { [value: string]: string }, groups?: { [group: string]: string[] }) {
  // For some reason, webpack is unhappy if you import an arrow function variable at the top level of a file (e.g. export const var= ()=>{...}),
  // so this was changed to an explicit funciton for simplicty
  if (groups) {
    return Object.entries(groups).map(([groupname, grouplist]) => ({
      label: groupname,
      options: grouplist.map((value) => ({ value, label: obj[value] })),
    }));
  } else {
    return Object.entries(obj).map(([value, label]) => ({ value, label }));
  }
}

/**
 * Converts a list of values to a list of options (for `<Select>` and `<Multiselect>` components)
 *
 * @param {string[]} list A list of strings used to set the label and value properties of each options
 * @returns {SelectProps.Option[]} A list of Options
 */
export const valueListToOptions = (list: string[]) => {
  return (list ?? []).map((x) => ({ value: x, label: x }));
};
