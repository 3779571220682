import { Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { fetchPipelineActivitiesAndInstances } from '../../data/api/fetchFromAPI';
import PAGES from '../../nav/Pages';
import PipelineActionsDropdown from './PipelineActionsDropdown';
import PipelineToggleStatusButton from './PipelineToggleStatusButton';
import PipelineContainerLayout from './PipelineContainerLayout';
import PipelineActivitiesTable from './PipelineActivitiesTable';
import { HammerstoneAppLayout } from '../helpers';
import { useGroupName, usePipeline } from 'src/data/redux';
import { useAddPipelineToRecentPage, useConditionalEffect, useTimeoutListRef } from 'src/commons';
import { DisplayMode } from '../helpers/content/contentInterfaces';

export default function ViewPipeline(props: { pipelineName: string }) {
  const { pipelineName } = props;
  const groupName = useGroupName();
  const { data: pipeline, fetching } = usePipeline({ groupName, pipelineName });
  useAddPipelineToRecentPage(pipelineName, groupName);

  const delayRefs = useTimeoutListRef();

  useConditionalEffect(() => {
    fetchPipelineActivitiesAndInstances(pipelineName, groupName, { delays: delayRefs }, false);
  }, [pipelineName, groupName]);

  return (
    <HammerstoneAppLayout {...PAGES.VIEW_PIPELINE.pageLayoutProps({ pipelineName, groupName })}>
      <SpaceBetween size="xl">
        <Header
          variant="h1"
          actions={
            <SpaceBetween size="xs" direction="horizontal">
              {/* TODO: Update iconography to use more friendly values (play/pause, activate?) */}
              <PipelineToggleStatusButton pipelineName={pipelineName} />
              <PipelineActionsDropdown pipelineName={pipelineName} />
            </SpaceBetween>
          }
        >
          {pipelineName}
        </Header>

        <PipelineContainerLayout
          pipelineName={pipelineName}
          mode={fetching || !pipeline ? DisplayMode.Loading : DisplayMode.View}
        />

        <PipelineActivitiesTable pipelineName={pipelineName} />
      </SpaceBetween>
    </HammerstoneAppLayout>
  );
}
