import React from 'react';
import { LOAD_SQL_WIKI } from 'src/constants/staticUrls';
import { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { ColumnContainer, descriptions } from '../../helpers';
import { ColumnLayout, Link } from '@amzn/awsui-components-react';
import Content from 'src/components/helpers/content/';
import { DisplayMode } from 'src/components/helpers/content/contentInterfaces';

/**
 * An editable container for information about an LOAD/COPY-type activity's pre and post SQL
 */
export default function LoadSQLContainer(props: ActivityContainerProps) {
  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;

  return (
    <ColumnContainer
      header="Load SQL"
      actions={
        <Link variant="info" target="_blank" href={LOAD_SQL_WIKI}>
          Info
        </Link>
      }
    >
      {props.mode === DisplayMode.Edit && <descriptions.SQLQuery />}
      <ColumnLayout columns={2}>
        <Content.Code
          label="Pre SQL"
          editDescription={'This field is optional'}
          language="sql"
          missingText="no pre sql defined"
          path="config.PRE_TRANSFORM_SQL"
          {...contentProps}
        />
        <Content.Code
          label="Post SQL"
          editDescription={'This field is optional'}
          language="sql"
          missingText="no post sql defined"
          path="config.POST_TRANSFORM_SQL"
          // optional
          {...contentProps}
        />
      </ColumnLayout>
    </ColumnContainer>
  );
}
