import React from 'react';
import { Popover } from '@amzn/awsui-components-react';
import { useSelector } from 'react-redux';

import { ActivityInfo, pipelineIdentifier } from 'src/interfaces/pipelineInterfaces';
import Activity from 'src/interfaces/activityInterfaces';
import { ReduxState } from 'src/interfaces/reduxInterfaces';
import { CustomStatusIndicator, ToggleableDatetime, popoverProps } from '../helpers';
import keys from 'src/constants/hammerstoneConstantKeys';

export interface LastExecution {
  lastExecutionDate?: string;
  lastExecutionStatus?: string;
}
export interface ActivityInfoWithStatus extends ActivityInfo, LastExecution {
  activity?: Activity;
}
export interface ActivityWithStatus extends Activity, LastExecution {}

const { RUNNING, SUCCESS, ErrorCancellation, NEW, DEACTIVATED } = keys.DjsExecutionStatus;

/**
 * Returns the last execution details for a particular activity
 *
 * @param {ReduxState} state The redux store's current state
 * @param {string} activityId The activity ID for which to retrieve the last execution
 * @returns The activity's last execution status and date
 */
function getLastExecutionFromState(state: ReduxState, activityId: string): LastExecution {
  // Get the resource data from the state
  const activity = state.data.activity.data[activityId];
  const instance = state.data.instance.data[activity?.activityInstanceId];

  // Get whether the resources are currently fetching
  const activityFetching = state.data.activity.fetching[activityId];
  const instanceFetching = state.data.instance.fetching[activity?.activityInstanceId];

  // Unpack the relevant statuses
  const djsDate = activity?.djsLastExecutionDate;
  const djsStatus = activity?.djsLastExecutionStatus;
  const instanceStatus = instance?.instanceStatusCode;
  const activityStatus = activity?.activityStatusCode;

  let lastExecutionDate: string;
  let lastExecutionStatus: string;

  const useLoading: boolean = activityFetching || (activity?.activityInstanceId && instanceFetching);
  const useActivityStatus: boolean = activityStatus === NEW || activityStatus === DEACTIVATED;
  const useInstanceDjsStatuses: (keyof typeof keys.DjsExecutionStatus)[] = [RUNNING, SUCCESS, ErrorCancellation];
  const useInstanceStatus: boolean = instanceStatus && useInstanceDjsStatuses.includes(djsStatus);

  if (useLoading) {
    // If either resource is still fetching, show a loading status until the APIs return
    lastExecutionStatus = 'Loading';
    lastExecutionDate = djsDate;
  } else if (useActivityStatus) {
    // Last execution is not relevant because activity is either new (not run) or deactivated (no longer running)
    lastExecutionStatus = activityStatus;
    lastExecutionDate = null;
  } else if (useInstanceStatus) {
    // The condition above is to maintain parity with the Ruby-on-Rails implementation, the logic can be found distributed across the code snippets below
    // https://code.amazon.com/packages/AWSDWHammerstoneWebsite/blobs/6a73ed521ebcb835da97ffb1b4cd6a7ccea3b662/--/rails-root/app/controllers/manageactivity_controller.rb#L202,L211,L212,L295,L296,L306,L336,L337
    lastExecutionStatus = instanceStatus;
    lastExecutionDate = djsDate;
  } else {
    // Default behavior
    lastExecutionStatus = djsStatus;
    lastExecutionDate = djsDate;
  }

  return { lastExecutionDate, lastExecutionStatus };
}

/** A selector which returns an activity with its last execution status & date */
export const useActivityWithStatus = (activityId: string): ActivityWithStatus =>
  useSelector((state: ReduxState) => {
    const activity = state.data.activity.data[activityId];

    // Return activity and its last execution status & date
    return { ...activity, ...getLastExecutionFromState(state, activityId) };
  });

/** A selector which returns a pipeline's activityInfoList along with each activity's last execution status & date */
export const useActivitiesWithStatus = (...args: Parameters<typeof pipelineIdentifier>): ActivityInfoWithStatus[] =>
  useSelector((state: ReduxState) => {
    const pipeline = state.data.pipeline.data[pipelineIdentifier(...args)];

    return (pipeline?.activityInfoList ?? []).map((activityInfo) => {
      return {
        ...activityInfo,
        ...getLastExecutionFromState(state, activityInfo.activityId.toString()),
        activity: state.data.activity.data[activityInfo.activityId],
      };
    });
  });

/** A selector which returns whether ANY of a pipeline's activities or their last instance are fetching */
export const useIsFetchingActivitiesWithStatus = (...args: Parameters<typeof pipelineIdentifier>): boolean =>
  useSelector((state: ReduxState) => {
    const pipeline = state.data.pipeline.data[pipelineIdentifier(...args)];

    return (pipeline?.activityInfoList ?? []).some((activityInfo) => {
      const activityId = activityInfo.activityId.toString();
      const instanceId = state.data.activity.data[activityId]?.activityInstanceId;
      // Returns true if any of the activities (or their last instance) are fetching
      return state.data.activity.fetching[activityId] || (instanceId && state.data.instance.fetching[instanceId]);
    });
  });

/** A component which will display an activity's last executions status, with a helpful popover message in case it's missing */
export function LastExecutionStatus(props: { lastExecutionStatus?: string }) {
  return props.lastExecutionStatus ? (
    <CustomStatusIndicator>{props.lastExecutionStatus}</CustomStatusIndicator>
  ) : (
    <Popover {...popoverProps.undeterminedDjsLastExecutionStatus}>
      <CustomStatusIndicator />
    </Popover>
  );
}

/** A component which will display an activity's last executions date, with a helpful popover message in case it's missing or not relevant */
export function LastExecutionDate(props: { lastExecutionDate?: string; activityStatusCode?: string }) {
  return props.activityStatusCode === 'NEW' ? (
    <span>No execution yet</span>
  ) : props.lastExecutionDate ? (
    <ToggleableDatetime>{props.lastExecutionDate}</ToggleableDatetime>
  ) : (
    <Popover {...popoverProps.undeterminedDjsLastExecutionDate}>
      <span>undetermined date</span>
    </Popover>
  );
}
