import React from 'react';
import { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { ColumnContainer, descriptions } from '../../helpers';
import Content, { Rules } from 'src/components/helpers/content/';

/**
 * An editable container for an EXTRACT activity's query
 */
export default function ExtractSQLContainer(props: ActivityContainerProps) {
  const contentProps = {
    mode: props.mode,
    resourceType: 'activity',
    resourceId: props.activityId,
  } as const;

  return (
    <ColumnContainer header={props.header}>
      <Content.Code
        {...contentProps}
        language="sql"
        path="config.SELECT_QUERY"
        label="Query"
        rules={(l) => ({ required: Rules.required(l) })}
        editDescription={<descriptions.SQLQuery />}
      />
    </ColumnContainer>
  );
}
