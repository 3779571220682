import React, { useMemo } from 'react';
import { objToOptions } from 'src/commons';
import { ColumnContainer, Column, descriptions } from '../../helpers';
import {
  Delimiters,
  LoadFileFormat,
  LoadReplaceOption,
  LoadSourceType,
  Region,
} from '../../../constants/hammerstoneConstants';
import keys from '../../../constants/hammerstoneConstantKeys';
import Activity, { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { ColumnLayout, Link } from '@amzn/awsui-components-react';
import { ActivityS3CredentialContent } from '../ActivityS3CredentialContent';
import { LOAD_ACTIVITY_WIKI } from 'src/constants/staticUrls';
import Content, { Rules } from 'src/components/helpers/content/';
import { useFormField } from 'src/data/redux';

const { PARQUET, JSON, CUSTOM, CSV, DELIMITER } = keys.LoadFileFormat;

/**
 * An editable container for information about an LOAD/COPY-type activity's S3 Configuration parameters
 */
export default function LoadS3Container(props: ActivityContainerProps) {
  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;
  const ReplaceOptions = useMemo(() => objToOptions(LoadReplaceOption), []);
  const SourceTypeOptions = useMemo(() => objToOptions(LoadSourceType), []);
  const RegionOptions = useMemo(() => [{ label: 'Cross region disabled', value: '' }, ...objToOptions(Region)], []);
  const FileFormatOptions = useMemo(() => objToOptions(LoadFileFormat), []);
  const dataFormat = useFormField<Activity>('config.FILE_FORMAT');
  const symmetricKey = useFormField<Activity>('config.SYMMETRICKEY');

  return (
    <ColumnContainer
      header={props.header}
      actions={
        <Link variant="info" target="_blank" href={LOAD_ACTIVITY_WIKI}>
          Info
        </Link>
      }
    >
      <ActivityS3CredentialContent {...contentProps} />
      <ColumnLayout columns={4}>
        <Content.Text
          label="Source filepath"
          path="config.S3_FILE_NAME"
          rules={(l) => ({ required: Rules.required(l), pattern: Rules.pattern.s3Path(l) })}
          {...contentProps}
        />
        <Content.Select
          label="Replace option"
          path="config.DATA_REPLACE_OPTION"
          options={ReplaceOptions}
          rules={(l) => ({ required: Rules.required(l) })}
          {...contentProps}
        />
        <Content.Number
          label="Schedule offset (days)"
          path="scheduleDateOffset"
          rules={(l) => ({
            required: Rules.required(l),
            min: Rules.min(0, l),
            max: Rules.max(Number.MAX_SAFE_INTEGER, l),
            validate: { int: Rules.isInteger(l) },
          })}
          {...contentProps}
        />
        <Content.Select
          label="Source type"
          path="config.SOURCE_TYPE"
          rules={(l) => ({ required: Rules.required(l) })}
          options={SourceTypeOptions}
          {...contentProps}
        />
        <Content.Toggle label="Remove manifest" path="config.REMOVE_MANIFEST" {...contentProps} />
        <Content.Toggle
          label="Missing S3 file generates error"
          path="config.NO_FILES_TO_COPY_GENERATES_ERROR"
          {...contentProps}
        />
      </ColumnLayout>
      <b>
        Redshift <code className="custom-code-box">COPY</code> Command Parameters
      </b>
      <ColumnLayout columns={2} variant="text-grid">
        <ColumnLayout columns={2}>
          <Column>
            <Content.Select
              label="Bucket cross-region"
              options={RegionOptions}
              path="config.REGION"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS'],
                condition: (activity) => activity.config.CUSTOM_PARAMETERS?.length > 0,
              }}
              // Optional --> The top option is 'Cross region disabled'
              filteringType="auto"
              {...contentProps}
            />
            <Content.Number
              label="Max errors"
              path="config.MAX_ERROR"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS'],
                condition: (activity) => activity.config.CUSTOM_PARAMETERS?.length > 0,
              }}
              rules={(l) => ({
                required: Rules.required(l),
                min: Rules.min(0, l),
                validate: { int: Rules.isInteger(l) },
              })}
              {...contentProps}
            />
            <Content.Text
              label="Field delimiter"
              path="config.FIELD_DELIMITER"
              // TODO: Include infoHelpPanel and a description of constraints and default
              rules={(l) => ({
                // Always optional, will default to an empty string which is then represented as NUL (i.e. the 0-val char)
                pattern: Rules.pattern.fieldDelimiter(l),
              })}
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  ![CSV, DELIMITER].includes(activity.config.FILE_FORMAT),
              }}
              viewTransform={(delim: string) => (
                <>
                  {/* If the value is one of the keys in the `Delimiters` const obj, then render a grey span indicating the kind of delimiter next to it.
                The purpose of this is to literally spell-out characters that may otherwise be ambiguous or not visible (e.g. "|" -> "| (pipe)") */}
                  {delim}{' '}
                  {delim in Delimiters && (
                    <span className="missing-data">{` (${Delimiters[delim as keyof typeof Delimiters]})`}</span>
                  )}
                </>
              )}
              {...contentProps}
            />
            <Content.Toggle
              label="Encryption"
              path="config.ENCRYPTION"
              infoHelpPanel="Encryption"
              editDescription={
                <>
                  If you are loading KMS encrypted data, please leave the 'Encryption key Odin material set' field
                  blank. If you are loading Symmetric key encrypted data, please enable that checkbox below and provide
                  an Odin material set.
                </>
              }
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT', 'config.SYMMETRICKEY'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  [PARQUET, CUSTOM].includes(activity.config.FILE_FORMAT) ||
                  // Disable encryption field if symmetric key encryption is turned on, since the latter requires the former to be on
                  // This is critical because we need to ensure there cannot be a case where "Encryption" is off but "Symmetric" is on
                  // https://w.amazon.com/bin/view/HammerstoneAPIUserGuideTemp/Documentations/APIs#HLoadJob
                  activity.config.SYMMETRICKEY,
              }}
              {...contentProps}
            />
            <Content.Toggle
              label="Symmetric key (deprecated)"
              path="config.SYMMETRICKEY"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT', 'config.ENCRYPTION'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  [PARQUET, CUSTOM].includes(activity.config.FILE_FORMAT) ||
                  // Disable symmetric encryption (Odin) field if encryption is turned off, since the former requires the latter to be on
                  // https://w.amazon.com/bin/view/HammerstoneAPIUserGuideTemp/Documentations/APIs#HLoadJob
                  !activity.config.ENCRYPTION,
              }}
              {...contentProps}
            />
            <Content.Text
              label="Encryption key Odin material set (deprecated)"
              path="config.ENCRYPT_KEY_ODIN"
              placeholder="Enable Encryption and leave this field blank to generate a KMS key automatically instead"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.SYMMETRICKEY'],
                condition: (activity) => activity.config.CUSTOM_PARAMETERS?.length > 0 || !activity.config.SYMMETRICKEY,
              }}
              // Max length comes from https://odin.amazon.com/#search and https://sage.amazon.dev/posts/23693?t=7
              rules={(l) => ({
                required: { value: !!symmetricKey, message: `${l} is required when symmetric key is enabled` },
                pattern: Rules.pattern.materialSet(l),
                maxLength: Rules.maxLength(255, l),
              })}
              {...contentProps}
            />
            <Content.Number
              label="Skip init. lines"
              path="config.SKIP_LINES"
              // int
              rules={(l) => ({
                required: Rules.required(l),
                min: Rules.min(0, l),
                validate: { int: Rules.isInteger(l) },
              })}
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  [PARQUET, CUSTOM].includes(activity.config.FILE_FORMAT),
              }}
              {...contentProps}
            />
            <Content.Text
              label="Day format"
              path="config.DATE_FORMAT"
              placeholder="default: 'YYYY-MM-DD'"
              rules={(l) => ({ required: dataFormat !== PARQUET && Rules.required(l) })}
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  [PARQUET, CUSTOM].includes(activity.config.FILE_FORMAT),
              }}
              {...contentProps}
            />
            <Content.Text
              label="Time format"
              path="config.TIME_FORMAT"
              placeholder="default: 'YYYY-MM-DD HH:MI:SS'"
              rules={(l) => ({ required: dataFormat !== PARQUET && Rules.required(l) })}
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  [PARQUET, CUSTOM].includes(activity.config.FILE_FORMAT),
              }}
              {...contentProps}
            />
          </Column>
          <Column>
            <Content.Toggle
              label="Direct (fast) load"
              path="config.FASTLOAD"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT', 'config.DATA_REPLACE_OPTION'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  activity.config.FILE_FORMAT === PARQUET ||
                  activity.config.DATA_REPLACE_OPTION !== keys.LoadReplaceOption.INSERT,
              }}
              {...contentProps}
            />
            <Content.Toggle
              label="Escape option"
              path="config.ESCAPE_OPTION"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 || activity.config.FILE_FORMAT !== DELIMITER,
              }}
              {...contentProps}
            />
            <Content.Toggle
              label="Remove quotes"
              path="config.REMOVEQUOTES"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 || activity.config.FILE_FORMAT !== DELIMITER,
              }}
              {...contentProps}
            />
            <Content.Toggle
              label="G-zipped"
              path="config.GZIP"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  ![DELIMITER, JSON, CSV].includes(activity.config.FILE_FORMAT),
              }}
              {...contentProps}
            />
            <Content.Toggle
              label="Truncate to fit"
              path="config.TRUNCATE_TO_FIT"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  ![DELIMITER, JSON, CSV].includes(activity.config.FILE_FORMAT),
              }}
              {...contentProps}
            />
            <Content.Toggle
              label="Dry run"
              path="config.DRYRUN"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  ![DELIMITER, JSON, CSV].includes(activity.config.FILE_FORMAT),
              }}
              {...contentProps}
            />
            <Content.Toggle
              label="Trim trailing spaces"
              path="config.TRIM"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  ![DELIMITER, JSON, CSV].includes(activity.config.FILE_FORMAT),
              }}
              {...contentProps}
            />
            <Content.Toggle
              label="JSONPath file"
              path="config.JSONPATHFILE"
              infoHelpPanel="JsonPathFile"
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 || activity.config.FILE_FORMAT !== JSON,
              }}
              {...contentProps}
            />
            <Content.Text
              label="JSONPath input"
              path="config.JSONPATHFILEINPUT"
              rules={(l) => ({ required: Rules.required(l), pattern: Rules.pattern.s3Path(l) })}
              editDescription="JSONPath file must be Enabled in order to fill out this field. For more information, click the label above."
              disableOn={{
                paths: ['config.CUSTOM_PARAMETERS', 'config.FILE_FORMAT', 'config.JSONPATHFILE'],
                condition: (activity) =>
                  activity.config.CUSTOM_PARAMETERS?.length > 0 ||
                  activity.config.FILE_FORMAT !== JSON ||
                  !activity.config.JSONPATHFILE,
              }}
              {...contentProps}
            />
          </Column>
        </ColumnLayout>
        <Column>
          <Content.Select
            label="Data format"
            path="config.FILE_FORMAT"
            options={FileFormatOptions}
            editDescription={<descriptions.DataFormat />}
            {...contentProps}
          />
          <Content.Textarea
            label="Custom parameters"
            path="config.CUSTOM_PARAMETERS"
            missingText="No custom parameters defined: using default parameters"
            // optional
            infoHelpPanel="CustomParameters_LOAD"
            {...contentProps}
            editDescription={<descriptions.CustomParameterTemplate />}
          />
        </Column>
      </ColumnLayout>
    </ColumnContainer>
  );
}
