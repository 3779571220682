import React, { useCallback, useState } from 'react';
import { ButtonDropdown } from '@amzn/awsui-components-react';
import { useNavigate, useMatch } from 'react-router';

import store, { actions, useActivity, useGroupName } from '../../data/redux';
import { fetchPipelineInfo } from '../../data/api/fetchFromAPI';
import { DeleteActivityInfo } from 'src/lib/hammerstoneApi';
import PAGES from '../../nav/Pages';
import { ConfirmActionModal, tempFlashbarMessage } from '../helpers';
import { urlSearchString } from 'src/nav/navHelper';
import { removeFromRecentPages } from 'src/commons';

enum Action {
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
  // "delete" is a protected keyword in Type/JavaScript, and should not be used as a standalone variable name
  Copy = 'copy',
}

/**
 * Returns dropdown button with View, Edit, Copy, and Delete actions for an activity.
 *
 * @param {string} activityId The Id used to specify the activity metadata
 * @returns ButtonDropdown and Modal components to submit activity actions
 *  */
export default function ActivityActionsDropdown(props: { activityId: string }) {
  const { activityId } = props;

  const matchesViewActivity = useMatch(PAGES.VIEW_ACTIVITY.path);
  const isViewActivityPage = matchesViewActivity !== null;

  const navigate = useNavigate();

  // Gather activity related objects for calling APIs
  const groupName = useGroupName();

  const { data: activity, fetching } = useActivity(activityId);

  // Local component states
  const [isCalling, setIsCalling] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const loading = fetching || isCalling;
  const disabled = !parseInt(activityId) || !activity;

  function doAction(action: Action) {
    switch (action) {
      case Action.Delete:
        return setIsModalVisible(true);
      case Action.Edit:
        return navigate({
          pathname: PAGES.EDIT_ACTIVITY.path.replace(':activityId', activityId),
          search: urlSearchString({ groupName }),
        });
      case Action.Copy:
        return navigate({
          pathname: PAGES.COPY_ACTIVITY.path.replace(':activityId', activityId),
          search: urlSearchString({ groupName }),
        });
      case Action.View:
        return navigate({
          pathname: PAGES.VIEW_ACTIVITY.path.replace(':activityId', activityId),
          search: urlSearchString({ groupName }),
        });
    }
  }

  const deleteActivity = useCallback(() => {
    setIsCalling(true);
    return DeleteActivityInfo({ activityId: parseInt(activityId), groupName })
      .then(() => {
        store.dispatch(actions.data.clearData({ datatype: 'activity', dataId: activityId }));
        tempFlashbarMessage({
          id: `delete_activity_success`,
          message: { header: `Successfully deleted activity ${activity?.activityNamespace} (${activityId})` },
        });
        fetchPipelineInfo({ groupName, pipelineName: activity?.activityNamespace }, true);
        // Avoids outdated reference to deleted activity on the Home page
        removeFromRecentPages('activity', activityId);
        navigate({
          pathname: PAGES.VIEW_PIPELINE.path.replace(':pipelineName', activity?.activityNamespace),
          search: urlSearchString({ groupName }),
        });
      })
      .catch((error) => {
        console.error('Delete activity failed! ', error);
        store.dispatch(
          actions.page.addToFlashbar({
            id: 'delete_activity_failure',
            message: {
              type: 'error',
              header: `Failed to delete activity ${activityId}`,
              content: error.message,
              dismissible: true,
            },
          }),
        );
      })
      .finally(() => {
        setIsCalling(false);
      });
  }, [activityId, groupName, activity?.activityNamespace]);

  const items: any[] = [
    // When you are NOT on the view activity page, "View activity" will be the main action
    // and "Edit activity" will be a secondary action item in the dropdown.
    // When you ARE on the view activity page, "Edit activity" becomes the main (and primary) action
    // and therefore is not repeated in the dropdown
    ...(isViewActivityPage
      ? []
      : [
          {
            text: 'Edit activity',
            id: Action.Edit,
            iconName: 'edit',
          },
        ]),
    {
      text: 'Copy activity',
      id: Action.Copy,
      iconName: 'copy',
    },
    {
      text: 'Delete activity',
      id: Action.Delete,
      iconName: 'remove',
    },
  ];

  return (
    <>
      <ButtonDropdown
        data-testid="activityActionsDropdown"
        items={items}
        onItemClick={({ detail }) => doAction(detail.id as Action)}
        loading={loading}
        disabled={disabled}
        variant={isViewActivityPage ? 'primary' : 'normal'}
        mainAction={{
          onClick: () => doAction(isViewActivityPage ? Action.Edit : Action.View),
          iconName: isViewActivityPage ? 'edit' : 'zoom-in',
          text: isViewActivityPage ? 'Edit activity' : 'View activity',
          loading,
          disabled,
        }}
      />
      <ConfirmActionModal
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        confirmName="Delete"
        onConfirm={() => deleteActivity()}
        header={`Delete activity ${activity?.activityName}?`}
      >
        Delete activity <b>{activity?.activityName}</b> ({activityId}) from pipeline{' '}
        <b>{activity?.activityNamespace}</b>?
      </ConfirmActionModal>
    </>
  );
}
