import { ButtonDropdown } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useNavigate, useMatch } from 'react-router';

import { removeFromRecentPages } from 'src/commons';
import keys from 'src/constants/hammerstoneConstantKeys';
import { fetchPipelineNames } from 'src/data/api/fetchFromAPI';
import { actions, useGroupName, usePipeline } from 'src/data/redux';
import { DeletePipelineInfo } from 'src/lib/hammerstoneApi';
import { urlSearchString } from 'src/nav/navHelper';
import PAGES from 'src/nav/Pages';
import { ConfirmActionModal, tempFlashbarMessage } from '../helpers';

enum Action {
  View = 'view',
  Add = 'add',
  Edit = 'edit',
  Delete = 'delete',
}

export default function PipelineActionsDropdown(props: { pipelineName: string }) {
  const { pipelineName } = props;

  const matchesViewPipeline = useMatch(PAGES.VIEW_PIPELINE.path);
  const isViewPipelinePage = matchesViewPipeline !== null;

  const groupName = useGroupName();
  const navigate = useNavigate();

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  // The current action, set to empty string if no action is currently being processed
  const { data: pipeline, fetching } = usePipeline({ groupName, pipelineName });
  const [isCalling, setIsCalling] = useState(false);

  const { status } = pipeline || {};
  // Button dropdown is disabled if no pipeline name is set, or if no pipeline has loaded
  const disabled = !pipelineName || !pipeline;
  // If not disabled, set to loading state if object is fetching or the action is non-empty
  const loading = fetching || isCalling;

  const items: any[] = [
    // When you are NOT on the view pipeline page, "View pipeline" will be the main action
    // and "Add activity" will be a secondary action item in the dropdown.
    // When you ARE on the view pipeline page, "Add activity" becomes the main (and primary) action
    // and therefore is not repeated in the dropdown
    ...(isViewPipelinePage ? [] : [{ text: 'Add activity', id: Action.Add, iconName: 'add-plus' }]),
    {
      text: 'Edit pipeline',
      id: Action.Edit,
      iconName: 'edit',
    },
    {
      text: 'Delete pipeline',
      id: Action.Delete,
      iconName: 'remove',
      disabled: status === keys.StatusCode.EDIT_PENDING,
      disabledReason: 'Pipeline cannot be deleted while an edit is pending',
    },
  ];

  function doAction(action: Action) {
    switch (action) {
      case Action.Delete:
        return setDeleteModalVisible(true);
      case Action.View:
        return navigate({
          pathname: PAGES.VIEW_PIPELINE.path.replace(':pipelineName', pipelineName),
          search: urlSearchString({ groupName }),
        });
      case Action.Add:
        return navigate({
          pathname: PAGES.CREATE_ACTIVITY.path,
          search: urlSearchString({ groupName, pipelineName }),
        });
      case Action.Edit:
        return navigate({
          pathname: PAGES.EDIT_PIPELINE.path.replace(':pipelineName', pipelineName),
          search: urlSearchString({ groupName }),
        });
    }
  }

  return (
    <>
      <ConfirmActionModal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        header={`Permanently delete pipeline '${pipelineName}'?`}
        confirmName={'Delete'}
        onConfirm={async () => {
          setIsCalling(true);
          try {
            await DeletePipelineInfo({
              groupName,
              pipelineName,
            });
            setIsCalling(false);
            tempFlashbarMessage({
              id: `delete_pipeline_${pipelineName}`,
              message: { header: `Successfully deleted pipeline '${pipelineName}'!` },
            });
            fetchPipelineNames({ groupName }, true);

            // Avoids outdated reference to deleted pipeline on the Home page
            removeFromRecentPages('pipeline', pipelineName);

            navigate({ pathname: PAGES.MANAGE_PIPELINES.path, search: urlSearchString({ groupName }) });
          } catch (error) {
            actions.page.addToFlashbar({
              id: `pipeline_delete_failed`,
              message: {
                type: 'error',
                header: `Failed to delete pipeline '${pipelineName}'`,
                content: error.toString(),
                dismissible: true,
              },
            }),
              console.error(error);
          }
        }}
      >
        Deleting a pipeline will also delete all its activities. This action cannot be reversed.
      </ConfirmActionModal>
      <ButtonDropdown
        data-testid="managePipelinesActions"
        disabled={disabled}
        loading={loading}
        items={items}
        onItemClick={async ({ detail }) => doAction(detail.id as Action)}
        variant={isViewPipelinePage ? 'primary' : 'normal'}
        mainAction={{
          onClick: () => doAction(!isViewPipelinePage ? Action.View : Action.Add),
          text: isViewPipelinePage ? 'Add activity' : 'View pipeline',
          iconName: isViewPipelinePage ? 'add-plus' : 'zoom-in',
          loading,
          disabled,
        }}
      />
    </>
  );
}
