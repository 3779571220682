import { Link } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';

import { useFormFieldErrors } from 'src/data/redux';

/** A recursive function to converts a react-hook-form `FieldErrors` object to a flattened map from each (invalid) path to its invalid message.
 *
 * @example
 * fieldErrors = {
 *  a: { message : 'A is invalid' },
 *  b: {
 *    c: { message : 'C is invalid' },
 *    d: { message : 'D is invalid' }
 *  }
 * };
 *
 * const pathMessageMap = fieldErrorsToPathMessageMap(fieldErrors);
 *
 * //pathMessageMap ===
 * {
 *  'a' : 'A is invalid',
 *  'b.c': 'C is invalid',
 *  'b.d': 'D is invalid',
 * }
 */
export function fieldErrorsToPathMessageMap(
  errors: any,
  previousPath: string = '',
  accumulated: { [path: string]: string } = {},
): { [path: string]: string } {
  // For each key-value pair in the structured errors object...
  Object.entries(errors ?? {}).forEach(([key, value]: any) => {
    // keep track of its current path (dot-separated)
    const currentPath = previousPath ? previousPath + '.' + key : key;

    if (value && typeof value['message'] === 'string') {
      // If the current value contains an invalid "message", add it to the accumulated pathMessageMap
      accumulated[currentPath] = value.message;
    } else if (typeof value === 'object') {
      // Otherwise, if the value is an object, recurse over its key-value pairs
      fieldErrorsToPathMessageMap(value, currentPath, accumulated);
    }
  });

  return accumulated;
}

/**
 * @param {object} pathMessageMap a flattened map from dot-separated paths to their respective error messages.
 *
 * @returns a list of objects, each represnting an invalid field, sorted by their distance from the top of the field (i.e. sorted by the order of the fields on the page)
 * Each object contains the respective `path` to the field and its invalid `message`.
 * If the field corresponding to a particular path can be found on the page, its distance from the top and DOM element are included
 */
export function getOrderedInvalidFields(pathMessageMap: { [path: string]: string }): {
  path: string;
  message: string;
  distanceFromTop?: number;
  $fieldElement?: Element;
}[] {
  // For each path-message pair in the input object...
  const fieldDistanceFromTop = Object.entries(pathMessageMap ?? {}).map(([path, message]) => {
    // Try and fetch the corresponding field element from the DOM by its data-content-path attribute
    const $element = document.querySelector(`[data-content-path="${path}"]`);

    // If the fieldElement is found, compute its distance from the top of the page
    return {
      path,
      message,
      $fieldElement: $element,
      distanceFromTop: $element ? $element.getBoundingClientRect()?.top : NaN,
    };
  });

  // Order path-message pairs by distance, but place undefined/unfound fields at the end
  const orderedPathMessages = Object.values(fieldDistanceFromTop).sort((a, b) => {
    if (!b.distanceFromTop) {
      return -1;
    }
    if (!a.distanceFromTop) {
      return 1;
    }
    return a.distanceFromTop - b.distanceFromTop;
  });

  return orderedPathMessages;
}

/** A react component which displays the Redux `state.page.formFieldErrors` as an ordered list component.
 * Converts the path-message map to an unordered list of each message, then plus a `scroll` link which will bring the user to the corresponding field upon click, if defined */
export function FormErrorFieldsText(props: { invertLink?: boolean }) {
  const invalidFields = useFormFieldErrors();
  const orderedPathMessageList = useMemo(() => getOrderedInvalidFields(invalidFields), [invalidFields]);

  // Render a Component/Element displaying a list of all the messages
  if (invalidFields && Object.keys(invalidFields).length) {
    return (
      <ul>
        {React.Children.toArray(
          (orderedPathMessageList ?? []).map(({ message, $fieldElement }) => (
            <li>
              {message}{' '}
              {$fieldElement && (
                <Link
                  color={props.invertLink ? 'inverted' : 'normal'}
                  variant="secondary"
                  onFollow={() =>
                    $fieldElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
                  }
                >
                  (scroll)
                </Link>
              )}
            </li>
          )),
        )}
      </ul>
    );
  } else {
    return null;
  }
}
