import { Link } from '@amzn/awsui-components-react';
import React from 'react';

/**
 * Source: https://code.amazon.com/packages/AWS-UI-Examples/blobs/11d9c83c34f25ec595e7b8a2fa4b6f8cfe35c61d/--/src/pages/commons/common-components.jsx#L71-L77
 * 
 * A special case of external link, to be used within a link group, where all of them are external
 * and we do not repeat the icon
 * 
 * See Polaris sample: https://polaris.a2z.com/examples/react/landing-page.html?awsui-mode=light
 */
export const ExternalLinkItem = ({ href, text }: {href: string, text: string}) => (
  <Link href={href} ariaLabel={`${text} ${externalLinkProps.externalIconAriaLabel}`} target="_blank">
    {text}
  </Link>
);

const externalLinkProps = {
  external: true,
  externalIconAriaLabel: 'Opens in a new tab',
};
