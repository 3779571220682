import React from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import {
  createExtractTransformParametersTemplate,
  createLoadParametersTemplate,
} from 'src/components/activity/all/CustomParametersTemplate';
import { CopySpan, ReadMoreHere } from 'src/components/helpers';
import keys from 'src/constants/hammerstoneConstantKeys';
import * as urls from 'src/constants/staticUrls';
import { useFormField } from 'src/data/redux';
import Activity from 'src/interfaces/activityInterfaces';

/** Displays descriptions of each data format with links to relevant resources. */
export function DataFormat() {
  // Retrieves the form value of FILE_FORMAT ("Data format") from Redux
  const dataFormat = useFormField<Activity>('config.FILE_FORMAT');

  switch (dataFormat as keyof typeof keys.LoadFileFormat) {
    case 'DELIMITER':
      return <ReadMoreHere href={urls.REDSHIFT_COPY_DELIMITER_FORMAT_DOCS} />;
    case 'CSV':
      return (
        <>
          CSV format does not support the following data conversion parameters: Remove Quotes and Escape Option.{' '}
          <ReadMoreHere href={urls.REDSHIFT_COPY_CSV_FORMAT_DOCS} />
        </>
      );
    case 'PARQUET':
      return (
        <>
          Parquet format does not currently most of the data conversion parameters, with a few exceptions (e.g.,{' '}
          <code className="custom-code-box">.gz</code> files are decompressed automatically). For more information on
          Columner formats: <ReadMoreHere href={urls.REDSHIFT_COPY_COLUMNAR_DOCS} />. For more information on Parquet:{' '}
          <ReadMoreHere href={urls.REDSHIFT_COPY_PARQUET_FORMAT_DOCS} />.
        </>
      );
    case 'JSON':
      return (
        <>
          JSON format does not support the following data conversion parameters: Delimiter, Remove Quotes, and Escape
          Option. <ReadMoreHere href={urls.REDSHIFT_COPY_JSON_FORMAT_DOCS} />
        </>
      );
    case 'CUSTOM':
      return (
        <>
          Custom format disables and overwrites all other parameters. To build custom parameters: clear the current
          parameters, select your preferred format, set the relevant parameter fields, and follow the instructions under
          custom parameters.
        </>
      );
    default:
      return (
        <>
          Select a data format to see details. <ReadMoreHere href={urls.REDSHIFT_COPY_FORMATS_DOCS} />
        </>
      );
  }
}

/** Displays a descsription of Custom Parameters along with a useful button to generate a template and copy it to the users clipboard */
export function CustomParameterTemplate() {
  let form: UseFormReturn<Activity>;
  let activity: Activity;
  try {
    form = useFormContext<Activity>();
    // Instead of subscribing to values, fetch them once
    activity = form.getValues();
  } catch {}

  return (
    <CopySpan
      toCopy={() => {
        if (activity.activityType === keys.ActivityType.COPY) {
          return createLoadParametersTemplate(activity);
        } else {
          return createExtractTransformParametersTemplate(activity);
        }
      }}
    >
      To generate a template: set the relevant parameters (found under the header "
      {activity.activityType === keys.ActivityType.COPY ? 'Redshift COPY' : 'S3 UNLOAD'} Command Parameters" ), click
      the copy icon, and paste the custom parameters into the field below.
    </CopySpan>
  );
}

export function SQLQuery() {
  return (
    <>
      In the new Hammerstone, we automatically escape quotes, backlashes, and line-breaks for you. You <b>do not</b>{' '}
      need to manually escape double quotes. If you are having issues with query formatting, please cut us a{' '}
      <a href={urls.HAMMERSTONE_HELP_DESK} target="_blank">
        ticket
      </a>{' '}
      or reach out over{' '}
      <a href={urls.DATA_TOOLS_CUSTOMERS_SLACK} target="_blank">
        Slack
      </a>{' '}
      with more details.
    </>
  );
}
