import React, { Component, ReactNode } from 'react';
import { LabeledContent } from '../helpers';
import { iContent } from '../helpers/content/contentInterfaces';

/** At minimum, a content error boundary requires a `label` prop to gracefull fail and render a LabeledContent component with the expected `label`   */
type RequiredContentProps = Required<Pick<iContent<any>, 'label'>>;

/**
 * This error boundary catches errors in its children `Content` components
 * In case of an error, returns `<LabeledContent>` notifying the user that the error
 * has ocurred, without exiting or breaking the entire page.
 * For more information: https://reactjs.org/docs/error-boundaries.html
 */
export class ContentErrorBoundary<TProps extends RequiredContentProps> extends Component<
  { children: ReactNode; contentProps: TProps },
  { hasError: boolean; error: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.info('Content component error', { props: this.props.contentProps });
    console.error(error, errorInfo);
    // TODO: Log error to services? Report failure?
  }

  render() {
    if (this.state.hasError) {
      return (
        <LabeledContent label={this.props.contentProps.label}>
          <div className="missing-data">there was a failure rendering this content</div>
        </LabeledContent>
      );
    } else {
      return this.props.children;
    }
  }
}

/**
 * A wrapper function which takes a `Content` function (NOT component) as its input and returns the component within a ContentErrorBoundary
 *
 * ---
 *
 * Incorrect Usage:
 *
 *      <WrappedText>
 *        <Content.Text {...TextContentProps} />
 *      </WrappedText>
 *
 * Incorrect Usage:
 *
 *      const WrappedText = ContentErrorWrapper(<Content.Text {...TextContentProps}/>)
 *
 * ---
 *
 * Correct Usage:
 *
 *      const WrappedText = ContentErrorWrapper(TextContent);
 *      return <>
 *          ...
 *          <WrappedText {...TextContentProps} />
 *      </>;
 */
export function ContentErrorWrapper<TProps extends RequiredContentProps>(
  ContentComponent: (props: TProps) => JSX.Element,
) {
  return function (props: TProps) {
    return (
      <ContentErrorBoundary contentProps={props}>
        <ContentComponent {...props} />
      </ContentErrorBoundary>
    );
  };
}

export default ContentErrorBoundary;
