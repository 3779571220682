import { DataType, DataTypePrimaryId } from '../interfaces/reduxInterfaces';

// TODO: WHENEVER YOU MAKE A BREAKING CHANGE TO THIS FILE, PLEASE INCREMENT THE VERSION VARIABLE!
export const RECENT_PAGES_VERSION = 0;
export const HS_RECENT_PAGES = `HS_RECENT_PAGES_V${RECENT_PAGES_VERSION}`;
export const RECENT_PAGES_LENGTH = 5;

export const clearOldRecentPagesCache = () => {
  // Clear old versions of the cache to prevent collisions, parsing errors, or other bugs
  window.localStorage.removeItem('RECENT_PAGES');
  // TODO: Eventually rewrite to map from old cache to new without losing data
  for (let v = 0; v < RECENT_PAGES_VERSION; v++) {
    window.localStorage.removeItem(`HS_RECENT_PAGES_V${v}`);
  }
};
export interface iRecentPage {
  title: string;
  dataIds: { [key: string]: string };
  type: DataType;
}

/**
 * Cache the path, and name of the 5 most recent visited pages using localstorage.
 */
export const addToRecentPages = (newPage: iRecentPage) => {
  try {
    const unparsed = window.localStorage.getItem(HS_RECENT_PAGES) || '[]';
    const oldPages: iRecentPage[] = JSON.parse(unparsed);

    const trimmed = computeRecentPages(newPage, oldPages);

    const stringified = JSON.stringify(trimmed);
    window.localStorage.setItem(HS_RECENT_PAGES, stringified);
  } catch (e) {
    console.error(e); // TODO: Log error to back-end (CloudWatch).
  }
};

export const removeFromRecentPages = (type: DataType, primaryDataId: string) => {
  try {
    const unparsed = window.localStorage.getItem(HS_RECENT_PAGES) || '[]';
    const oldPages: iRecentPage[] = JSON.parse(unparsed);

    const trimmed = oldPages.filter(
      (page) => page.type !== type || page.dataIds[DataTypePrimaryId[page.type]] !== primaryDataId,
    );

    const stringified = JSON.stringify(trimmed);
    window.localStorage.setItem(HS_RECENT_PAGES, stringified);
  } catch (e) {
    console.error(e); // TODO: Log error to back-end (CloudWatch).
  }
};

/**
 * Takes in a single newPage and list of old pages and then dedupes based on the primary type ID
 */
export const computeRecentPages = (newPage: iRecentPage, oldPages: iRecentPage[]) => {
  const newPagePrimaryId = newPage.dataIds[DataTypePrimaryId[newPage.type]];
  const deduped = oldPages.filter(
    ({ type, dataIds }) => type !== newPage.type || dataIds[DataTypePrimaryId[type]] !== newPagePrimaryId,
  );
  const newPages = [newPage, ...deduped];
  const trimmed = newPages.slice(0, RECENT_PAGES_LENGTH);
  return trimmed;
};
