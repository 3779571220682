import React from 'react';
import { TopNavigation } from '@amzn/awsui-components-react/polaris';
import PAGES from './Pages';
import { useAlias, useGroupName, useIsCustomerOnboarded } from 'src/data/redux/selectors';
import { useNavigate } from 'react-router';
import { urlSearchString } from './navHelper';
import { HammerstoneGroupSelector } from './HammerstoneGroupSelector';
import { ONBOARDING_WIKI } from 'src/constants/staticUrls';

export default function TopbarNavigation() {
  const navigate = useNavigate();
  const alias = useAlias();
  const groupName = useGroupName();
  const phoneTool = `https://phonetool.amazon.com/users/${alias ?? 'Loading...'}`;
  const isCustomerOnboarded = useIsCustomerOnboarded();

  return (
    /**
     * This div adds style to TopbarNavigation and links formatting with AppLayout components by assiging the div id to the
     * AppLayout's headerSelector param.
     *
     * Some manual CSS selection via polaris class names were also necessary to support a right justified, searchable
     * dropdown menu effect using the Select component below. The CSS is defined in base.scss under the
     * "myTopbarNavigation" id with more details provided.
     *
     * TODO: remove this manual CSS selection when Polaris or browser support improves.
     */
    <div id="myTopbarNavigation" style={{ position: 'sticky', top: 0, zIndex: 1002, justifyContent: 'right' }}>
      <TopNavigation
        identity={{
          href: PAGES.HOME.path + urlSearchString({ groupName }),
          logo: {
            src: './hammerstone-react-logo.png',
            alt: 'Hammerstone Logo',
          },
          onFollow: (e) => {
            e.preventDefault();
            //Manually navigating because a type mismatch is breaking the event parameter (no href, refreshes page)
            navigate({ pathname: PAGES.HOME.path, search: urlSearchString({ groupName }) });
          },
        }}
        search={isCustomerOnboarded && <HammerstoneGroupSelector />}
        utilities={[
          !isCustomerOnboarded && {
            type: 'button',
            external: true,
            text: 'Get access',
            href: ONBOARDING_WIKI,
            ariaLabel: 'Get access (opens a new tab)',
          },
          {
            type: 'button',
            iconName: 'user-profile',
            text: alias ?? 'Loading...',
            href: phoneTool,
            ariaLabel: 'Phonetool link',
          },
        ]}
        i18nStrings={{
          overflowMenuTriggerText: 'More',
          overflowMenuTitleText: 'All',
        }}
      />
    </div>
  );
}
