import { Box, Link, PopoverProps, SpaceBetween } from '@amzn/awsui-components-react';
import * as urls from 'src/constants/staticUrls';
import { ReadMoreHere } from '..';
import React from 'react';

// These variables define the props for a Polaris `<Popover>` component (minus its `children` which will be defined later)
// Popovers should provide supplemental, non-essential information to users about displayed text.
// Documentation: https://polaris.a2z.com/components/popover

// WARNING!
// This implementation does NOT follow all Polaris best practices (https://polaris.a2z.com/components/popover/?tabId=usage) ...
// TODO: ...it should be revised once we flesh out the site's Help System (https://polaris.a2z.com/patterns/general/help-system/) and ...
// TODO: ... populate the tools drawer with useful information (see <HammerstoneAppLayout> and https://polaris.a2z.com/components/app-layout/?tabId=api)

export const customParameters_EXTRACT_TRANSFORM: PopoverProps = {
  header: 'What are custom parameters?',
  content: (
    <SpaceBetween size="xxs">
      <Box>
        Hammerstone generates the following S3 command from the parameters in this container:{' '}
        <Box variant="code">
          <div className="custom-code-box">
            UNLOAD (&#123;extract_sql&#125;) TO &#123;s3_location&#125; CREDENTIALS &#123;s3_access_keys&#125;
            &#123;encrypted&#125; <b>&#123;custom_parameters&#125;</b>
          </div>
        </Box>
      </Box>
      <Box>
        To define more advanced{' '}
        <Link href={urls.REDSHIFT_UNLOAD_DOCS} external>
          Redshift UNLOAD command
        </Link>{' '}
        parameters, you may override these configurations by defining custom parameters in this field. Click the copy
        button in the description below to generate a template for this activity's custom parameters.
      </Box>
      <ReadMoreHere href={urls.CUSTOM_PARAMETERS_UNLOAD_WIKI} />
    </SpaceBetween>
  ),
};

export const customParameters_LOAD: PopoverProps = {
  header: 'What are custom parameters?',
  content: (
    <SpaceBetween size="xxs">
      <Box>
        Hammerstone's WorkerService generates the following Redshift command from the parameters in this container:{' '}
        <Box variant="code">
          <div className="custom-code-box">
            COPY &#123;target_table&#125; FROM &#123;s3_location&#125; CREDENTIALS &#123;iam_roles&#125; &#123;manifest
            <b>&#125; &#123;custom_parameters&#125;</b> STATUPDATE ON ;
          </div>
        </Box>
      </Box>
      <Box>
        To define more advanced{' '}
        <Link href={urls.REDSHIFT_COPY_DOCS} external>
          Redshift COPY command
        </Link>{' '}
        parameters, you may override these configurations by defining custom parameters in this field. Click the copy
        button in the description below to generate a template for this activity's custom parameters.
      </Box>
      <ReadMoreHere href={urls.CUSTOM_PARAMETERS_LOAD_WIKI} />
    </SpaceBetween>
  ),
};

export const encryption: PopoverProps = {
  header: 'What is this?',
  content: (
    <SpaceBetween size="xxs">
      <Box variant="p">
        To read about encryption in Hammerstone, see our wiki{' '}
        <Link href={urls.ENCRYPTION_WIKI} external>
          here.
        </Link>
      </Box>
      <Box variant="p">
        To read about encryption in Redshift, see the{' '}
        <Link external href={urls.REDSHIFT_UNLOADING_ENCRYPTION_DOCS}>
          UNLOAD
        </Link>{' '}
        and{' '}
        <Link external href={urls.REDSHIFT_LOADING_ENCRYPTION_DOCS}>
          COPY
        </Link>{' '}
        encryption wikis.
      </Box>
    </SpaceBetween>
  ),
};

export const iamRoleChaining: PopoverProps = {
  header: 'What is IAM role chaining?',
  content: <ReadMoreHere href={urls.IAM_ROLE_CHAINING_WIKI} />,
};

export const confidenceFile: PopoverProps = {
  header: 'What is a confidence file?',
  content: <ReadMoreHere href={urls.CONFIDENCE_FILE_WIKI} />,
};

export const activityType: PopoverProps = {
  header: 'What are activity types?',
  content: (
    <SpaceBetween size="xxs">
      <Box>
        <Link href={urls.EXTRACT_ACTIVITY_WIKI} external>
          Extract
        </Link>{' '}
        - Queries data on Redshift with SQL and outputs the results to S3.
      </Box>
      <Box>
        <Link href={urls.TRANSFORM_ACTIVITY_WIKI} external>
          Transform
        </Link>{' '}
        - Queries data on Redshift with SQL and outputs the results to S3. Supports multiple SQL statements, identity
        key management, and auditing steps.
      </Box>
      <Box>
        <Link href={urls.LOAD_ACTIVITY_WIKI} external>
          Copy (AKA "Load")
        </Link>{' '}
        - Loads data from S3 to Redshift clusters
      </Box>
      <hr />
      <ReadMoreHere href={urls.HOW_TO_USE_HAMMERSTONE_WIKI} />
    </SpaceBetween>
  ),
};

function djsUndeterminedPopover(type: 'date' | 'status'): PopoverProps {
  return {
    triggerType: 'text',
    content: (
      <SpaceBetween size="xxs">
        <b>
          What does <span className="missing-data">undetermined {type}</span> mean?
        </b>
        <Box>
          <ul>
            <li>DJS is throttling Hammerstone's calls. Please wait a few minutes before trying again.</li>
            <li>
              This activity has not yet been activated or executed by DJS, and therefore has no last execution {type}
            </li>
            <li>DJS is not returning any {type} for some other reason.</li>
          </ul>
        </Box>
        <Box>To minimize throttling, refrain from excessive refreshes and wait a few seconds between refreshes.</Box>
      </SpaceBetween>
    ),
  };
}
export const undeterminedDjsLastExecutionDate: PopoverProps = djsUndeterminedPopover('date');

export const undeterminedDjsLastExecutionStatus: PopoverProps = djsUndeterminedPopover('status');

export const jsonPathFile: PopoverProps = {
  header: 'What is this?',
  content: <ReadMoreHere href={urls.REDSHIFT_COPY_JSON_USAGE_DOCS} />,
};

export const encryptionOdinAndKMS: PopoverProps = {
  header: 'How do I use Odin or KMS encryption?',
  content: (
    <SpaceBetween size="xxs">
      <Box>
        <b>Precondition: </b>Encryption is required for both KMS and Odin. <ReadMoreHere href={urls.ENCRYPTION_WIKI} />
      </Box>
      <Box>
        <b>With Publisher:</b> You may only use Odin. If no material set is provided, Redshift multi-part files will be
        encrypted with S3 server-side AES-256 encryption (by an automatically generated KMS Key) BUT the Publisher file
        sent by email will NOT be encrypted.
      </Box>
      <Box>
        <b>Without Publisher:</b> You may provide your own KMS Key (as long as your pipeline uses IAM as S3 credentials;{' '}
        <ReadMoreHere href={urls.ODIN_TO_IAM_MIGRATION_GUIDE} />
        ), Odin material set, or leave both blank to use automated AES-256 encryption.
      </Box>
    </SpaceBetween>
  ),
};

export const addAcl: PopoverProps = {
  header: 'What is this?',
  content: <ReadMoreHere href={urls.ADD_ACL_WIKI} />,
};

export const remedyAlarmForPreconditions: PopoverProps = {
  header: 'What are these?',
  content: <ReadMoreHere href={urls.REMEDY_ALARM_FOR_PRECONDITIONS_WIKI} />,
};

export const s3CredentialType: PopoverProps = {
  header: 'Why are Odins deprecated?',
  content: <ReadMoreHere href={urls.ODIN_TO_IAM_MIGRATION_GUIDE} />,
};

export const s3OdinMaterialSet: PopoverProps = {
  header: 'S3 Odins are deprecated',
  content: (
    <>
      Odins are being deprecated in{' '}
      <Link external target="_blank" href={urls.RAGNAROK}>
        Project Ragnarok
      </Link>{' '}
      (<ReadMoreHere href={urls.RAGNAROK} />
      ). Old pipelines which still use Odins are prohibited from creating new activities. Please migrate this Pipeline
      to use IAM Roles for S3 credentials instead, following{' '}
      <Link external target="_blank" href={urls.ODIN_TO_IAM_MIGRATION_GUIDE}>
        this guide
      </Link>
      .
    </>
  ),
};

export const destinationFilepath_EXTRACT_TRANSFORM: PopoverProps = {
  header: 'Destination S3 Filepath',
  content: (
    <SpaceBetween size="xxs">
      <Box variant="p">
        You may include uppercase UTC datetime placeholders for the year{' '}
        <span className="custom-code-box">&#123;YYYY&#125;</span>, month{' '}
        <span className="custom-code-box">&#123;MM&#125;</span>, day{' '}
        <span className="custom-code-box">&#123;DD&#125;</span>, and hour{' '}
        <span className="custom-code-box">&#123;HH&#125;</span> (24hr).
      </Box>
      <Box variant="p">
        {/* Example and best practices suggested by alezhou@ */}
        An ideal filepath clearly indicates the activity, pipeline, and schedule.
      </Box>
      <Box variant="p">
        For example, a monthly activity might have the following path:
        <div className="custom-code-box">
          s3://hammerstone-output/this-pipeline/this-monthly-activity/&#123;YYYY&#125;/&#123;MM&#125;/output_
        </div>{' '}
        while an hourly activity might have the following:
        <div className="custom-code-box">
          s3://hammerstone-output/this-pipeline/this-hourly-activity/&#123;YYYY&#125;/&#123;MM&#125;/&#123;DD&#125;/&#123;HH&#125;/output_
        </div>
        . When these files are written, the datetime placeholders will be written as the corresponding numeric values.
      </Box>
    </SpaceBetween>
  ),
};

export const redshiftOverwrite: PopoverProps = {
  header: 'What does this do?',
  content: (
    <>
      By default,{' '}
      <Link external href={urls.REDSHIFT_UNLOAD_DOCS}>
        UNLOAD
      </Link>{' '}
      commands fail instead of overwriting files in the S3 destination. To bypass this error and overwrite files (inc.
      manifest files), enable this toggle, or manually include the{' '}
      <span className="custom-code-box">ALLOWOVERWRITE</span> parameter in the custom parameters. You may also avoid
      naming collisions by defining a destination with datetime placeholders (see the "Destination filepath" above).
    </>
  ),
};

export const redshiftManifest_EXTRACT_TRANSFORM: PopoverProps = {
  header: 'What is this?',
  content: (
    <SpaceBetween size="xxs">
      <Box variant="p">
        A manifest is a JSON-formatted list of the files that an{' '}
        <Link external href={urls.REDSHIFT_UNLOAD_DOCS}>
          UNLOAD
        </Link>{' '}
        command <i>has written</i> and that a <Link href={urls.REDSHIFT_COPY_DOCS}>COPY</Link> command{' '}
        <i>should read</i> (<ReadMoreHere href={urls.REDSHIFT_MANIFEST_DOCS} />
        ).
      </Box>
      <Box variant="p">
        To enable manifest in Extract and Transform jobs, select this toggle or manually add "MANIFEST" to the custom
        parameters. Redshift will write a manifest to the destination bucket, listing all the files created by the
        UNLOAD command.
      </Box>
    </SpaceBetween>
  ),
};
