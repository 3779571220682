import React, { useEffect, useMemo } from 'react';
import {
  AttributeEditor,
  AttributeEditorProps,
  Box,
  FormField,
  Input,
  Select,
  Table,
} from '@amzn/awsui-components-react';
import {
  ArrayPath,
  Control,
  Controller,
  FieldValues,
  Path,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFormReturn,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { get } from 'lodash-es';

import { BetterCodeEditor, CodeTextBox, HelpPanelInfoLink, LabeledContent } from '..';
import { useIsEditContentDisabled, useViewContent } from './contentHelpers';
import { DisplayMode, ResourcePath, ResourceType, iAttributeEditorContent } from './contentInterfaces';
import { AttributeEditorInput } from 'src/interfaces/inputInterfaces';
import { LabeledContentSkeleton } from '../skeletons/LabeledContentSkeleton';
import { optionsToObj } from 'src/commons';
import { DataAttributes, filterDataAttributes, getContentDataAttributes } from 'src/commons/dataAttributes';
import { SALTIRE } from 'src/constants/ariaLabels';
import { editorInputDefinition } from 'src/interfaces/inputInterfaces';
import { ContentErrorWrapper } from 'src/components/error/ContentErrorBoundary';

export const AttributeEditorContent = ContentErrorWrapper(_AttributeEditorContent);

/** Renders the field identified by the `path` as a Polaris `<AttributeEditor>` component, displays as `<Table>`. Manipulates the value as a `object[]` type
 * For an overview of `<Content>` components, see the [README.md](./README.md). For usage examples, see the [How-to guide](./HowToGuide.md) */
function _AttributeEditorContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iAttributeEditorContent<RType, DisabledOnPaths>,
) {
  // Processed at top level component to avoid unneccessary executions of a relatively heavy functions between renders
  const dataAttributes = { ...filterDataAttributes(props), ...getContentDataAttributes('AttributeEditor', props) };
  switch (props.mode) {
    case DisplayMode.View:
      return <ViewAttributeEditorContent {...props} dataAttributes={dataAttributes} />;
    case DisplayMode.Edit:
      return <EditAttributeEditorContent {...props} dataAttributes={dataAttributes} />;
    case DisplayMode.Loading:
      return <LabeledContentSkeleton label={props.label} />;
    default:
      return null;
  }
}

function ViewAttributeEditorContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iAttributeEditorContent<RType, DisabledOnPaths> & { dataAttributes: DataAttributes },
) {
  const { value, disabled } = useViewContent(props);
  // For each definition, create the optionsMap if options are defined. This allow this operation to be computed once rather than on every render
  const optionsMapByKey = useMemo(
    () => Object.fromEntries(props.definitions.map(({ key, options }) => [key, optionsToObj(options)])),
    [props.definitions],
  );
  const columnDefinitions = useMemo(
    () =>
      props.definitions.map(({ input, key, label }) => ({
        id: key,
        header: label,
        cell: (item: any) => {
          const _value = item[key];
          switch (input) {
            case AttributeEditorInput.Code:
              // SQL is used as the default language based on the fact that queries are the most common use-case (currently) for Code Editors
              // Nevertheless, any prismjs-supported language may be used (https://prismjs.com/)
              return <CodeTextBox language={item.language || 'sql'}>{_value}</CodeTextBox>;
            case AttributeEditorInput.Select:
              return get(optionsMapByKey, `${key}.${_value}.label`) ?? _value;
            default:
              return _value;
          }
        },
      })),
    [props.definitions],
  );

  return (
    <LabeledContent
      label={props.label}
      missingText={props.missingText}
      info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
    >
      <span className={disabled ? 'disabled-content' : ''} {...props.dataAttributes}>
        {props.viewTransform ? (
          props.viewTransform(value)
        ) : (
          <Table
            items={value ?? []}
            sortingDisabled
            columnDefinitions={columnDefinitions}
            // TODO: Eventually expand Table interface to EditorAttributeContent
            empty={
              <Box textAlign="center" color="inherit">
                <span className="missing-data">{props.missingText || 'No data to show'}</span>
              </Box>
            }
          />
        )}
      </span>
    </LabeledContent>
  );
}

function EditAttributeEditorContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iAttributeEditorContent<RType, DisabledOnPaths> & { dataAttributes: DataAttributes },
) {
  // Subscribe to changes in fields on which this field is conditionally disabled. No `.onChange` passed in since AttributeEditor uses a react-hook-form's FieldArray update
  const disabled = useIsEditContentDisabled(props);

  // Get the react-hook-form form object from the context
  const form = useFormContext<ResourceType[RType]>();
  const { control, formState, clearErrors } = form;

  // TODO: Eventually implement full rules and disabled parameters on top-level AE controller to be in parity with other fields, this is just a temporary fix
  useEffect(() => {
    // This effect will ignore any potential validation errors if the particular field is disabled
    if (disabled) {
      clearErrors(props.path);
    }
  }, [disabled, formState.isSubmitting, formState.isValidating]);

  // Use a field array to edit the values of the defined path. This will control high-level changes to the entirety of the value/path (not individual subfields)
  const { fields, update, append, remove } = useFieldArray({
    control,
    name: props.path as ArrayPath<ResourceType[RType]>,
    rules: props.rules as any,
  });

  // Get the AttributeEditor definition, including the custom controls, from the component's props
  const definition = useMemo(
    () => editorDefinition(props.definitions, form, props.path, update, disabled, props.resourceType),
    [disabled, props.definitions, get(formState.errors, props.path)],
  );

  const onAddButtonClick = () => editorAddWithDefault(fields, append, props.definitions);
  const onRemoveButtonClick = ({ detail }: CustomEvent) => remove(detail.itemIndex);

  return (
    <FormField
      label={props.label}
      description={props.editDescription}
      info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
      errorText={get(formState, `errors.${props.path}.root.message`)}
    >
      <span {...props.dataAttributes}>
        <AttributeEditor
          // TODO: Eventually extend this Content's interface to include relevant AttributeEditor props for more fine-grain control
          disableAddButton={disabled}
          isItemRemovable={() => !disabled}
          onAddButtonClick={onAddButtonClick}
          onRemoveButtonClick={onRemoveButtonClick}
          items={fields}
          addButtonText={`+ Add ${props.label}`}
          removeButtonText={SALTIRE}
          empty="No items associated with this resource."
          definition={definition}
        />
      </span>
    </FormField>
  );
}

// === Attribute Editor Specific Helper Functions ===

/**
 * Adds a new value to an [`<AttributeEditor>`] items using the `append()` function returned by [`useFieldArray()`](https://react-hook-form.com/docs/usefieldarray) hook
 * This will set the newly added value's attributes to defaults based on the definition
 *
 * If `defaults` is a string, then that will be used as its default value
 * If `defaults` is `true`, then this will use the last value for that particular field
 * If `defaults` is a function, then the current value of all the items is passed into the function, and the return string is used as a default value
 * Otherwise, an empty string is used
 *
 * @param {any[]} allItems The list of all items being displayed in the attribute editor
 * @param {UseFieldArrayAppend} append The append function returned by the high-level react-hook-form [`useFieldArray`](https://react-hook-form.com/docs/usefieldarray) hook
 * @param {editorInputDefinition[]} definitions The props.definition of the Content.AttributeEditor
 *
 * @example
 * AttributeEditor ... onAddButtonClick={editorAddWithDefault(allItems, append, props.definitions)}/>
 */
function editorAddWithDefault(
  allItems: any[],
  append: UseFieldArrayAppend<any, any>,
  definitions: editorInputDefinition[],
) {
  const newItem: any = {};
  //Sets default values for items within an Attribute editor, if a string or function is defined. If defaults just === true, returns the previously used value.
  definitions.forEach(({ key, defaults }) => {
    if (typeof defaults === 'function') {
      newItem[key] = defaults(allItems);
    } else if (defaults === true) {
      newItem[key] = allItems?.length > 0 ? allItems[allItems.length - 1][key] : undefined;
    } else {
      newItem[key] = defaults ?? '';
    }
  });
  append(newItem);
}

/** Converts our custom Content.AttributeEditor's definition props to that of Cloudscape's [`<AttributeEditor>`](https://cloudscape.aws.dev/components/attribute-editor/)
 * This allows the local `editorInputDefinition` type to be used to define BOTH the definitions of a Table and an AttributeEditor simultaneously.
 * By using a single interface that is mapped to both Table and AttributeEditor, we automate the definition of unique (but inferrable) key-value pairs for each definition, respectively (such as `cell` in Table and `control` in AttribtueEditor )
 * Implements the controls for each field in the definition thru a react-hook-form [`<Controller>`](https://react-hook-form.com/docs/usecontroller/controller)
 *
 * @param {editorInputDefinition[]} definitions The definitions for each field, must include a label and key
 * @param {UseFormReturn} form The form returned by react-hook-form [`useForm()`](https://react-hook-form.com/docs/useform) or [`useFormContext()`](https://react-hook-form.com/docs/useformcontext) hooks
 * @param {string} path The `props.path` of the AttributeEditor Content, which should point to a `object[]` value
 * @param {UseFieldArrayUpdate} update The `update()` function returned by [`useFieldArray()`](https://react-hook-form.com/docs/usefieldarray) hook
 * @param {boolean} disabled Optional - Whether the entire field is disabled
 * @returns A list of definitions for the `definition` prop of an `<AttributeEditor>`
 *
 * @example
 * <AttributeEditor ... items={allItems} definition={editorDefinition(props.definition)} /> */
function editorDefinition<T extends FieldValues>(
  definitions: editorInputDefinition[],
  form: UseFormReturn<T>,
  path: Path<T>,
  update: UseFieldArrayUpdate<T>,
  disabled?: boolean,
  resourceType?: string,
): AttributeEditorProps<any>['definition'] {
  return definitions.map((def) => {
    const partialDefinition = {
      label: def.label,
      errorText: (_: any, index: number) => get(form.formState, `errors.${path}[${index}].${def.key}.message`),
    };
    const subpath = (index: number) => `${path}.${index}.${def.key}` as string;
    const subDataAttributes = (index: number) =>
      getContentDataAttributes(`AttributeEditor.${def.input ?? AttributeEditorInput.Text}`, {
        mode: DisplayMode.Edit,
        path: subpath(index),
        label: def.label,
        resourceType,
      });

    switch (def.input) {
      case AttributeEditorInput.Select:
        return {
          ...partialDefinition,
          control: (item: any, index: number) => (
            <Controller
              control={form.control as Control}
              name={subpath(index)}
              rules={def.rules}
              render={({ field, fieldState }) => {
                const optionsMap = optionsToObj(def.options);
                return (
                  <span {...subDataAttributes(index)}>
                    <Select
                      selectedOption={{
                        value: item[def.key],
                        label: optionsMap[item[def.key]]?.label ?? item[def.key],
                      }}
                      options={def.options}
                      onChange={({ detail }) => {
                        // By default, useFieldArray will add an `id` field to each object in order to track its values internally (see https://react-hook-form.com/docs/usefieldarray Returns `fields`)
                        // In order to prevent this ID from 'leaking' out to the values of the field array, it is deleted on changes.
                        // This preserves react-hook-form's behavior without introducing unexpected key-value pairs to AttributeEditor fields
                        delete item.id;
                        // Calling field.onChange will trigger an evaluation of whether this field violates its defined validation rules (local state)
                        field.onChange(detail.selectedOption.value);
                        update(index, { ...item, [def.key]: detail.selectedOption.value });
                      }}
                      placeholder={def.placeholder}
                      selectedAriaLabel="Selected"
                      disabled={disabled}
                      ref={field.ref}
                      onBlur={field.onBlur}
                      invalid={fieldState.invalid}
                    />
                  </span>
                );
              }}
            />
          ),
        };
      case AttributeEditorInput.Code:
        return {
          ...partialDefinition,
          control: (item: any, index: number) => (
            <Controller
              control={form.control as Control}
              name={subpath(index)}
              rules={def.rules}
              render={({ field }) => {
                return (
                  <span {...subDataAttributes(index)}>
                    <BetterCodeEditor
                      value={item[def.key]}
                      language={def.language}
                      onChange={({ detail }) => {
                        delete item.id;
                        // Calling field.onChange will trigger an evaluation of whether this field violates its defined validation rules (local state)
                        field.onChange(detail.value);
                        update(index, { ...item, [def.key]: detail.value });
                      }}
                      className="editor-code-input"
                      editorContentHeight={100}
                      disabled={disabled}
                    />
                  </span>
                );
              }}
            />
          ),
        };
      default: //Default input is Text
        return {
          ...partialDefinition,
          control: (item: any, index: number) => (
            <Controller
              control={form.control as Control}
              name={subpath(index)}
              rules={def.rules}
              render={({ field, fieldState }) => {
                return (
                  <span {...subDataAttributes(index)}>
                    <Input
                      value={item[def.key]}
                      placeholder={def.placeholder}
                      onChange={({ detail }) => {
                        delete item.id;
                        // Calling field.onChange will trigger an evaluation of whether this field violates its defined validation rules (local state)
                        field.onChange(detail.value);
                        update(index, { ...item, [def.key]: detail.value });
                      }}
                      disabled={disabled}
                      ref={field.ref}
                      onBlur={field.onBlur}
                      invalid={fieldState.invalid}
                    />
                  </span>
                );
              }}
            />
          ),
        };
    }
  });
}
