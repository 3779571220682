import React, { useMemo } from 'react';
import { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { DependencyWait, PreconditionS3Type, Region } from '../../../constants/hammerstoneConstants';
import { ColumnContainer } from '../../helpers';
import { PRECONDITIONS_WIKI } from 'src/constants/staticUrls';
import { objToOptions } from 'src/commons';
import { Link } from '@amzn/awsui-components-react';
import Content, { Rules } from 'src/components/helpers/content/';
import { AttributeEditorInput } from 'src/interfaces/inputInterfaces';

/**
 * An editable container with information about an Activity's preconditions
 */
export default function PreconditionsContainer(props: ActivityContainerProps) {
  // Memoized at top level to avoid unnecessary computations
  const PRECONDITION_OPTIONS = useMemo(() => objToOptions(PreconditionS3Type), []);
  const REGION_OPTIONS = useMemo(() => objToOptions(Region), []);
  const DEPENDENCY_OPTIONS = useMemo(() => DependencyWait.map((label, ix) => ({ value: ix.toString(), label })), []);

  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;

  return (
    <ColumnContainer
      header={props.header}
      columns={3}
      actions={
        <Link variant="info" target="_blank" href={PRECONDITIONS_WIKI}>
          Info
        </Link>
      }
      footer={
        <Content.AttributeEditor
          {...contentProps}
          label="Preconditions"
          path="preconditions"
          definitions={[
            {
              key: 'key',
              label: 'S3 Type',
              input: AttributeEditorInput.Select,
              options: PRECONDITION_OPTIONS,
              defaults: true,
              rules: { required: Rules.required('Precondition S3 type') },
            },
            {
              key: 'value',
              label: 'S3 Precondition',
              input: AttributeEditorInput.Text,
              placeholder: 's3://<bucket-name>/<folder>/<filename>',
              rules: {
                required: Rules.required('Precondition S3 value'),
                pattern: Rules.pattern.s3Path('Precondition S3 value'),
              },
            },
          ]}
        />
      }
    >
      <Content.Select
        {...contentProps}
        label="S3 bucket region"
        path="s3PreconRegion"
        options={REGION_OPTIONS}
        filteringType="auto"
        rules={(l) => ({ required: Rules.required(l) })}
      />
      <Content.Number
        {...contentProps}
        label="Retry maximum"
        path="config.PRECON_RETRY"
        rules={(l) => ({
          required: Rules.required(l),
          min: Rules.min(0, l),
          max: Rules.max(99, l),
          validate: { int: Rules.isInteger(l) },
        })}
      />
      <Content.Select
        {...contentProps}
        label="Wait for dependencies"
        path="config.PRECON_WAIT"
        options={DEPENDENCY_OPTIONS}
        rules={(l) => ({ required: Rules.required(l) })}
      />
    </ColumnContainer>
  );
}
