import { ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { getNodeText } from '../../commons';

export function ColumnContainer(props: {
  header: React.ReactNode;
  children?: React.ReactNode;
  mode?: 'any' | 'viewOnly' | 'editOnly';
  actions?: React.ReactNode;
  footer?: React.ReactNode;
  columns?: number;
}) {
  return (
    <Container
      // Adds a unique(ish) data-testid value to containers by combining column-container with a stringified header
      data-testid={'column-container:' + getNodeText(props.header).toLowerCase().replaceAll(' ', '_')}
      header={
        <Header variant="h2" actions={props.actions}>
          {props.header}
        </Header>
      }
      footer={props.footer}
    >
      {props.columns ? (
        <ColumnLayout columns={props.columns} variant="text-grid">
          {props.children}
        </ColumnLayout>
      ) : (
        <SpaceBetween size="xl">{props.children}</SpaceBetween>
      )}
    </Container>
  );
}
