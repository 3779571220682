import { Box, Button, Checkbox, ColumnLayout, Link, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { EDIT_ACTIVITY_ISSUE_SIM_TEMPLATE } from 'src/constants/staticUrls';

import { useGroupName } from 'src/data/redux';
import PAGES from 'src/nav/Pages';
import { urlSearchString } from 'src/nav/navHelper';

const IGNORE_EDIT_ACTIVITY_RISK = 'IGNORE_EDIT_ACTIVITY_RISK' as const;

// TODO: Should this be displayed right before submitting?

const getIgnore = () => JSON.parse(window.localStorage.getItem(IGNORE_EDIT_ACTIVITY_RISK) ?? 'false');
const setIgnore = (ignore?: boolean) =>
  window.localStorage.setItem(IGNORE_EDIT_ACTIVITY_RISK, JSON.stringify(ignore ?? false));

export function AcknowledgeEditActivityRisk(props: { activityId: string }) {
  const groupName = useGroupName();
  const [dismiss, setDismiss] = useState(false);
  const ignore = getIgnore();
  const [shouldIgnore, setShouldIgnore] = useState(false);
  const navigate = useNavigate();

  return (
    <Modal
      onDismiss={() => {
        setDismiss(true);
        navigate({
          pathname: PAGES.VIEW_ACTIVITY.path.replace(':activityId', props.activityId),
          search: urlSearchString({ groupName }),
        });
      }}
      visible={!(dismiss || ignore)}
      closeAriaLabel="Close modal"
      footer={
        <ColumnLayout columns={2}>
          <Box float="left" padding={{ top: 'xxs' }}>
            <Checkbox checked={shouldIgnore} onChange={({ detail }) => setShouldIgnore(detail.checked)}>
              <i>Do not show again</i>
            </Checkbox>{' '}
          </Box>
          <Box float="right">
            <SpaceBetween size="xs" direction="horizontal">
              <Button
                variant="primary"
                iconName="check"
                onClick={() => {
                  setIgnore(shouldIgnore);
                  setDismiss(true);
                }}
                data-testid="acknowledgeEditActivityRisk"
              >
                Acknowledge
              </Button>
            </SpaceBetween>
          </Box>
        </ColumnLayout>
      }
      header="Before you proceed..."
    >
      <SpaceBetween size="m">
        <Box variant="p">
          We are excited to deliver a new and improved Hammerstone experience. As you transition to the new console, we
          hope that you find it faster, easier to use, and more delightful!
        </Box>
        <Box variant="p">
          No software is perfect, however, so we appreciate your patience as we iron out uncaught edge-cases.
        </Box>
        <Box variant="p">
          In the event that editing an activity causes unintended side-effects, please{' '}
          <Link href={EDIT_ACTIVITY_ISSUE_SIM_TEMPLATE} external>
            cut us a ticket using this template
          </Link>
          . You can always roll back changes to a{' '}
          <Link
            external
            target="_blank"
            href={PAGES.ACTIVITY_HISTORY.pageLayoutProps({ activityId: props.activityId }).oldHref}
          >
            previous revision
          </Link>{' '}
          <b>if the activity has been activated.</b> To be extra safe, please activate your activity (thereby creating a
          revision) <b>before</b> making edits!
        </Box>
        {/* TODO: Next step, add buttons here to activate and pause the activity from the modal before editing, so that user doesn't have to return to View page */}
        <br />
      </SpaceBetween>
    </Modal>
  );
}
