import { Link } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';

import { LabeledContent, LabeledContentSkeleton } from 'src/components/helpers';
import { DisplayMode } from 'src/components/helpers/content/contentInterfaces';
import { getServiceRegion, HAMMERSTONE_ADMIN_GROUP } from 'src/constants/config';
import { DEFAULT_DJS_CONSOLE_LINK, DJS_CONSOLE_LINKS } from 'src/constants/staticUrls';
import { fetchDjsJobId } from 'src/data/api/fetchFromAPI';
import { useActivity, useHammerstoneGroups, useDjsJobId } from 'src/data/redux';

interface iDjsActivityContent {
  mode: DisplayMode;
  activityId: string;
}

/**
 * A LabeledContent component which will display the DJS Job ID if the following criterion are met:
 *   - The user belongs to the admin hammerstone group
 *   - The given activity is not "NEW"
 *   - The DJS link correctly fetches from Repo Service
 */
export default function DjsActivityContent(props: iDjsActivityContent) {
  const hammerstoneGroups = useHammerstoneGroups();
  if (!hammerstoneGroups?.includes(HAMMERSTONE_ADMIN_GROUP)) {
    return null;
  } else {
    return <DjsJobLink {...props} />;
  }
}

/**
 * Separated this component out to avoid unnecessary hook calls in the applications
 */
function DjsJobLink({ activityId, mode }: iDjsActivityContent) {
  const { data: djsJobId, fetching } = useDjsJobId(activityId);
  const { data: activity } = useActivity(activityId);
  const region = getServiceRegion();
  const djsBaseUrl = DJS_CONSOLE_LINKS[region as keyof typeof DJS_CONSOLE_LINKS] ?? DEFAULT_DJS_CONSOLE_LINK;
  const djsHref = `${djsBaseUrl}/jobs/${djsJobId}`;

  useEffect(() => {
    if (activity?.activityStatusCode && activity.activityStatusCode !== 'NEW') {
      // Only fetches DJS ID for non-new activities
      fetchDjsJobId({ activityId: parseInt(activityId) });
    }
  }, [activity?.activityStatusCode, activityId]);

  if (mode === DisplayMode.Loading || fetching) {
    return <LabeledContentSkeleton label="DJS activity" />;
  } else if (!djsJobId) {
    // If the DJS Activity id is null/empty
    return (
      <LabeledContent label="DJS activity">
        <span className="missing-data">unavailable until the activity has been activated</span>
      </LabeledContent>
    );
  } else {
    // DJS activity ID is a defined string
    return (
      <LabeledContent label="DJS activity">
        <Link href={djsHref} external>
          {djsJobId}
        </Link>
      </LabeledContent>
    );
  }
  return <></>;
}
