import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { dataSlice } from './slices/dataSlice';
import { userSlice } from './slices/userSlice';
import { ctiSlice } from './slices/ctiSlice';
import { pageSlice } from './slices/pageSlice';
const { createStateSyncMiddleware, initStateWithPrevTab } = require('redux-state-sync');

export const actions = {
  user: userSlice.actions,
  data: dataSlice.actions,
  cti: ctiSlice.actions,
  page: pageSlice.actions,
};

const reduxStateSyncConfig: any = {};
// Only synchronizes API data between tabs (not user or form data)
reduxStateSyncConfig['white' + 'list'] = Object.keys(actions.data).map(
  (key) => actions.data[key as keyof typeof actions.data].type,
);

// Unit test only complex reducers: https://redux.js.org/usage/writing-tests#unit-testing-individual-functions
export const store = configureStore({
  reducer: combineReducers({
    user: userSlice.reducer,
    data: dataSlice.reducer,
    cti: ctiSlice.reducer,
    page: pageSlice.reducer,
  }),
  middleware: [createStateSyncMiddleware(reduxStateSyncConfig)],
});

initStateWithPrevTab(store);

export default store;
