import React from 'react';
import { Container, ColumnLayout, SpaceBetween, Header } from '@amzn/awsui-components-react';
import Skeleton from 'react-loading-skeleton';
import { LabeledContentSkeleton } from './LabeledContentSkeleton';
import { colorBackgroundLayoutMain } from '@amzn/awsui-design-tokens/polaris';

// Selects the hex value of the background color
const background = colorBackgroundLayoutMain.slice(-7, -1);

//TODO: Eventually refactor to separate CSS asset, but TODO: howto leverage Polaris design tokens?
// Fades out the bottom of the container
const finalCSS = {
  position: 'absolute',
  height: 250,
  zIndex: 100, //Ensures that the fade is placed on top of the container
  width: '105%', // Covers borders and shadows of container
  right: -3,
  left: -3,
  bottom: -3,
  background: `linear-gradient(to bottom, #${background}00 0%, #${background}FF 70%)`,
} as React.CSSProperties;

/**
 * A loading placeholder for a `<Container>`
 *
 * @param {number[]} columnsPerRow The number of LabeledContentSkeletons rendered in each row. e.g. [4,4,4] renders three rows with four columns each.
 * @param {string} header An optional header to add instead of a loading skeleton
 * @param {boolean} final Whether to fade out the bottom of the final container
 */
export function ContainerSkeleton({ columnsPerRow = [3, 3, 3] as number[], header = '', final = false }) {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Container header={header ? <Header variant="h2">{header}</Header> : <Skeleton count={0.25} height="2em" />}>
        <SpaceBetween size="xxl">
          {React.Children.toArray(
            columnsPerRow.map((n) => (
              <ColumnLayout columns={n}>
                {React.Children.toArray([...Array(n)].map(() => <LabeledContentSkeleton />))}
              </ColumnLayout>
            )),
          )}
        </SpaceBetween>
      </Container>
      {/* Will fade out the bottom of the container if it is declared as `final` */}
      <div style={final ? finalCSS : {}}></div>
    </div>
  );
}
