import { Box, Button, Icon } from '@amzn/awsui-components-react';
import React from 'react';
import { useNavigate } from 'react-router';
import { useGroupName } from 'src/data/redux';
import { urlSearchString } from 'src/nav/navHelper';
import PAGES from '../../nav/Pages';
import { LinkButton } from '../helpers';

/**
 * A component containing centered buttons for redirecting the users either back or home after an error / failure
 */
export default function BacktrackButtons() {
  const navigate = useNavigate();
  const groupName = useGroupName();

  return (
    <Box padding={{ top: 'l' }}>
      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        <Button variant="link" onClick={() => navigate(-1)}>
          <Icon name="angle-left" /> Previous page
        </Button>
        <LinkButton variant="link" href={PAGES.HOME.path + urlSearchString({ groupName })} data-testid="homePageLinkButton">
          Home page <Icon name="angle-right" />
        </LinkButton>
      </div>
    </Box>
  );
}
