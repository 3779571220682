// === THIS FILE IS SLATED TO REPLACE THE OLD 'contentHelpers' FILE AND IMPLEMENTS A STRONGER TYPESCRIPT INTERFACE & REACT-HOOK-FORM INSTEAD OF REDUX FORM SLICE ===
import { get, set } from 'lodash-es';
import { UseFormSetValue, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InterfaceFromPaths, ResourcePath, iDisabledOn, ResourceType, iContent } from './contentInterfaces';
import { ReduxState } from 'src/interfaces/reduxInterfaces';
import { changeBy } from 'src/commons/componentHelpers';

/**
 * A helper hook to retrieve props used to display various `<View___Content>` components
 *
 * @param props The props passed into the parent `<Content>` component
 *
 * @returns An object containing :
 *    - `value`: The value of the particular property (defined by path) of a particular resource (identified by resourceType and resourceId, e.g. 'activity' and '1234') in the Redux data state
 *    - `disabled`: Whether that value would be disabled if the Content were in an `edit` mode
 *    - `useValue`: a hook which can retrieve the value at a path of a particular resource (identified by resourceType and resourceId, see above)
 */
export function useViewContent<RType extends keyof ResourceType, DisableOnPaths extends ResourcePath<RType>[] = []>(
  props: iContent<RType, DisableOnPaths>,
) {
  // A hook which retrieves the value at a path of a particular resource in the Redux data slice
  // lodash `get` is used to efficiently and succinctly retrieve paths that may be deeply nested in an object (https://lodash.com/docs/4.17.15#get)
  const useViewValue = <RT extends keyof ResourceType = RType>(path: ResourcePath<RT>) =>
    useSelector((state: ReduxState) => get(state.data[props.resourceType]?.data[props.resourceId], path));

  // The value of the path at the resource identified by `props.resourceType` and `props.resourceId`
  const value = useViewValue<RType>(props.path);

  // Whether this particular field should be disabled
  const disabledOnValue = useDisabledOn(props.disableOn, useViewValue);
  const disabled = disabledOnValue || props.disabled;

  return { value, disabled, useValue: useViewValue };
}

/** Retrieves the values of a path using `react-form-hook`'s `useWatch` hook, which subscribes to changes in the value of that defined field
Using `useWatch` with a particular pathname (instead of `form.watch`) prevents the entire form component from re-rendering on every change (https://react-hook-form.com/docs/usewatch) **/
function useEditValue<RType extends keyof ResourceType>(path: ResourcePath<RType>) {
  return useWatch({ name: path });
}

/**
 * A helper hook to retrieve an onChange function to be used by various `<Edit___Content>` components
 *
 * @param props The props passed into the parent `<Content>` component
 * @param setField The function which will update the value of the current field (e.g. `react-hook-form`'s `field.onChange`)
 * @param setValue A function which updates the the value of a particular field (arg0) with a new value (arg1) (e.g. `react-hook-form`'s `form.setValue`)
 * @param changeByEvent Optional - A key to how the field's value should be udpated based on the emitted event. Can be:
 *    - `"value"`: (default) Used for most string and number fields (e.g. `<Input>`, <Textarea>`, etc.)
 *    - `"option"`:  Used for radio and select fields (e.g. `<Select>`), returns the `value` of the selected option
 *    - `"options"`: Used for multiselect and checkbox fields fields (e.g  `<Multiselect>`), returns a list of the `value` of the selected options
 *    - `"checked"`: Used for boolean fields (e.g. `<Toggle>`), returns `true`/`false`
 *    - Custom function: You may also define a custom method which will take in the emitted event and compute some desired transformation
 *
 * @returns An `onChange` function which updates a form field's value from the event emitted by an Input-style component
 */
export function useEditOnChange<RType extends keyof ResourceType, DisableOnPaths extends ResourcePath<RType>[] = []>(
  props: iContent<RType, DisableOnPaths>,
  setField: (...event: any[]) => void,
  setValue: UseFormSetValue<ResourceType[RType]>,
  changeByEvent: keyof typeof changeBy = 'value',
) {
  // Allows custom onChange function for particular form fields
  // but developer must then ensure that the `form` field's value is correctly updated, so the field's onChange is passed in to assist
  const onChange: (event: CustomEvent<any>) => void =
    // If there is a custom onChange function defined...
    props.onChange
      ? //  ...then use it and pass in the field updater.
        (event) => props.onChange(event, setField, setValue) // TODO: Eventually replace the setField function with a setValue function which can change other paths in an object for more customizable behavior
      : // Otherwise,  use a predefined `changeBy` function to retrieve the correct value format from the event (see `changeBy` function map for implementation)
        (event) => changeBy[changeByEvent](setField)(event);

  return onChange;
}

/**
 * @param props The props passed into the parent `<Content>` component, notably props.disabled and props.disableOn.
 * This hook will subscribe to changes to the `props.disableOn.paths` and update it's returned value based on whether the `props.disableOn.condition` OR `props.disabled` is true.
 * @returns Whether the given component should be disabled
 */
export function useIsEditContentDisabled<
  RType extends keyof ResourceType,
  DisableOnPaths extends ResourcePath<RType>[] = [],
>(props: iContent<RType, DisableOnPaths>) {
  // Whether the form field should be disabled
  const disabledOnValue = useDisabledOn(props.disableOn, useEditValue);
  const disabled = disabledOnValue || props.disabled;

  return disabled;
}

/**
 * A helper hook which subscribes to changes at various `paths` and returns whether the values at those paths cause a `condition()` to be true or false
 *
 * Why is this necessary? `<Content>` was designed with both a `disabled` and a `disableOn` props  in order to maximize the component's flexibility and performance, while maintaining a type-constrained interface.
 * It may initially seem redundant to separate the subscribed paths from the conditions dependent on those paths. However, this allows the component to subscribe
 * to **only** the necessary fields (thereby minimizizing renders) while abstracting away the subscription logic to prevent developer errors (e.g conditional hook
 * errors) and confusion (e.g. how to subscribe to the `view` versus `edit` values.)
 *
 * @param disableOn An object with `paths` defining the various fields of a form to watch, and a `condition` function which takes in an object containing the form's values at those paths and returns whether to `disable watched
 * @param useValue A hook which can retrieve the value at a particular path (e.g. a custom Redux `useSelector` or react-hook-form `useWatch` function)
 *
 * @returns Whether the `condition` is true for the values defined at the `paths`
 */
export function useDisabledOn<DisableOnPaths extends string[]>(
  disableOn: iDisabledOn<DisableOnPaths>,
  useValue: (path: string) => any,
): boolean {
  if (disableOn?.condition && disableOn?.paths?.length) {
    const disableOnValues = {};
    (disableOn?.paths).forEach((path) => {
      // Lodash `set()` creates a deeply nested path for dot-separated paths that do not yet exist
      set(disableOnValues, path, useValue(path));
    });

    // Input the watched values defined above to the condition function to determine if disabled
    return disableOn.condition(disableOnValues as InterfaceFromPaths<DisableOnPaths>);
  } else {
    return false;
  }
}
