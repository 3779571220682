import React, { useEffect } from 'react';
import { FormField, Textarea } from '@amzn/awsui-components-react';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash-es';
import { useEditOnChange, useIsEditContentDisabled, useViewContent } from './contentHelpers';
import { DisplayMode, ResourcePath, ResourceType, iContent } from './contentInterfaces';
import { LabeledContentSkeleton } from '../skeletons/LabeledContentSkeleton';
import { HelpPanelInfoLink, LabeledContent } from '..';
import { DataAttributes, filterDataAttributes, getContentDataAttributes } from 'src/commons/dataAttributes';
import { ContentErrorWrapper } from 'src/components/error/ContentErrorBoundary';
import { Rules } from '.';

/** Renders the field identified by the `path` as a Polaris `<Textarea>`. Manipulates the value as a `string` type.
 * For an overview of `<Content>` components, see the [README.md](./README.md). For usage examples, see the [How-to guide](./HowToGuide.md) */
export const TextareaContent = ContentErrorWrapper(_TextareaContent);

function _TextareaContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iContent<RType, DisabledOnPaths>,
) {
  // Processed at top level component to avoid unneccessary executions of a relatively heavy functions between renders
  const dataAttributes = { ...filterDataAttributes(props), ...getContentDataAttributes('Textarea', props) };

  switch (props.mode) {
    case DisplayMode.View:
      return <ViewTextareaContent {...props} dataAttributes={dataAttributes} />;
    case DisplayMode.Edit:
      return <EditTextareaContent {...props} dataAttributes={dataAttributes} />;
    case DisplayMode.Loading:
      return <LabeledContentSkeleton label={props.label} height="5em" />;
    default:
      return null;
  }
}

function ViewTextareaContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iContent<RType, DisabledOnPaths> & { dataAttributes: DataAttributes },
) {
  const { value, disabled } = useViewContent(props);

  return (
    <LabeledContent
      label={props.label}
      missingText={props.missingText}
      info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
    >
      <span className={disabled ? 'disabled-content' : ''} {...props.dataAttributes}>
        {props.viewTransform ? props.viewTransform(value) : <Textarea readOnly value={value} />}
      </span>
    </LabeledContent>
  );
}

function EditTextareaContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iContent<RType, DisabledOnPaths> & { dataAttributes: DataAttributes },
) {
  const { control, clearErrors, setValue } = useFormContext<ResourceType[RType]>();
  const disabled = useIsEditContentDisabled(props);

  return (
    <Controller
      control={control}
      name={props.path}
      rules={Rules.useRules(props.rules, props.label, disabled)}
      render={({ field, formState, fieldState }) => {
        const onChange = useEditOnChange(props, field.onChange, setValue, 'value');

        useEffect(() => {
          // This effect will ignore any potential validation errors if the particular field is disabled
          if (disabled) {
            clearErrors(props.path);
          }
        }, [disabled, formState.isSubmitting, formState.isValidating]);

        return (
          <FormField
            label={props.label}
            description={props.editDescription}
            errorText={get(formState, `errors.${props.path}.message`)}
            info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
          >
            <span {...props.dataAttributes}>
              <Textarea
                value={field.value as string}
                ref={field.ref}
                onChange={onChange}
                onBlur={field.onBlur}
                invalid={fieldState.invalid}
                disabled={disabled}
                placeholder={props.placeholder}
              />
            </span>
          </FormField>
        );
      }}
    />
  );
}
