import { SpaceBetween, Link, ColumnLayout } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { ColumnContainer } from '../../helpers';
import { CompareOperator } from '../../../constants/hammerstoneConstants';
import { objToOptions } from 'src/commons';
import { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { TRANSFORM_AUDIT_WIKI } from 'src/constants/staticUrls';
import Content, { Rules } from 'src/components/helpers/content/';

/**
 * An editable container for information about an TRANSFORM-type activity's optional auditor parameters
 */
export default function TransformAuditorContainer(props: ActivityContainerProps) {
  const CompareOptions = useMemo(() => objToOptions(CompareOperator), []);
  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;

  return (
    <ColumnContainer
      header={props.header}
      actions={
        <Link variant="info" target="_blank" href={TRANSFORM_AUDIT_WIKI}>
          Info
        </Link>
      }
      footer={
        <Content.Toggle
          {...contentProps}
          label="Store audit results"
          path="config.STORE_AUDIT"
          disableOn={{ paths: ['config.USE_AUDITOR'], condition: (activity) => !activity.config.USE_AUDITOR }}
          expandable
        >
          <ColumnLayout columns={2} variant="text-grid">
            <Content.Code
              label="Store audit results SQL"
              path="config.STORE_AUDIT_QUERY"
              language="sql"
              editorContentHeight={200}
              disableOn={{
                paths: ['config.USE_AUDITOR', 'config.STORE_AUDIT'],
                condition: (activity) => !activity.config.USE_AUDITOR || !activity.config.STORE_AUDIT,
              }}
              {...contentProps}
            />
            <SpaceBetween size="m">
              <SpaceBetween direction="horizontal" size="s">
                <Content.Text
                  label="Schema"
                  path="config.STORE_AUDIT_SCHEMA"
                  disableOn={{
                    paths: ['config.USE_AUDITOR', 'config.STORE_AUDIT'],
                    condition: (activity) => !activity.config.USE_AUDITOR || !activity.config.STORE_AUDIT,
                  }}
                  {...contentProps}
                />
                <Content.Text
                  label="Table"
                  path="config.STORE_AUDIT_TABLE"
                  disableOn={{
                    paths: ['config.USE_AUDITOR', 'config.STORE_AUDIT'],
                    condition: (activity) => !activity.config.USE_AUDITOR || !activity.config.STORE_AUDIT,
                  }}
                  {...contentProps}
                />
              </SpaceBetween>
              <Content.Text
                label="Results S3 filename"
                path="config.STORE_AUDIT_LOCATION"
                rules={(l) => ({ pattern: Rules.pattern.s3Path(l) })}
                disableOn={{
                  paths: ['config.USE_AUDITOR', 'config.STORE_AUDIT'],
                  condition: (activity) => !activity.config.USE_AUDITOR || !activity.config.STORE_AUDIT,
                }}
                {...contentProps}
              />
            </SpaceBetween>
          </ColumnLayout>
        </Content.Toggle>
      }
    >
      <Content.Toggle {...contentProps} label="Auditor configuration" path="config.USE_AUDITOR" expandable>
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="m">
            <Content.Code
              label="Validation SQL"
              language="sql"
              path="config.VALIDATION_QUERY"
              disableOn={{ paths: ['config.USE_AUDITOR'], condition: (activity) => !activity.config.USE_AUDITOR }}
              rules={(l) => ({ required: Rules.required(l) })}
              editorContentHeight={200}
              {...contentProps}
            />
          </SpaceBetween>
          <SpaceBetween size="m">
            <SpaceBetween size="s" direction="horizontal">
              <Content.Number
                label="Value"
                path="config.AUDIT_FAILURE_VALUE_LO"
                disableOn={{
                  paths: ['config.USE_AUDITOR', 'config.AUDIT_FAILURE_CONDITION'],
                  condition: (activity) =>
                    !activity.config.USE_AUDITOR || !activity.config.AUDIT_FAILURE_CONDITION?.includes('BETWEEN'),
                }}
                rules={(l) => ({ required: Rules.required(l), validate: { isNumber: Rules.isNumber(l) } })}
                {...contentProps}
              />
              <Content.Select
                label="Operator"
                options={CompareOptions}
                disableOn={{ paths: ['config.USE_AUDITOR'], condition: (activity) => !activity.config.USE_AUDITOR }}
                path="config.AUDIT_FAILURE_CONDITION"
                rules={(l) => ({ required: Rules.required(l) })}
                {...contentProps}
              />
              <Content.Number
                label="Value"
                path="config.AUDIT_FAILURE_VALUE_HI"
                {...contentProps}
                disableOn={{ paths: ['config.USE_AUDITOR'], condition: (activity) => !activity.config.USE_AUDITOR }}
                rules={(l) => ({ required: Rules.required(l), validate: { isNumber: Rules.isNumber(l) } })}
              />
            </SpaceBetween>
          </SpaceBetween>
        </ColumnLayout>
      </Content.Toggle>
    </ColumnContainer>
  );
}
