import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// A map from categories to types to a list of items
type iCtiSlice = { [categories: string]: { [types: string]: string[] } };
const initialState: iCtiSlice = {};

export const ctiSlice = createSlice({
  name: 'cti',
  initialState,
  reducers: {
    resetCtiSlice() {
      return initialState;
    },
    setCategories(state, { payload }: PayloadAction<{ categories: string[] }>) {
      const { categories } = payload;
      (categories ?? [])
        .filter((category) => !(category in state))
        .forEach((category) => {
          // For all categories that are undefined, add their keys and initialize their types to an empty object
          state[category] = {};
        });
    },
    setTypes(state, { payload }: PayloadAction<{ category: string; types: string[] }>) {
      const { category, types } = payload;
      // If for some reason the requested category is undefined, define it as an empty object
      if (!(category in state)) {
        state[category] = {};
      }
      (types ?? [])
        .filter((type) => !(type in state[category]))
        .forEach((type) => {
          // For all types that are undefined, add their keys to the category and define their items as an empty list
          state[category][type] = [];
        });
    },
    setItems(state, { payload }: PayloadAction<{ category: string; type: string; items: string[] }>) {
      const { category, type, items } = payload;
      // If for some reason the requested category is undefined, define it as an empty object
      if (!(category in state)) {
        state[category] = {};
      }
      // Set the items of the given category and type
      state[category][type] = items ?? [];
    },
  },
});
