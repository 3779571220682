import { Button } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { delay } from 'src/commons';
import keys from 'src/constants/hammerstoneConstantKeys';
import { fetchPipelineInfo } from 'src/data/api/fetchFromAPI';
import { actions, useAlias, useGroupName, usePipeline } from 'src/data/redux';
import { PutPipelineInfo } from 'src/lib/hammerstoneApi';
import { CustomSVG, tempFlashbarMessage } from '../helpers';

export default function PipelineToggleStatusButton(props: { pipelineName: string }) {
  const { pipelineName } = props;
  const groupName = useGroupName();
  const alias = useAlias();
  const dispatch = useDispatch();
  const [isCalling, setIsCalling] = useState(false);

  const { data: pipeline, fetching } = usePipeline({ groupName, pipelineName });
  const disabled = !pipelineName || !pipeline;
  const loading = fetching || isCalling;

  const setPipelineStatus = async (operation: keyof typeof keys.Operation) => {
    setIsCalling(true);
    try {
      await PutPipelineInfo({ pipelineName, groupName, body: { operation, updatedBy: alias } });
      tempFlashbarMessage({
        id: `${operation}_pipeline_${pipelineName}_success`,
        message: { header: `Successful ${operation} of pipeline '${pipelineName}'!` },
      });
      // Wait 1 second before fetching new information
      await delay(1000);
      // Update pipeline info after action
      fetchPipelineInfo(
        {
          pipelineName,
          groupName,
        },
        true,
      );
    } catch (error) {
      dispatch(
        actions.page.addToFlashbar({
          id: `pipeline_${operation}_failed`,
          message: {
            type: 'error',
            header: `Failed to perform ${operation} operation on pipeline '${pipelineName}'`,
            content: error.toString(),
            dismissible: true,
          },
        }),
      );
    }
    setIsCalling(false);
  };

  if (pipeline?.status === keys.StatusCode.SCHEDULED) {
    return (
      <Button
        loading={loading}
        disabled={disabled}
        iconSvg={CustomSVG.Pause}
        onClick={() => setPipelineStatus(keys.Operation.pause)}
      >
        Pause pipeline
      </Button>
    );
  } else {
    return (
      <Button
        loading={loading}
        disabled={disabled}
        iconSvg={CustomSVG.Play}
        onClick={() => setPipelineStatus(keys.Operation.activate)}
      >
        Activate pipeline
      </Button>
    );
  }
}
