import React from 'react';
import { ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import { ContainerSkeleton } from '../helpers';

/**
 * A useful loading component structured to resemble an Activity's details page
 */
export default function ActivityLoadingSkeleton() {
  return (
    <SpaceBetween size="xl">
      <ContainerSkeleton columnsPerRow={[4, 4, 4]} header="About" />
      <ColumnLayout columns={2}>
        <ContainerSkeleton columnsPerRow={[2, 2]} header="Schedule" final />
        <ContainerSkeleton columnsPerRow={[2, 2]} header="Notifications" final />
      </ColumnLayout>
    </SpaceBetween>
  );
}
