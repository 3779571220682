// TODO: Eventually ensure that removing some of the `data-testid` attributes are not breaking integ tests
import Content from 'src/components/helpers/content/';
import { iContent } from 'src/components/helpers/content/contentInterfaces';
import { getNodeText } from './objectManipulators';
import { AttributeEditorInput } from 'src/interfaces/inputInterfaces';

const dataPrefix = 'data-' as const;

// For more information on Data attributes, read : https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes
export type DataAttributes = {
  [key: `${typeof dataPrefix}${Lowercase<string>}`]: string;
};

/** For any object-like `props`, this will return an object mapping all the included `data-*` attributes to their values. This allows
 * for custom components to have any valid `data-*` attribute propogate down to its children.
 *
 * @example
 * function Component(props: {...} & DataAttributes){
 *     const customDataAttributes : DataAttributes = filterDataAttributes(props);
 *     return <SomeOtherComponent
 *        {...customDataAttributes}
 *         ...
 *      />
 * }
 * */
export function filterDataAttributes<T extends DataAttributes>(props: T): DataAttributes {
  return Object.fromEntries(
    Object.entries(props)
      .filter(([key]) => key.startsWith(dataPrefix))
      // Ensures that the data attribute keys are lowercased to prevent React Warning
      .map(([dataKey, value]) => [dataKey.toLowerCase(), value]),
  );
}

/** For a particular Content's type (e.g. Text, Number, Toggle, etc.) and props, this will return various `data-*` attributes to help query for that particular component.
 * AttributeEditor input subfields "data-content-type" are dot-appended in lowercase: "AttributeEditor.text", "AttributeEditor.code", "AttributeEditor.select"
 * Spread the returned object over a component to add the data attributes.
 *
 * @example
 * function XContentComponent(props){
 *    return <Component {...getContentDataAttributes('X', props)} />;
 * }
 * */
export function getContentDataAttributes(
  contentType: keyof typeof Content | `AttributeEditor.${AttributeEditorInput}`,
  props: Pick<iContent<any>, 'resourceType' | 'mode' | 'path' | 'label'>,
) {
  // Note: data attribute keys must be LOWER CASE to prevent React throwing warning. When querying, dashes following the `data-` prefix are converted to camelcase.
  return {
    'data-content-type': contentType,
    'data-content-resourcetype': props.resourceType,
    'data-content-mode': props.mode,
    'data-content-path': props.path,
    'data-content-label': getNodeText(props.label),
  };
}
