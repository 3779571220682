import Activity from '../../interfaces/activityInterfaces';
import { configMetadataPreprocess } from '.';
import keys from '../../constants/hammerstoneConstantKeys';
import {
  GetActivityInfoRequest,
  GetActivityInfoResponse,
} from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';

/**
 * Converts a raw activity object returned from the GetActivityInfo API to a cleaner local `Activity` item.
 * Preprocesses the Activity's data to make it more TypeScript legible.
 */
export function activityPreprocess(rawActivity: GetActivityInfoResponse, input?: GetActivityInfoRequest) {
  //Do nothing with input:
  input;
  const activity: any = { ...rawActivity };

  activity.activityType = activity.activityType as typeof keys.ActivityType;
  activity.activityStatusCode = activity.activityStatusCode as typeof keys.StatusCode;
  activity.activityPriority = activity.activityPriority as typeof keys.ActivityPriority;
  activity.s3PreconRegion = activity.s3PreconRegion as typeof keys.Region;
  activity.configChanged = activity.configChanged === 'Y';
  activity.preconditionChanged = activity.preconditionChanged === 'Y';

  // When there is no previous exeuction and when DJS throttles, the API will return nothing. In that case, we set the values to empty strings and display an 'Undetermined' message, as suggested by a customer (ecaglar@)
  activity.djsLastExecutionStatus = (activity.djsLastExecutionStatus ?? '') as keyof typeof keys.DjsExecutionStatus;
  activity.djsLastExecutionDate = activity.djsLastExecutionDate ?? '';

  activity['config'] = configMetadataPreprocess(activity);
  activity['alarm'] = { ...activity.onFailureAlarm } as any;
  activity.alarm.active = activity.alarm.active === 'true';
  activity.alarm.severity = activity.alarm.severity as typeof keys.Severity;

  delete activity.configurationMetadata;
  delete activity.onFailureAlarm;

  return activity as Activity;
}

export default activityPreprocess;
